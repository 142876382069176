import { FocusEvent, memo, useEffect, useRef, useState } from 'react';
import NotePhotos from '../notes/NotePhotos';
import formattedDateString from '../../utility/formattedDateString';
import { FiEdit, FiX } from 'react-icons/fi';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';

export type ReportNoteClient = {
  note_id: number,
}

const Container = ({ condensed, children, onClick, selectable, ...rest }: {
  condensed?: boolean,
  children: any,
  rest?: any,
  onClick?: () => void,
  selectable?: boolean,
}) => <div
  className={`relative group grid md:grid-cols-6 xl:grid-cols-4 rounded-md 
  ${selectable ? 'hover:bg-gray-300 cursor-pointer' : ''} 
  bg-gray-200 shadow-sm 
  ${condensed ? 'py-2 px-3 text-xxs' : 'p-3'}`}
  title={selectable ? "Click to select" : ''}
  onClick={onClick}
  {...rest}
>
    {children}
  </div>

const NoteTakerContainer = ({ children }: {
  children: any,
}) => <div
  className={`col-span-full md:col-span-1 flex`}
> {children} </div>

const NoteTakerPhoto = ({ alt, src }: {
  alt: string,
  src: string,
}) => <img
    className="rounded-full mr-2 w-10 h-10 object-cover"
    src={src}
    alt={alt}
  />

const NoteTakerEditButton = ({ onClick }: {
  onClick: () => void,
}) =>
  <button className="absolute right-4 top-3 hover:opacity-70 text-sm" data-testId="editSiteNote" onClick={onClick}>
    <FiEdit />
  </button>

const NoteSaving = ({ saving, saved }: {
  saving: boolean,
  saved: boolean,
}) => <>
    {saving && (
      <div className="absolute top-2 right-3 flex items-center">
        <Loader color="text-gray-700" />
      </div>
    )}
    {saved && (
      <div className="absolute top-2 right-3 flex items-center">
        <BiCheckCircle
          size={16}
          className="bg-green-500 text-white rounded-full overflow-hidden"
        />
      </div>
    )}
  </>

export {
  NoteTakerEditButton,
  NoteSaving,
}

function ProjectSiteNote({
  note,
  dataTestId = "inputProjectSiteNote",
  dismissable = false,
  onDismiss,
  editable = false,
  onEdit,
  onEditButtonClicked,
  selectable = false,
  onSelect,
  showProfilePhoto = true,
  condensed = false,
  showEditButton = true,
  showUserTitle = true,
  showPhotos,
  onShowPhotosClicked,
  saving,
  saved,
}: {
  note: any,
  dataTestId?: string,
  dismissable?: boolean,
  onDismiss?: (e: any) => void,
  editable?: boolean,
  onEditButtonClicked?: () => void,
  onEdit?: (e: any) => void,
  showProfilePhoto?: boolean,
  condensed?: boolean,
  selectable?: boolean,
  onSelect?: (newReportNote: ReportNoteClient) => void,
  showEditButton?: boolean,
  showUserTitle?: boolean,
  showPhotos?: boolean,
  onShowPhotosClicked?: (e?: any) => void,
  saving?: boolean,
  saved?: boolean,
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [ownerEditing, setOwnerEditing] = useState(false)

  const onBlur = (e: FocusEvent) => {
    setOwnerEditing(false)
    onEdit?.(e)
  }
  useEffect(() => {
    if (!inputRef.current || inputRef.current.textContent) return;
    inputRef.current.textContent = note?.text;
  }, [inputRef]);

  useEffect(() => {
    if (!ownerEditing || !inputRef.current) return;
    inputRef.current.focus();
    let sel = window.getSelection();
    sel?.selectAllChildren?.(inputRef.current);
    sel?.collapseToEnd?.();
  }, [inputRef, ownerEditing])

  const contentEditable = editable || ownerEditing;

  return (
    <Container
      key={`project_notes_card_note_${note.objectId} `}
      condensed={condensed}
      selectable={selectable}
      onClick={() => onSelect?.({ note_id: note.id })}
      data-testid={dismissable ? 'projectSiteNoteDismissable' : 'projectSiteNote'}
    >

      {dismissable && <div onClick={onDismiss} data-testid="siteNoteDismissal" className="cursor-pointer opacity-0 p-1.5 text-white rounded-full -top-2 -left-2 bg-black group-hover:opacity-100 absolute flex items-center">
        <FiX size={12} />
      </div>}
      {editable && !(saving || saved) && showEditButton && <NoteTakerEditButton onClick={onEditButtonClicked ? onEditButtonClicked : () => setOwnerEditing(true)} />}
      {saving || saved && <NoteSaving saving={!!saving} saved={saved} />}
      {!condensed && <NoteTakerContainer>
        {showProfilePhoto && (
          <NoteTakerPhoto
            src={
              note.notetaker.profile_photos?.medium ||
              'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
            }
            alt={note.notetaker.name}
          />
        )}
        <div>
          <p className="single-line font-normal text-xs">{note.notetaker.name}</p>
          {showUserTitle && (
            <p className="font-bold single-line text-xxs">
              {note.notetaker.title}
            </p>
          )}
        </div>
      </NoteTakerContainer>}
      <div
        className={`col-span-full mt-3 md:mt-0 ${!condensed && 'md:col-span-5 md:pl-2 xl:col-span-3'
          } `}
      >
        <div className="flex space-x-2">
          {condensed && (
            <p className="single-line font-bold text-xs">
              {note.notetaker.name}
            </p>
          )}
          <p className="mb-1 font-normal text-gray-500 text-xs">
            {formattedDateString(note.created_at, 'LLL')}
          </p>
        </div>
        <p
          data-testid={dataTestId}
          contentEditable={contentEditable}
          ref={inputRef}
          onInput={onEdit}
          onBlur={onBlur}
          className={`text-xs font-normal whitespace-pre-line ${editable ? 'hover:bg-gray-300' : ''} focus:border-gray-200 focus:outline-none focus:ring-0`}
        />
      </div>
      {note.processed_photos?.length > 0 && onShowPhotosClicked && (
        <div className="col-span-full text-right">
          <p
            className="text-black text-xs cursor-pointer hover:opacity-80"
            onClick={onShowPhotosClicked}
          >
            {showPhotos ? '-' : '+'} {note.processed_photos?.length} Photo
            {note.processed_photos?.length > 1 ? 's' : ''}
          </p>
          {showPhotos && <NotePhotos note={note} />}
        </div>
      )}
    </Container>
  );
}

export default memo(ProjectSiteNote);
