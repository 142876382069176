import { useCallback, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';
import ErrorViewModel from '../../models/ErrorViewModel';
import Loader from '../utility/Loader';
import AddPhotoItem from '../project/AddPhotoItem';
import { useParams } from 'react-router';
import useNote from './hooks/useNote';
import Form from '../../utils/Form';
import AssetUpdateItem from '../assets/AssetUpdateItem';
import timings from '../../styles/timings';
import PhotosModal from '../utility/PhotosModal';
import MapFileToAsset from '../assets/utils/MapFileToAsset';

function NoteEditModal({
    noteId,
    onClose,
    afterEdit
}: {
    noteId: string | null,
    onClose: () => void,
    afterEdit?: (n: any) => void
}) {
    const {
        note,
        updateNote,
        updateError,
        updatedNote,
        updated,
        updating
    } = useNote(noteId)

    const { project_id } = useParams<{ project_id: string }>();
    const [uploadingPhotos, setUploadingPhotos] = useState(false);
    const [savedPhotos, setSavedPhotos] = useState<string[]>([]);
    const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);
    const [removedAssets, setRemovedAssets] = useState<number[]>([]);
    const [description, setDescription] = useState<string | undefined>();
    const [files, setFiles] = useState<any[]>([]);

    const submitNote = async (photos: string[]) => {
        await updateNote({
            text: description,
            assets_attributes: [
                ...photos.map((file) => {
                    return { file };
                }),
                ...removedAssets.map((id) => {
                    return { id, _destroy: true };
                })
            ],
        });
    };

    const filesWithAssets = [...files.map(MapFileToAsset), ...note?.assets || []];

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            setFiles([...files, ...acceptedFiles]);
        },
        [files]
    );

    const updateDescription = (text: string) => {
        setDescription(text);
    };

    const removeImageAtIndex = (index: number) => {
        setFiles([...files.filter((f, i) => i !== index)]);
    };

    const savePhotos = (signedId: string) => {
        const newSavedPhotos = [...savedPhotos, signedId];
        setSavedPhotos([...savedPhotos, signedId]);
        if (newSavedPhotos.length === files.length) submitNote(newSavedPhotos);
    };

    const closeModal = () => {
        setFiles([]);
        setSavedPhotos([]);
        setRemovedAssets([]);
        setUploadingPhotos(false);
        onClose?.();
        if (updatedNote) afterEdit?.(updatedNote);
    }

    useEffect(() => {
        setDescription(note?.text);
    }, [note?.objectId, note?.updated_at])

    useEffect(() => {
        if (!updated) return;
        setTimeout(closeModal, timings.normal);
    }, [updated]);

    return <>
        <Modal isOpen={!!noteId} onClose={closeModal}>
            <ModalHeader
                title="Update Note"
                onClose={closeModal}
                closeBtnTestId="buttonCloseNoteEditModal"
                sticky
            />
            {updateError && <ErrorView error={new ErrorViewModel(updateError)} />}
            {updated ? (
                <ModalSuccessView text={'Note successfully updated'} />
            ) : (
                <>
                    <ModalBody>
                        <>
                            <Form.Group>
                                <Form.TextArea
                                    mb={0}
                                    placeholder="Add a note ..."
                                    value={description}
                                    rows={4}
                                    onChange={({ target: { value } }) =>
                                        updateDescription(value)
                                    }
                                    data-testid="inputEditSiteNote"
                                />
                            </Form.Group>
                        </>
                        <div className="pb-2 flex flex-wrap">
                            {files.map((file, index) => {
                                return (
                                    <AddPhotoItem
                                        key={`project_${project_id}_temp_site_note_image_${file.path}`}
                                        file={file}
                                        startSaving={uploadingPhotos}
                                        index={index}
                                        onSaved={savePhotos}
                                        passback={true}
                                        description={description}
                                        onSelect={() => setShownPhotoIndex(index)}
                                        onRemove={removeImageAtIndex}
                                        locationRequired={undefined}
                                        onLocated={undefined} />
                                );
                            })}
                            {note?.assets?.map?.((asset: any, index: number) => {
                                const markedForRemoval = removedAssets.includes(asset.id);
                                return <AssetUpdateItem
                                    key={`asset-${asset.id}-${index}-${markedForRemoval}`}
                                    markedForRemoval={markedForRemoval}
                                    asset={asset}
                                    onSelect={() => setShownPhotoIndex(files.length + index)}
                                    onCancelRemove={() => setRemovedAssets((as) => [...as.filter((id) => id !== asset.id)])}
                                    onRemove={() => setRemovedAssets((as) => ([...as, asset.id]))}
                                />
                            })}
                        </div>
                        <Form.FileDropzone accept="image/jpeg, image/png" onDrop={onDrop} />
                    </ModalBody>

                    <ModalFooter sticky>
                        <button
                            className="modal-save-btn"
                            type="button"
                            data-testid="buttonSaveSiteNote"
                            disabled={(description === note?.text && !removedAssets.length && !files.length) || updating || uploadingPhotos}
                            onClick={() => !!files.length ? setUploadingPhotos(true) : submitNote([])}
                        >
                            Save
                            {(updating || uploadingPhotos) && <Loader className="ml-3" />}
                        </button>
                        <button
                            className="modal-close-btn"
                            type="button"
                            disabled={updating || uploadingPhotos}
                            onClick={closeModal}
                        >
                            Cancel
                        </button>
                    </ModalFooter>
                </>
            )}
        </Modal>
        <PhotosModal
            isOpen={shownPhotoIndex > -1}
            assets={filesWithAssets}
            activeIndex={shownPhotoIndex}
            hideBackdrop
            onClose={() => setShownPhotoIndex(-1)}
        />
    </>;
}

export default NoteEditModal;
