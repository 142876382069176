import { useState, useEffect, memo, useLayoutEffect, useCallback } from 'react';
import { FiFilePlus } from 'react-icons/fi';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../utility/Modal';
import MapLayerFileUpload from './MapLayerFileUpload';
import MapLayersSelect from './MapLayersSelect';
import AssetDropzone from '../assets/AssetDropzone';
import useFileMapLayerData from './hooks/useFileMapLayerData';
import useValidateFile from './hooks/useValidateFile';
import ErrorView from '../utility/ErrorView';

export const DIRECT_UPLOAD_FILE_TYPES = ['TIF', 'GEOTIFF', 'TIFF', 'FBX'];
const ACCEPTED_FILE_STRING = 'application/pdf,.kml,.kmz,.jpg,.jpeg,.png,.tif,.tiff,.geotiff,.fbx';
const FILE_EXTENSIONS_WITH_ICONS = ['kml', 'kmz', 'pdf', 'tif', 'tiff'];

const PlusFileButton = ({ onClick }) => <a
  className="disabled:opacity-70 text-xxs flex items-center cursor-pointer hover:opacity-80 font-bold justify-center rounded-md-r bg-white text-gray-700 disabled:opacity-70"
  title="Add/Change File"
  onClick={(e) => {
    e.preventDefault();
    onClick();
  }}
  data-testid="addAssetButton"
  role="button"
  href='#'
>
  <FiFilePlus size={20} />
</a>;

function AddAssetButton({
  modalOpen: passedModalOpen = false,
  mapLayer = {},
  onAssetAdded,
  onCopyLayerChosen,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [layerToCopy, setLayerToCopy] = useState();
  const [{
    file,
    fileType,
    name,
    dimensions,
    url,
    json,
  }, setFileData] = useState({
    file: null,
    fileType: null,
    name: null,
    dimensions: { width: 0, height: 0 },
    url: null,
    json: null,
  });

  const [error, setError] = useState();

  const closeModal = () => {
    if (!data) window.history.back();
    setModalOpen(false);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setError(null);
      const file = acceptedFiles[0];
      const fileType = file.name.split('.').pop().toUpperCase();
      const name = file.name.split('.').slice(0, -1).join('.');

      setFileData((fd) => ({
        ...fd,
        file,
        fileType,
        name,
      }));
    },
    [file]
  );

  const formattedData = () => {
    //Knowledge of this shape couples the asset button too tightly to the map layer
    //We should consider a type/shape for client-side assets that is more generic
    let tempFormattedData = {
      ...mapLayer,
      rotation: mapLayer.rotation || 0,
      name,
      bounds: mapLayer.bounds || [], //TODO: bounds is deprecated, remove it
      file_type: fileType,
      file,
      asset: {
        updated: true,
        processed_dimensions: {
          width: dimensions.width,
          height: dimensions.height,
        },
        files: {
          large: json || url,
        },
      },
    };

    if (layerToCopy) {
      //TODO: bounds is deprecated, remove it
      tempFormattedData.bounds = layerToCopy.bounds.map(
        ({ direction, lat, lng }) => {
          return { direction, lat, lng };
        }
      );

      tempFormattedData.bounding_box = layerToCopy.bounding_box;
      tempFormattedData.rotation = layerToCopy.rotation;
      tempFormattedData.scale = layerToCopy.scale;
      tempFormattedData.asset.processed_dimensions =
        layerToCopy.asset.processed_dimensions;
    }

    return tempFormattedData;
  };


  const fileLoaded = (!!file && !!fileType);
  const { error: fileError } = useValidateFile({ enabled: fileLoaded, file, fileType })
  const { loading, data } = useFileMapLayerData({
    enabled: fileLoaded,
    file,
    fileType
  });

  const onSubmit = () => {
    setModalOpen(false);
    onAssetAdded?.(formattedData());
  };

  useEffect(() => {
    setFileData((fd) => ({ ...fd, ...data }));
  }, [JSON.stringify(data)])

  useEffect(() => {
    onCopyLayerChosen?.(layerToCopy);
  }, [layerToCopy]);

  useLayoutEffect(() => {
    setModalOpen(passedModalOpen);
  }, [passedModalOpen]);

  return (
    <>
      <PlusFileButton
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={modalOpen}
        dialogScrolls={false}
        modalScrolls={false}
        maxHeight={'max-h-auto'}
        modalClass={'overflow-hidden rounded-lg'}
        dialogClass={'py-10'}
        aligned={'items-start'}
        onClose={closeModal}
      >
        <ModalHeader title={'Add File'} onClose={closeModal} />
        <ModalBody>
          <MapLayerFileUpload.Container>
            <MapLayerFileUpload.Title>
              Let's add a new map layer
            </MapLayerFileUpload.Title>
            {error && <ErrorView error={fileError} extraClass={'mb-4'} />}
            {fileError && <ErrorView error={fileError} extraClass={'mb-4'} />}
            <MapLayerFileUpload.Body>
              <div className="flex-1">
                <div className="max-w-md mx-auto w-full">
                  <AssetDropzone
                    testId='dropMapLayerRoot'
                    heightClass='h-80'
                    accept={ACCEPTED_FILE_STRING}
                    onDrop={onDrop}
                    onFilesRejected={(rejections) => {
                      setError(
                        rejections.map(
                          ({ file, errors }) => `${file.path} ${errors[0].message}`
                        )
                      );
                    }}
                  >
                    {
                      !fileLoaded ? <MapLayerFileUpload.EmptyUploadBody /> :
                        <>
                          {FILE_EXTENSIONS_WITH_ICONS.includes(file.name.split('.').pop().toLowerCase()) ? (
                            <MapLayerFileUpload.Icon extension={file.name.split('.').pop().toLowerCase()} />
                          ) : (
                            <img src={URL.createObjectURL(file)} className="h-40 my-5 mx-auto" />
                          )}
                          <MapLayerFileUpload.FileNameContainer>
                            {loading &&
                              <MapLayerFileUpload.Loading />
                            }
                            <MapLayerFileUpload.FileNameInput
                              onChange={(e) => {
                                setFileData((fd) => ({ ...fd, name: e.target.value }));
                                e.stopPropagation();
                              }}
                              value={name}
                            />
                          </MapLayerFileUpload.FileNameContainer>
                        </>
                    }
                  </AssetDropzone>
                </div>
              </div>
            </MapLayerFileUpload.Body>
          </MapLayerFileUpload.Container>
        </ModalBody>
        <ModalFooter>
          <MapLayersSelect
            disabled={!fileType || fileType !== 'PDF'}
            selectedId={layerToCopy && layerToCopy.id}
            onSelect={setLayerToCopy}
          />
          <button
            className="modal-save-btn"
            type="button"
            data-testid="addAssetSubmitButton"
            onClick={onSubmit}
            disabled={!data || loading}
          >
            Load File
          </button>
          <button
            className="modal-close-btn"
            type="button"
            onClick={closeModal}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal >
      <MapLayerFileUpload.PDFWorker />
    </>
  );
}

export default memo(AddAssetButton);
