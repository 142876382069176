import { useEffect, useState } from 'react';
import { getTextColorFromBackground } from '../../../styles/colors';

export default function FieldBoxes({
  options,
  label,
  multiSelect,
  componentId,
  updateFormState,
  value,
}) {
  const lightBlue = '#dfe9ed';
  const darkBlue = '#10678B';
  const white = '#ffffff';
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (value && statuses.length === 0) {
      setStatuses(value);

      updateFormState(value, multiSelect, componentId);
    }
  }, [value]);

  const selectStatus = (event, field) => {
    addOrRemoveSelection(field);
  };

  const addOrRemoveSelection = (f) => {
    let newStatuses = statuses;
    const index = newStatuses.findIndex((x) => x?.id === f?.id && !x?._destroy);

    if (index !== -1) {
      newStatuses[index]._destroy = true;
    } else {
      if (multiSelect) {
        newStatuses.push({ ...f, _destroy: false });
      } else {
        if (newStatuses.length > 0) {
          newStatuses = newStatuses.map((x) => ({ ...x, _destroy: true }));
          newStatuses.push({ ...f, _destroy: false });
        } else {
          newStatuses[0] = { ...f, _destroy: false };
        }
      }
    }
    setStatuses([...newStatuses]);
    updateFormState(newStatuses, multiSelect, componentId);
  };

  return (
    <div className="mb-8">
      <p className="text-secondary font-bold text-lg mb-3 capitalize">
        {label}
      </p>
      <div className="flex flex-wrap">
        {options.map((f) => {
          const isSelected =
            statuses?.findIndex((x) => x?.id === f?.id && !x?._destroy) !== -1;
          const backgroundColor = isSelected ? f.color || darkBlue : lightBlue;
          const color = isSelected
            ? f.color
              ? getTextColorFromBackground(f.color, 200)
              : white
            : darkBlue;

          return (
            <button
              id={f?.id}
              data-testid={`fieldBox-${f?.name
                .toLowerCase()
                .replace(' ', '-')}`}
              onClick={(e) => selectStatus(e, f)}
              style={{
                backgroundColor,
                color,
              }}
              className={`hover:text-white h-16 px-6 text-secondary rounded-md font-bold mr-3 mb-3 ${statuses.includes(f.id) && multiSelect
                  ? 'status-selected'
                  : `status-button-${componentId}`
                }`}
            >
              {f.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}
