import React, { useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import authApi from '../api/auth';
import useAuth from '../auth/useAuth';
import useQuery from '../hooks/useQuery';

import OnboardingImage from '../images/bgs/login-bg.png';
import ConfirmationForm from '../partials/onboarding/ConfirmationForm';
import JoinWorkspaceForm from '../partials/onboarding/JoinWorkspaceForm';
import SignupForm from '../partials/onboarding/SignupForm';
import WorkspaceForm from '../partials/onboarding/WorkspaceForm';
import WorkspaceShareForm from '../partials/onboarding/WorkspaceShareForm';
import ProfilePictureForm from '../partials/onboarding/ProfilePictureForm';
function Onboarding() {
  const { step } = useParams();
  const history = useHistory();
  const { user, setUser } = useAuth();
  const query = useQuery();
  const code = query.get('code');

  const reloadUser = async () => {
    let response = await authApi.me();
    if (response.ok) setUser({ ...response.data });
  };

  const reloadUserThenGoTo = async (goTo) => {
    await reloadUser();
    history.push(goTo);
  };

  const handleStepAfterConfirmation = (user) => {
    history.push(user.default_workspace ? `share` : 'workspace');
  };

  return (
    <main className="bg-white">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {code && step === 'sign_up' && (
              <div className="w-full bg-green-400 text-white text-center text-lg font-semibold p-3">
                You've been invited to join Site Marker
              </div>
            )}
            <div>
              {/* Header */}
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <svg
                    width="54"
                    height="33"
                    viewBox="0 0 54 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2324 10.4287L26.7621 20.3574L43.3054 10.4287L36.8946 6.58489L30.0762 10.6732V2.48302L26.7621 0.5L23.4616 2.48302V10.6732L16.6433 6.57131L10.2324 10.4287Z"
                      fill="#F49F15"
                    />
                    <path
                      d="M53.4244 16.5L47.0135 12.6426L26.7622 24.7988L26.6264 24.7037L6.52454 12.6562L0.100098 16.5L26.7622 32.5L53.4244 16.5Z"
                      fill="#16697A"
                    />
                  </svg>
                </Link>
                {step === 'sign_up' && (
                  <div className="text-sm">
                    Have an account?{' '}
                    <Link
                      className="font-medium text-tertiary hover:text-secondary"
                      to="/signin"
                    >
                      Sign In
                    </Link>
                  </div>
                )}
              </div>

              {/* Progress bar */}
              {!code && (
                <div className="px-4 pt-12 pb-8">
                  <div className="max-w-md mx-auto w-full">
                    <div className="relative">
                      <div
                        className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200"
                        aria-hidden="true"
                      ></div>
                      <ul className="relative flex justify-between w-full">
                        <li>
                          <div
                            className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-tertiary text-white`}
                          >
                            1
                          </div>
                        </li>
                        <li>
                          <div
                            className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${
                              !['sign_up'].includes(step)
                                ? 'bg-tertiary text-white'
                                : 'bg-gray-100 text-gray-500'
                            }`}
                          >
                            2
                          </div>
                        </li>
                        <li>
                          <div
                            className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${
                              !['confirm', 'sign_up'].includes(step)
                                ? 'bg-tertiary text-white'
                                : 'bg-gray-100 text-gray-500'
                            } `}
                          >
                            3
                          </div>
                        </li>
                        <li>
                          <div
                            className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${
                              ![
                                'join_workspace',
                                'workspace',
                                'confirm',
                                'sign_up',
                              ].includes(step)
                                ? 'bg-tertiary text-white'
                                : 'bg-gray-100 text-gray-500'
                            }`}
                          >
                            4
                          </div>
                        </li>
                        <li>
                          <div
                            className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${
                              ![
                                'profile_picture',
                                'join_workspace',
                                'workspace',
                                'confirm',
                                'sign_up',
                              ].includes(step)
                                ? 'bg-tertiary text-white'
                                : 'bg-gray-100 text-gray-500'
                            }`}
                          >
                            5
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="px-4 py-8 mt-10">
              {step === 'sign_up' && (
                <div className="max-w-md mx-auto">
                  <h1 className="text-3xl text-gray-800 font-bold mb-6">
                    Let's get started
                  </h1>
                  <SignupForm
                    code={code}
                    onComplete={(user) =>
                      history.push(
                        user.confirmed
                          ? 'workspace'
                          : 'confirm?email=' + user.email
                      )
                    }
                  />
                </div>
              )}
              {step === 'confirm' && (
                <ConfirmationForm onConfirmed={handleStepAfterConfirmation} />
              )}
              {step === 'join_workspace' && <JoinWorkspaceForm user={user} />}
              {step === 'workspace' && (
                <WorkspaceForm
                  user={user}
                  onBack={() => history.goBack()}
                  onComplete={() => reloadUserThenGoTo('profile_picture')}
                />
              )}
              {step === 'profile_picture' && (
                <ProfilePictureForm
                  onBack={() => history.goBack()}
                  onComplete={() => reloadUserThenGoTo('share')}
                />
              )}
              {step === 'share' && (
                <WorkspaceShareForm
                  user={user}
                  onBack={() => history.goBack()}
                  onComplete={() =>
                    history.push(`/${user.default_workspace.objectId}/projects`)
                  }
                />
              )}
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
          {/* <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block" src={OnboardingDecoration} width="218" height="224" alt="Authentication decoration" /> */}
        </div>
      </div>
    </main>
  );
}

export default Onboarding;
