import { memo, useEffect, useState } from 'react';
import { FaEdit, FaFilter, FaMapMarker, FaSave } from 'react-icons/fa';
import PinViewModel from '../../models/PinViewModel';
import { getTextColorFromBackground } from '../../styles/colors';
import MapBoxMap from '../maps/mapbox/MapBoxMap';
import reportPinsApi from '../../api/report_pins';
import useApi from '../../hooks/useApi';
import { useParams } from 'react-router-dom';
import pinsApi from '../../api/pins';
import Loader from '../utility/Loader';
import EntryPinNote from './EntryPinNote';
import useReportContext from '../reports/useReportContext';
import PinModal from '../pins/PinModal';
import mapLayersApi from '../../api/map_layers';
import Button from '../shared/Button';
import { FiX } from 'react-icons/fi';
import Tags from '../shared/Tags';
import useFeatures from '../../hooks/useFeatures';
import useModal from '../../hooks/useModal';
import Modal, { ModalHeader } from '../utility/Modal';
import PinFilterCard from '../pins/PinFilterCard';
import usePins from '../pins/usePins';
import formattedDateString from '../../utility/formattedDateString';
import useNoteAssets from '../notes/hooks/useNoteAssets';
import PhotosModal from '../utility/PhotosModal';

export const buildMapLayerAttributes = ({
  existingMapLayers,
  chosenMapLayers,
  associationId = 'report_pin_map_layer_id',
}) => {
  const newMapLayers = chosenMapLayers.map((mapLayer) => {
    let existingLayer = existingMapLayers.find(({ id }) => id === mapLayer.id);

    if (existingLayer) {
      return {
        id: existingLayer[associationId],
        opacity: parseFloat(mapLayer.opacity),
      };
    }

    return {
      map_layer_id: mapLayer.id,
      opacity: parseFloat(mapLayer.opacity),
    };
  });

  const chosenMapLayerIds = chosenMapLayers.map(({ id }) => id);

  const deletedMapLayers = existingMapLayers.filter(
    ({ id }) => !chosenMapLayerIds.includes(id)
  );

  if (deletedMapLayers.length > 0) {
    deletedMapLayers.forEach(({ [associationId]: id }) => {
      newMapLayers.push({
        id,
        _destroy: true,
      });
    });
  }

  return newMapLayers;
};

const EntryCreatorPhoto = ({ src, dataTestId, onLoaded }) => {
  const { loading, onLoad, onError } = useRetry(onLoaded);

  return <NewPhoto
    className="rounded-full object-cover border-2 border-white float-left mr-2 w-9 h-9"
    loaded={!loading}
    testId={dataTestId}
    src={src}
    onError={onError}
    onLoad={onLoad}
  />
}

const EntryPinHeader = ({ report, isGenerating, additional, pin, onPhotoLoaded, latestNote, index, photoIndex }) => {
  const { features: { rollout: { pin_tags, scraping_bee_reports } = {} } = {} } = useFeatures();

  return (
    <div className="block md:flex justify-between gap-4 leading-tight">
      <div>
        <div className="flex flex-start items-center">
          <p className="font-normal relative">
            <FaMapMarker
              style={{
                color:
                  report?.metadata?.map_state?.pin_style?.color === 'inherit'
                    ? pin.color
                    : 'orange',
              }}
              fontSize={40}
            />
            <span className="font-semibold text-sm text-center w-full absolute top-1 left-0 text-white">
              {index}
            </span>
          </p>
          <p
            className="font-semibold text-center rounded-md bg-gray-100 border border-gray-200 px-5 py-1 mx-3"
            id="entryPinIdentifier"
          >
            {pin.identifier}
          </p>
          {pin.pin_type.name !== 'Tree' && (
            <p
              className="font-semibold capitalize"
              style={{ color: pin && pin.getStatusColor() }}
            >
              {pin.getStatus()}
            </p>
          )}
          {additional && <p className="italic text-xs">(continued)</p>}
        </div>
        <div className="text-sm mt-2 mb-2">
          <span className="font-bold text-xs">Pin Type:</span>{' '}
          <span className="font-medium ml-1">{pin.pin_type.name}</span>
        </div>
        {!additional && (
          <div className="flex justify-between">
            {pin_tags && !!pin.tags_with_colors.length && (
              <div className="text-xs mr-5 mt-1">
                <div className="mb-2">
                  <span className="font-bold mr-1 mb-1 border-b">Tags:</span>
                </div>
                <Tags
                  tagSize="sm"
                  on={'pin'}
                  tags={pin.tags_with_colors}
                  containerClassName={'flex -mt-0.5'}
                  tagClassName={'mr-1'}
                />
              </div>
            )}
            {pin.hasCategoryFields() && (
              <div className="text-sm">
                <div>
                  <span className="font-medium text-xs">Categories:</span>
                </div>
                {pin
                  .categoryFields()
                  .map(({ field_option: { color, name } }, index) => {
                    return (
                      <span
                        key={`report_pin_${pin.objectId}_category_${index}`}
                        className={`ml-1 py-1 px-2 rounded-lg shadow text-white font-semibold text-xs mr-1`}
                        style={{
                          backgroundColor: color,
                          color: getTextColorFromBackground(color, 200),
                        }}
                      >
                        {name}
                      </span>
                    );
                  })}
              </div>
            )}
            {pin.pin_type.name === 'Tree' && (
              <>
                <div className="text-xs mt-1">
                  <div className="mb-2">
                    <span className="font-bold mr-1 mb-1 border-b">Details:</span>
                  </div>
                  <span>
                    {pin.gradeField()
                      ? 'Grade ' + pin.gradeField()?.field_option?.name
                      : ''}{' '}
                    {pin.speciesField()?.field_option?.name}{' '}
                    {pin.ratingField()
                      ? 'with ' +
                      pin.ratingField()?.field_option?.name +
                      ' rating.'
                      : '.'}
                  </span>
                </div>
                <div className="text-xs mt-1 ml-2">
                  <div className="mb-2">
                    <span className="font-bold mr-1 mb-1 border-b">Trunk size(s):</span>
                  </div>
                  {pin.trunkFields().map(({ value }, index) => {
                    return (
                      <span
                        key={`report_pin_${pin.objectId}_trunk_${index}`}
                        className={`ml-1 py-1 px-1 rounded-md shadow text-white font-semibold text-xs mr-1 bg-gray-900`}
                      >
                        {value} "
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <div className="border-t pt-2 mt-4 md:pt-0 md:border-t-0 md:mt-0 flex-shrink md:flex justify-end">
        <div>
          <div className="font-semibold text-sm md:text-xs">Created by:</div>
          <div className="flex items-center mt-2">
            <EntryCreatorPhoto
              dataTestId={`entryPinCreatorPhoto${photoIndex}`}
              src={scraping_bee_reports && isGenerating ? latestNote.notetaker.profile_photos.download.large : latestNote.notetaker.profile_photos.large}
              onLoaded={() => onPhotoLoaded(photoIndex)}
            />
            <div className="leading-snug text-sm">
              <p>{latestNote.notetaker.name}</p>
              <p className="font-medium text-xs">
                {latestNote.notetaker.title}
              </p>
            </div>
          </div>
          <p className="font-semibold text-xs mt-2 italic">
            {formattedDateString(
              latestNote.created_at,
              'MMM D, YYYY [at] h:mm a'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const ErroredPhotoPlaceholder = ({ size }) => (
  <div className={PhotoSizes[size]} />
);

const ErrorRetryButton = ({ onClick }) => (
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="flex flex-col items-center bg-white px-3 py-4 rounded-md shadow-sm">
      <p className="text-tertiary font-semibold text-sm mb-2">
        Error loading photo
      </p>
      <Button text="Retry" onClick={onClick} size={'sm'} />
    </div>
  </div>
);


const PhotoSizes = {
  full: 'h-42 w-full',
  siteNote: 'h-42 w-42',
  lg: 'h-72 w-72',
  md: 'h-64 w-64',
  sm: 'h-56 w-56',
}

const NewPhoto = ({ className, testId, key, size, src, onLoad, onError }) => {
  const [loaded, setLoaded] = useState(false);
  const [bgImage, setBgImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
      setBgImage(src);
    };
    img.onerror = onError;
  }, [src]);

  useEffect(() => {
    if (!loaded) return;
    const imgElement = document.createElement('img');
    imgElement.setAttribute('data-testid', testId + 'img' || 'background-image');
    imgElement.src = src;
    imgElement.onload = () => {
      if (onLoad) onLoad?.();
      document.body.removeChild(imgElement);
    };

    document.body.appendChild(imgElement);
  }, [loaded, src]);

  return (
    <div
      key={key}
      data-testid={testId}
      className={`${className || PhotoSizes[size]} bg-no-repeat bg-cover bg-center`}
      style={{ backgroundImage: loaded ? `url(${bgImage})` : 'none' }}
    />
  );
};

const PhotoContainer = ({ children, onClick }) => (
  <div className="cursor-zoom-in flex flex-col mr-1 shadow-sm border mb-1 rounded-md overflow-hidden bg-gray-800 relative" onClick={onClick}>
    {children}
  </div>
);

const PhotoLoader = () => (
  <div className="absolute inset-0 flex items-center justify-center">
    <Loader />
  </div>
);

const PhotoFooter = ({ children }) => (
  <div className="font-semibold text-xxs text-center bg-black text-white py-1">
    {children}
  </div>
);

const MAX_RETRY = 3;

const useRetry = (onLoaded) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const retryLoad = () => {
    setRetryCount(retryCount + 1);
    setError(false);
  };

  const onLoad = () => {
    setLoading(false);
    onLoaded?.();
  }

  const onError = () => {
    const hitRetryLimit = retryCount >= MAX_RETRY;
    setError(hitRetryLimit);

    if (hitRetryLimit) return;
    retryLoad();

    setLoading(false);
  };

  return { error, loading, retryCount, retryLoad, onLoad, onError };
};

export const EntryPinPhoto = ({ photo, index, onLoaded, onSelect, size = 'lg' }) => {
  const { error, loading, retryCount, retryLoad, onLoad, onError } = useRetry(() => onLoaded(index));

  return (
    <PhotoContainer onClick={() => onSelect(index)}>
      {error ? (
        <ErroredPhotoPlaceholder size={size} />
      ) : (
        <NewPhoto
          testId={`${photo.objectId}Photo${index}`}
          key={`report_pin_note_photos_${photo.objectId}_photo_${index}_${retryCount}`}
          size={size}
          src={photo.large}
          onLoad={onLoad}
          onError={onError}
        />
      )}
      <PhotoFooter>
        {formattedDateString(photo.createdAt, 'MMM D, YYYY [at] h:mm a')}
      </PhotoFooter>
      {loading && <PhotoLoader />}
      {error && <ErrorRetryButton onClick={retryLoad} />}
    </PhotoContainer>
  );
};

const EntryPinPhotos = ({ startIndex = 0, isGenerating, assets, latestNote, onLoaded, onSelect, totalCount }) => {

  const { features: { rollout: { scraping_bee_reports } = {} } = {} } = useFeatures();

  return <>
    <p className={`font-semibold text-sm my-2`}>Photos:</p>
    <div className="flex flex-wrap justify-center">
      {assets.map(({ objectId, files, created_at, save_started_at, taken_at }, index) => {
        const { download, large } = files;
        return (
          <EntryPinPhoto
            photo={{
              objectId: objectId || latestNote.objectId,
              large: scraping_bee_reports && isGenerating ? download.large : large,
              createdAt: taken_at || save_started_at || created_at || latestNote.created_at,
            }}
            onLoaded={onLoaded}
            onSelect={onSelect}
            index={startIndex + index}
            size={totalCount < 3 ? 'lg' : 'sm'}
          />
        );
      })}
    </div>
  </>
};

export const EntryMapEditState = ({
  allowFiltering = false,
  editing,
  onSave,
  onToggle,
  saving,
  saved,
  updated,
}) => {
  const { open, toggle } = useModal();

  return (
    <>
      <div
        className={`absolute pointer-events-none inset-0 ${updated ? 'border-green-500' : 'border-blue-500'
          } border-4 border-opacity-60 z-10 transition-opacity duration-200 ${editing ? 'opacity-100' : 'opacity-0'
          }`}
      />
      <div className="absolute m-4 rounded-md overflow-hidden right-0 print:hidden z-10 flex flex-col md:flex-row">
        <Button
          onClick={onSave}
          className={`font-semibold mr-2 order-last md:order-first ${updated ? 'opacity-100' : 'opacity-0 hidden'
            }`}
          icon={saved || saving ? <span /> : <FaSave className="ml-1" />}
          text="Save"
          color="success"
          disabled={saving || saved}
          saving={saving}
          saved={saved}
          savedText={'Saved'}
          data-testId="saveMapButton"
        />
        {allowFiltering && (
          <Button
            text="Filter Pins"
            className="mb-2 md:mb-0 mr-1"
            color={'light'}
            icon={<FaFilter className={`ml-1`} />}
            onClick={toggle}
            data-testId="filterMapButton"
          />
        )}
        <Button
          disabled={saving || saved}
          text={editing ? 'Cancel' : 'Edit'}
          className="mb-2 md:mb-0"
          color={'light'}
          icon={
            editing ? <FiX className="ml-1" /> : <FaEdit className="ml-1" />
          }
          onClick={onToggle}
          data-testId="editMapButton"
        />
      </div>
      {allowFiltering && (
        <PinFilterModal
          open={open}
          toggle={toggle}
          onSave={onSave}
          updated={updated}
          saved={saved}
          saving={saving}
        />
      )}
    </>
  );
};

const PinFilterModal = ({ open, toggle, onSave, updated, saved, saving }) => {
  const { setLoadPins } = usePins();

  useEffect(() => {
    setLoadPins(open);
  }, [open]);

  return (
    <Modal
      isOpen={open}
      side
      maxHeight="h-full"
      dialogPadding="px-0"
      modalClass="rounded-0 w-96"
    >
      <ModalHeader title={'Filter Pins on Map'} onClose={toggle} />
      <PinFilterCard
        viewOptions={{
          droppedBy: false,
          header: false,
          previousNeedsAction: false,
          privatePins: false,
        }}
      />
      <div className="bg-white p-5 border-b shadow-sm">
        <Button
          onClick={onSave}
          className={`font-semibold w-full ${updated ? 'opacity-100' : 'cursor-not-allowed'
            }`}
          icon={saved || saving ? <span /> : <FaSave className="ml-1" />}
          text="Save"
          color={updated ? 'success' : 'disabled'}
          disabled={saving || saved}
          saving={saving}
          saved={saved}
          savedText={'Saved'}
          data-testId="saveMapButton"
        />
      </div>
    </Modal>
  );
};

const photosLoadedClass = "photos-loaded";
export {
  photosLoadedClass
}
function EntryPin({
  report,
  pinId,
  index,
  includeBreak = true,
  maxPhotos = 3,
}) {
  const [selectedPin, setSelectedPin] = useState(null);
  const [mapLocked, setMapLocked] = useState(true);
  const [updatedMapAttributes, setUpdatedMapAttributes] = useState();
  const [mapSaved, setMapSaved] = useState(false);
  const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);

  const mapUpdated = !!updatedMapAttributes;

  const {
    data: loadedPin,
    loading: loadingPin,
    request: getPin,
  } = useApi(pinsApi.getPin, null, true);

  const {
    data: loadedReportPin,
    loading,
    request: getReportPin,
  } = useApi(reportPinsApi.getReportPin, null);

  const {
    data: updatedReportPin,
    loading: updating,
    request: updateReportPin,
  } = useApi(reportPinsApi.updateReportPin, null);

  const {
    data: { records: reportPinMapLayers },
    request: getMapLayers,
  } = useApi(mapLayersApi.getMapLayers, { records: [], pagy: {} }, true);

  const { report_page_id, share_id } = useParams();
  const { editing } = useReportContext();
  const [loadedPhotos, setLoadedPhotos] = useState([]);
  const isGenerating = window.location.pathname.includes('generate');

  const reportPinMapLayersUpdate = (mapLayers) => {
    if (!editing) return;

    const mappedAttributes = buildMapLayerAttributes({
      existingMapLayers: reportPinMapLayers,
      chosenMapLayers: mapLayers,
    });

    setUpdatedMapAttributes((a) => ({
      ...a,
      report_pin_map_layers_attributes: mappedAttributes,
    }));

    setMapLocked(false);
  };

  useEffect(() => {
    getPin(pinId, { share_id });
  }, []);

  useEffect(() => {
    if (!loadedPin?.objectId) return;
    getReportPin(report.objectId, loadedPin.id, { share_id });
  }, [loadedPin?.objectId]);

  useEffect(() => {
    if (!loadedReportPin?.objectId) return;
    getMapLayers({ report_pin_id: loadedReportPin.objectId, share_id });
  }, [loadedReportPin?.objectId, updatedReportPin?.updated_at]);

  useEffect(() => {
    if (!updatedReportPin?.updated_at) return;
    setMapSaved(true);

    const clearMapUpdates = () => {
      setUpdatedMapAttributes();
      setMapLocked(true);
      setMapSaved(false);
    };

    setTimeout(clearMapUpdates, 1000);
  }, [updatedReportPin?.updated_at]);


  let pin = loadedPin ? new PinViewModel(loadedPin) : null;

  const { assets, loading: loadingAssets } = useNoteAssets(pin?.latest_note_with_photos?.[0], {
    enabled: !!pin,
    share_id
  });

  const latest_note = pin?.latest_notes?.[0];
  const needs_multiple_pages = assets.length > maxPhotos;
  const allPhotosLoaded = (assets.length + (needs_multiple_pages ? 2 : 1)) === loadedPhotos.length;

  if ((loading && !loadedReportPin) || loadingPin || loadingAssets)
    return (
      <div className="flex justify-center w-full px-5 py-10">
        <Loader color={'text-gray-800'} />
      </div>
    );

  return (
    <div className="group relative entryPin" data-testid={`entryPin_${index}`}>
      <div className={`p-4 w-full`} style={{ height: 'auto' }}>
        <EntryPinHeader
          report={report}
          pin={pin}
          isGenerating={isGenerating}
          latestNote={latest_note}
          photoIndex={assets.length}
          onPhotoLoaded={(i) => setLoadedPhotos((loadedIndexes) => [...loadedIndexes, i])}
          index={index}
        />
        <div className="py-2 flex flex-col justify-center mb-4">
          <p className="font-semibold mb-2 text-sm">Location:</p>
          <div className="w-full h-52 px-4">
            <div className="w-full h-full rounded-md border shadow-sm relative bg-gray-900">
              {!loading && (
                <MapBoxMap
                  onBoundsUpdated={(bounds) =>
                    setUpdatedMapAttributes((a) => ({ ...a, bounds }))
                  }
                  onLayersChosen={reportPinMapLayersUpdate}
                  pins={[{ ...loadedPin, index }]}
                  fitToPins={true}
                  project={report.project}
                  onSelectPin={setSelectedPin}
                  locked={mapLocked}
                  bearing={report.metadata?.map_state?.bearing}
                  pitch={report.metadata?.map_state?.pitch}
                  pinStyle={
                    report.metadata?.map_state?.pin_style || {
                      icon: 'pin',
                      color: 'orange',
                      showCount: true,
                    }
                  }
                  allowMapLayerSelection
                  canAddLayers={false}
                  canEditLayers={false}
                  showNavigationControls={false}
                  mapTypeToggle={false}
                  defaultLayers={reportPinMapLayers}
                  defaultBounds={loadedReportPin.bounds}
                  testId={`entryPinMap_${index}`}
                >
                  {editing && (
                    <EntryMapEditState
                      editing={!mapLocked}
                      onToggle={() => {
                        setUpdatedMapAttributes();
                        if (mapUpdated) {
                          getReportPin(report.objectId, loadedPin.id, {
                            share_id,
                          });
                        }
                        setMapLocked(!mapLocked);
                      }}
                      onSave={() =>
                        updateReportPin(
                          report.objectId,
                          loadedPin.id,
                          updatedMapAttributes
                        )
                      }
                      saving={updating}
                      saved={mapSaved}
                      updated={mapUpdated}
                    />
                  )}
                </MapBoxMap>
              )}

              <PinModal
                key={`pin_${selectedPin}_map`}
                onClose={() => setSelectedPin(null)}
                pinId={selectedPin}
                open={selectedPin !== null}
                actionable={false}
              />
            </div>
          </div>
        </div>
        <EntryPinNote editing={editing} pin={pin} note={latest_note} />
        {allPhotosLoaded && <span data-testid="pinPhotosLoaded" className={photosLoadedClass} />}
        {assets.length > 0 && (
          <EntryPinPhotos
            assets={assets.slice(0, maxPhotos)}
            totalCount={assets.length}
            isGenerating={isGenerating}
            onSelect={(index) => setShownPhotoIndex(index)}
            latestNote={latest_note}
            onLoaded={(i) => setLoadedPhotos((loadedIndexes) => [...loadedIndexes, i])}
          />
        )}
      </div>
      {((includeBreak && !report_page_id) || needs_multiple_pages) && (
        <div className="html2pdf__page-break" />
      )}
      {needs_multiple_pages && (
        <>
          <div className={`p-4 border-b w-full`} style={{ height: 'auto' }}>
            <EntryPinHeader
              pin={pin}
              report={report}
              latestNote={latest_note}
              isGenerating={isGenerating}
              index={index}
              additional={true}
              photoIndex={assets.length + 1}
              onPhotoLoaded={(i) => setLoadedPhotos((loadedIndexes) => [...loadedIndexes, i])}
            />
            <EntryPinPhotos
              startIndex={maxPhotos}
              assets={assets.slice(maxPhotos)}
              extra={true}
              totalCount={assets.length}
              latestNote={latest_note}
              isGenerating={isGenerating}
              onSelect={(index) => setShownPhotoIndex(index)}
              onLoaded={(index) => setLoadedPhotos((loadedIndexes) => [...loadedIndexes, index])}
            />
          </div>
          {includeBreak && !report_page_id && (
            <div className="html2pdf__page-break" />
          )}
        </>
      )}
      <PhotosModal
        isOpen={shownPhotoIndex > -1}
        assets={assets}
        activeIndex={shownPhotoIndex}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setShownPhotoIndex(-1)}
      />
    </div>
  );
}

export default memo(EntryPin);
