import { memo, useEffect, useState } from 'react';
import {
  FiArrowLeft,
  FiArrowRight,
  FiCheckCircle,
  FiCircle,
  FiMaximize,
  FiMinimize,
  FiX,
  FiZoomIn,
  FiZoomOut,
} from 'react-icons/fi';
import useAuth from '../../auth/useAuth';
import DeleteButton from './DeleteButton';
import DownloadButton from './DownloadButton';
import Loader from './Loader';
import Modal from './Modal';
import PhotoModalImage from './PhotoModalImage';
import PhotoModalNote from './PhotoModalNote';
import ZoomToPinButton from './ZoomToPinButton';

function PhotosModal({
  allowDelete = true,
  assets = [],
  defaultFullScreen = false,
  isPDF = false,
  isVideo = false,
  activeIndex: passedActiveIndex = -1,
  selectedPhotoIndexes = [],
  onSelected,
  isOpen = false,
  hideBackdrop = false,
  onClose,
  onDelete,
}: {
  allowDelete?: boolean,
  assets?: any[],
  defaultFullScreen?: boolean,
  isPDF?: boolean,
  isVideo?: boolean,
  activeIndex?: number,
  selectedPhotoIndexes?: number[],
  onSelected?: any,
  isOpen?: boolean,
  hideBackdrop?: boolean,
  onClose?: any,
  onDelete?: any,
}) {
  const { user } = useAuth();
  const zoomLevels = isPDF
    ? [0.3, 0.4, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
    : ['scale-100', 'scale-105', 'scale-110', 'scale-125', 'scale-150'];
  const [hasOpened, setHasOpened] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noPreview, setNoPreview] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(isPDF ? 2 : 0);
  const [previousOffset, setPreviousOffset] = useState(0);
  const [fullScreen, setFullScreen] = useState(defaultFullScreen);
  const [activeIndex, setActiveIndex] = useState(passedActiveIndex);

  const [documentPreviewError, setDocumentPreviewError] = useState(false);

  const selected = selectedPhotoIndexes.includes(activeIndex);

  useEffect(() => {
    const keyHandler = ({ keyCode }: {
      keyCode: number
    }) => {
      if (![187, 189, 39, 37].includes(keyCode)) return;
      if (keyCode === 189 && zoomLevels[zoomLevel - 1] !== undefined)
        setZoomLevel(zoomLevel - 1);
      else if (keyCode === 187 && zoomLevels[zoomLevel + 1] !== undefined)
        setZoomLevel(zoomLevel + 1);
      else if (keyCode === 37 && assets[activeIndex - 1] !== undefined)
        setActiveIndex(activeIndex - 1);
      else if (keyCode === 39 && assets[activeIndex + 1] !== undefined)
        setActiveIndex(activeIndex + 1);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    let pinDialog = document.querySelector('.pin-dialog[style=""]');
    if (pinDialog) {
      setPreviousOffset(isOpen ? pinDialog.scrollTop : 0);
      if (isOpen) {
        setHasOpened(true);
        pinDialog.scrollTo(0, 0);
      } else if (hasOpened) {
        pinDialog.scrollTo(0, previousOffset);
      }
    }

    if (isOpen) return;
    setDocumentPreviewError(false);
    setZoomLevel(0);
    setLoading(true);
    setNoPreview(false);
  }, [isOpen]);

  useEffect(() => {
    setActiveIndex(passedActiveIndex);
  }, [passedActiveIndex]);

  return (
    <Modal
      isOpen={isOpen}
      maxWidth={'max-w-full'}
      modalScrolls={false}
      hideBackdrop={hideBackdrop}
      bgColor="black"
      modalClass="h-full"
      dialogClass="bg-gray-900 bg-opacity-60"
      onClose={onClose}
    >
      <div className="w-full h-full bg-black grid grid-cols-7">
        <div
          className={`col-span-7 ${fullScreen || isPDF ? 'lg:col-span-7' : 'lg:col-span-5'
            } relative flex items-center h-full`}
        >
          <div className="absolute bottom-5 md:bottom-10 inset-x-0 flex z-10 justify-center pointer-events-none px-3">
            <div className="text-white bg-gray-900 rounded-lg px-5 py-2">
              {assets[activeIndex]?.name}
              <span className="text-gray-300 italic ml-1">
                (Version {assets[activeIndex]?.version})
              </span>
            </div>
          </div>
          <div className="w-100 absolute inset-0 z-10 h-20 px-4 py-3 flex items-center justify-between bg-transparent">
            <button
              className="focus:outline-none cursor-pointer bg-black hover:bg-gray-900 flex items-center h-12 w-12 rounded-full flex justify-center text-white"
              title="Close modal"
              onClick={onClose}
            >
              <FiX size={34} />
            </button>
            <div className="text-white text-lg flex">
              {onSelected && (
                <button
                  className={`px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 mr-2 disabled:text-gray-500 hover:bg-gray-900 cursor-pointer text-white`}
                  onClick={() => onSelected?.({ selected, index: activeIndex })}
                  title="Click to select image"
                >
                  {selected ? (
                    <FiCheckCircle size={24} />
                  ) : (
                    <FiCircle size={24} />
                  )}
                </button>
              )}
              {allowDelete &&
                assets[activeIndex]?.creator_id === user?.id &&
                assets[activeIndex] && (
                  <DeleteButton
                    key={`delete_asset_button_${assets[activeIndex].objectId}`}
                    asset={assets[activeIndex]}
                    onDelete={onDelete}
                  />
                )}
              {assets[activeIndex] && user?.id && (
                <ZoomToPinButton asset={assets[activeIndex]} />
              )}
              {assets[activeIndex] && (
                <DownloadButton
                  file={assets[activeIndex].files.original}
                  name={assets[activeIndex].name}
                />
              )}
              <button
                className={`px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:opacity-50 mr-2 hover:bg-gray-900 cursor-pointer text-white ${zoomLevels[zoomLevel + 1]
                  ? ''
                  : 'opacity-50 pointer-events-none'
                  }
                ${isPDF ? 'hidden' : ''} 
                `}
                title="Zoom in"
                disabled={zoomLevels[zoomLevel + 1] === undefined}
                onClick={() => setZoomLevel(zoomLevel + 1)}
              >
                <FiZoomIn size={24} />
              </button>
              <button
                className={`px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:text-gray-500 mr-2 hover:bg-gray-900 cursor-pointer text-white ${zoomLevels[zoomLevel - 1]
                  ? ''
                  : 'opacity-50 pointer-events-none'
                  }
                ${isPDF ? 'hidden' : ''} 
                `}
                title="Zoom out"
                disabled={zoomLevels[zoomLevel - 1] === undefined}
                onClick={() => setZoomLevel(zoomLevel - 1)}
              >
                <FiZoomOut size={24} />
              </button>
              <button
                className={`${isPDF && 'hidden'
                  } px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:text-gray-500 hover:bg-gray-900 cursor-pointer text-white`}
                title="Toggle Fullscreen"
                onClick={() => setFullScreen(!fullScreen)}
              >
                {fullScreen ? (
                  <FiMinimize size={24} />
                ) : (
                  <FiMaximize size={24} />
                )}
              </button>
            </div>
          </div>

          <button
            className={`absolute left-3 z-10 px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:text-gray-500 hover:bg-gray-900 cursor-pointer text-white ${assets[activeIndex - 1] ? '' : 'opacity-50 pointer-events-none'
              }`}
            title="Previous photo"
            disabled={assets[activeIndex - 1] === undefined}
            onClick={() => {
              setActiveIndex(activeIndex - 1);
              setZoomLevel(0);
            }}
          >
            <FiArrowLeft size={34} />
          </button>
          <div className="flex flex-1 h-full max-h-screen items-center justify-center px-4 overflow-auto">
            <div
              className={`${isPDF ? 'max-w-screen px-10 h-full' : 'max-w-4xl'
                } relative`}
            >
              {noPreview && (
                <div className="text-white font-bold text-3xl h-screen py-5 w-full flex justify-center items-center">
                  <span>No preview available</span>
                </div>
              )}
              {!noPreview && !isPDF && assets[activeIndex] && (
                <img
                  className={`max-h-screen py-5 transition transition-transform duration-700 ease-in-out ${zoomLevels[zoomLevel] &&
                    'transform ' + zoomLevels[zoomLevel]
                    }`}
                  src={assets[activeIndex].files.original}
                  key={`photo_${assets[activeIndex].files.original}`}
                  onLoad={() => setLoading(false)}
                  onLoadStart={() => setLoading(true)}
                  onError={() => {
                    setLoading(false);
                    setNoPreview(true);
                  }}
                />
              )}
              {!noPreview && isPDF && assets[activeIndex] && (
                <iframe
                  src={assets[activeIndex].files.original}
                  className={'h-full w-screen pt-20 px-6'}
                  onLoad={() => setLoading(false)}
                />
              )}
              {documentPreviewError && (
                <div className="font-bold text-xl color-white">
                  This document cannot be previewed, please download it to view
                  its contents.
                </div>
              )}
              {isVideo && (
                <video
                  controls
                  autoPlay
                  muted
                  className={`max-h-screen py-5 transition transition-transform duration-700 ease-in-out ${zoomLevels[zoomLevel] &&
                    'transform ' + zoomLevels[zoomLevel]
                    }`}
                  key={`photo_${assets[activeIndex].files.original}`}
                  onLoadedData={() => setLoading(false)}
                >
                  <source src={assets[activeIndex].files.original}></source>
                </video>
              )}
              {loading && (
                <div className="absolute inset-0 justify-center items-center flex">
                  <Loader margin={''} />
                </div>
              )}
            </div>
          </div>
          <button
            className={`absolute right-3 z-10 px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:text-gray-500 hover:bg-gray-900 cursor-pointer text-white ${assets[activeIndex + 1] ? '' : 'opacity-50 pointer-events-none'
              }`}
            title="Next photo"
            disabled={assets[activeIndex + 1] === undefined}
            onClick={() => {
              setActiveIndex(activeIndex + 1);
              setZoomLevel(0);
            }}
          >
            <FiArrowRight size={34} />
          </button>
        </div>
        <div
          className={`col-span-7 lg:col-span-2 ${fullScreen || isPDF ? 'hidden' : ''
            } bg-gray-800 z-10`}
        >
          <div className="p-5 mt-5 text-left text-base">
            {assets[activeIndex] &&
              assets[activeIndex].assetable_type === 'Note' &&
              !fullScreen && (
                <PhotoModalNote
                  key={`note_details_${assets[activeIndex].assetable.objectId}`}
                  note_id={assets[activeIndex].assetable.objectId}
                />
              )}
            {assets[activeIndex] &&
              assets[activeIndex].assetable_type === 'Image' &&
              !fullScreen && (
                <PhotoModalImage
                  key={`image_details_${assets[activeIndex].assetable.objectId}`}
                  image_id={assets[activeIndex].assetable.objectId}
                />
              )}
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default memo(PhotosModal);
