import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import EditMenu from '../EditMenu';

function ProjectsTableCell({ project, workspaceId }) {
  const {
    objectId,
    name,
    identifier,
    updated_at,
    map: {
      location: { street_address, region, locality },
      center: { lat, lng },
      images: { large, thumbnail },
    },
    latest_members,
    total_member_count,
  } = project;

  return (
    <Link
      to={`/${workspaceId}/projects/${objectId}/details`}
      className="focus:outline-none flex items-center justify-between bg-white cursor-pointer border-b border-gray-200 p-5 hover:opacity-80"
    >
      <div className="flex items-center space-x-2">
        <div
          className="w-10 h-10"
          style={{
            backgroundImage: `url(${large})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
        <div className="text-sm sm:text-lg justify-center font-normal">
          <span className="font-medium">{identifier}</span> {name}
        </div>
        <div className="hidden md:flex px-2 text-sm justify-center italic">{`${street_address} ${locality}, ${region}`}</div>
        {/* <div className="pl-4 pr-2 text-primary border-l text-sm font-semibold">{member.permissionLevel}</div> */}
        {/* <div className="hidden sm:flex px-2 bg-sm-lightblue text-secondary rounded-md border-l text-sm">701 Easley Bridge Rd, Greenville, SC</div> */}
        <div className="hidden md:flex flex-shrink-0 -space-x-3 -ml-px">
          {latest_members.slice(0, 3).map((member) => {
            return (
              <Link
                key={`member_${member.objectId}`}
                className="block"
                to={`/projects/${objectId}/members/${member.objectId}`}
              >
                <div
                  className="rounded-full border-2 border-white box-content"
                  style={{
                    height: 28,
                    width: 28,
                    backgroundImage: `url(${
                      member.profile_photos?.medium ||
                      'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  alt={member.name}
                />
              </Link>
            );
          })}
          {total_member_count > 3 && (
            <div
              className="rounded-full border-2 border-white box-content bg-primary text-white text-xs flex items-center justify-center"
              style={{ height: 28, width: 28 }}
            >
              <p>+{total_member_count - 3}</p>
            </div>
          )}
        </div>
      </div>
      <div className="relative">
        <EditMenu className="" action="click">
          <li>
            <Link
              className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
              to={`/${workspaceId}/projects/${objectId}/details#edit`}
            >
              Edit Details
            </Link>
          </li>
          <li>
            <Link
              className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
              to={`/${workspaceId}/projects/${objectId}/details`}
            >
              View Project
            </Link>
          </li>
          {/* <li>
                <Link className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3" to="#0">Add Members</Link>
            </li>
            <li>
                <Link className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3" to="#0">Delete</Link>
            </li> */}
        </EditMenu>
      </div>
    </Link>
  );
}

export default memo(ProjectsTableCell);
