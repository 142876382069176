import { useState, memo } from 'react';
import useAuth from '../../auth/useAuth';
import ShareReport from '../shares/ShareReport';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../utility/Modal';

function PublishReportModal({ report, publishing, onPublish }) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);
  const { user } = useAuth();
  const [includeSelf, setIncludeSelf] = useState(true);

  const [sharedWith, setSharedWith] = useState([]);
  const [shareTypes, setShareTypes] = useState(['pdf', 'public_link']);

  const toggleShareType = (type) => {
    let found = shareTypes.includes(type);
    if (found) setShareTypes([...shareTypes.filter((st) => st !== type)]);
    else setShareTypes([...shareTypes, type]);
  };

  const submitShares = () => {
    let finalSharedWith = sharedWith.map(({ id }) => {
      return { shared_with_id: id, share_types: shareTypes };
    });

    if (includeSelf)
      finalSharedWith.push({
        shared_with_id: user.id,
        share_types: shareTypes,
      });

    onPublish && onPublish(finalSharedWith);
  };

  return (
    <>
      <button
        className="flex items-center content-center rounded-md text-white bg-green-500 py-1 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none"
        disabled={publishing}
        onClick={toggleModal}
      >
        {' '}
        Publish Report {publishing && <Loader />}
      </button>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={'Ready to Publish Report?'} onClose={toggleModal} />
        <ModalBody>
          <p className="mb-1">
            Once published we'll create a PDF of the report.{' '}
            <span className="italic">(usually takes 1 - 5 mins)</span>
          </p>
          <p className="mb-2">
            <span className="font-bold">
              When it's ready would you like to automatically share it?
            </span>
          </p>
          {modalOpen && (
            <ShareReport report={report} onSharedWithUpdate={setSharedWith} />
          )}
          <div className="flex mt-4">
            <p className={`mr-2 text-gray-500 font-medium text-sm`}>
              Include PDF
            </p>
            <div
              className={`form-switch focus-within:shadow-outline mr-3`}
              onClick={() => !publishing && toggleShareType('pdf')}
            >
              <input
                type="checkbox"
                id={`sendPDF_${report.objectId}`}
                className="sr-only"
                checked={shareTypes.includes('pdf')}
                onChange={() => { }}
              />
              <label
                className="bg-gray-300"
                htmlFor={`sendPDF_${report.objectId}`}
              >
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Include PDF</span>
              </label>
            </div>
            <p className={`mr-2 text-gray-500 font-medium text-sm`}>
              Include Public Link
            </p>
            <div
              className={`form-switch focus-within:shadow-outline `}
              onClick={() => !publishing && toggleShareType('public_link')}
            >
              <input
                type="checkbox"
                id={`sendMagicLink_${report.objectId}`}
                className="sr-only"
                checked={shareTypes.includes('public_link')}
                onChange={() => { }}
              />
              <label
                className="bg-gray-300"
                htmlFor={`sendMagicLink_${report.objectId}`}
              >
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Include Public Link</span>
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex mr-0 sm:mr-auto mb-6 sm:mb-0">
            <div
              className={`form-switch focus-within:shadow-outline mr-3`}
              onClick={() => !publishing && setIncludeSelf(!includeSelf)}
            >
              <input
                type="checkbox"
                id={`send_to_self_${report.objectId}`}
                className="sr-only"
                checked={includeSelf}
                onChange={() => { }}
              />
              <label
                className="bg-gray-300"
                htmlFor={`send_to_self_${report.objectId}`}
              >
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Send To Self PDF</span>
              </label>
            </div>
            <p className={`mr-2 text-gray-500 font-medium text-sm`}>
              Email me when report is ready
            </p>
          </div>
          <button
            className="modal-save-btn sm:w-auto text-center"
            disabled={publishing}
            type="button"
            onClick={submitShares}
          >
            Publish Report {publishing && <Loader />}
          </button>
          <button
            className="modal-close-btn hidden sm:block"
            disabled={publishing}
            type="button"
            onClick={toggleModal}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default memo(PublishReportModal);
