import { useEffect, useState, useMemo, useCallback } from 'react';
import { createConsumer } from '@rails/actioncable';
import { useStorage } from '../auth/storage';
import { environment } from '../config';

export const PROJECT_MAP_LAYERS_CHANNEL = 'ProjectMapLayersChannel';
export const REPORTS_CHANNEL = 'ReportsChannel';

const useActionCable = (channelName, params = {}, enabled = true) => {
  const { token } = useStorage();
  const [channel, setChannel] = useState(null);
  const [rejected, setRejected] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [receivedMessages, setReceivedMessages] = useState([]);

  const cable = useMemo(() => {
    return createConsumer(`${environment.actionCableUrl}?token=${token}`);
  }, [token]);

  const subscribe = useCallback(() => {
    if (channelName && !channel) {
      const newChannel = cable.subscriptions.create(
        {
          channel: channelName,
          ...params,
        },
        {
          connected: () => {
            setDisconnected(false);
            setRejected(false);
          },
          disconnected: () => {
            setDisconnected(true);
          },
          received: (data) => {
            setReceivedMessages((prevMessages) => [...prevMessages, data]);
          },
          rejected: () => {
            setRejected(true);
          },
        }
      );
      setChannel(newChannel);
    }
  }, [channelName, cable, channel]);

  const unsubscribe = useCallback(() => {
    if (channel) {
      cable.subscriptions.remove(channel);
      setChannel(null);
    }
  }, [channel, cable]);

  const sendMessage = useCallback(
    (message) => {
      if (channel) {
        channel.send(message);
      } else {
        console.error(
          'You must be subscribed to the channel before sending messages.'
        );
      }
    },
    [channel]
  );

  useEffect(() => {
    if (!token || !enabled) return;
    subscribe();
    return () => {
      unsubscribe();
    };
  }, [enabled, token, subscribe, unsubscribe]);

  const rejectedOrDisconnected = rejected || disconnected;

  return {
    receivedMessages,
    sendMessage,
    disconnected,
    rejected,
    rejectedOrDisconnected,
    unsubscribe,
  };
};

export default useActionCable;
