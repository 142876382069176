import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useEffect, useState } from 'react';

export default function useMapControls({ map, locked, showNavigationControls }) {

    const [navigation] = useState(
        new mapboxgl.NavigationControl({ visualizePitch: true })
    );

    const [userLocation] = useState(
        new mapboxgl.GeolocateControl({
            fitBoundsOptions: { maxZoom: 19 },
            positionOptions: { enableHighAccuracy: true },
            showUserLocation: true,
            showUserHeading: true,
            trackUserLocation: true
        })
    );

    const [scaleBar] = useState(
        new mapboxgl.ScaleControl({
            unit: 'imperial',
            maxWidth: 100,
        })
    );

    useEffect(() => {
        if (!map) return;

        if (locked) {
            if (showNavigationControls) {
                map.removeControl(navigation);
                map.removeControl(userLocation);
                map.removeControl(scaleBar);
            }
        } else {
            if (showNavigationControls) {
                map.addControl(scaleBar, 'bottom-right');
                map.addControl(navigation, 'bottom-right');
                map.addControl(userLocation, 'bottom-right');
            }
        }
    }, [map, locked]);
}