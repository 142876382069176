import { useContext } from 'react';
import WorkspaceContext from '../partials/workspaces/context';
export default function useWorkspaceContext() {
  const { workspace, setWorkspace, loading } = useContext(WorkspaceContext);

  return {
    workspace,
    setWorkspace,
    loading,
  };
}
