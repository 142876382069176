import client from './client';
const endpoint = '/projects';

const getProjects = (params = {}) => client.get(endpoint, params);
const getProject = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addProject = ({ project, workspaceId }) =>
  client.post(`${workspaceId ? `/workspaces/${workspaceId}` : ''}${endpoint}`, {
    project,
  });
const updateProject = (id, project) =>
  client.put(`${endpoint}/${id}`, { project });
const getWorkspaceProjects = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const deleteProject = (projectId) => client.delete(`${endpoint}/${projectId}`);
const getProjectFolderTree = (projectId, folderId) =>
  client.get(`${endpoint}/${projectId}/folders/${folderId}/tree`);

export default {
  getProjects,
  getProject,
  addProject,
  updateProject,
  getWorkspaceProjects,
  deleteProject,
  getProjectFolderTree,
};
