import { useState } from 'react';
import FeatureFlags from '../partials/feature_flags/FeatureFlags';
import Page, { PageHeader, PageTitle } from '../partials/shared/Page';
import Tabs, { Tab } from '../utils/Tabs';
import FeatureAnnouncements from '../partials/feature_announcements/FeatureAnnouncements';
export default function Features() {

    const [activeTab, setActiveTab] = useState(0);

    return (
        <Page>
            <PageHeader>
                <PageTitle>Features</PageTitle>
            </PageHeader>
            <Tabs>
                <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>Flags</Tab>
                <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Announcements</Tab>
            </Tabs>
            {activeTab === 0 && <FeatureFlags />}
            {activeTab === 1 && <FeatureAnnouncements />}
        </Page>
    );
}
