import { useEffect } from 'react';
import Form from '../../../utils/Form';
import useApi from '../../../hooks/useApi';
import featureFlagsApi from '../../../api/feature_flags';
import DatePicker from 'react-datepicker';
type FeatureAnnouncement = {
    feature_flag?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    loom_link?: string;
    faq_link?: string;
    expiration_date?: string;
    status?: string;
    active?: boolean;
}
export default function useFeatureAnnouncementForm(featureAnnouncement: FeatureAnnouncement, {
    onUpdate
}: { onUpdate: (field: string, value: any) => void }) {
    const {
        data: featureFlags,
        request: getFeatureFlags,
    } = useApi(featureFlagsApi.getFeatureFlags, []);

    useEffect(() => {
        getFeatureFlags();
    }, [])

    useEffect(() => {
        if (!featureFlags.length || !featureAnnouncement) return;
        if (!featureAnnouncement.feature_flag) onUpdate('feature_flag', featureFlags[0].key)
    }, [featureAnnouncement, featureFlags.length])

    return <><Form.Label>Feature Flag</Form.Label>
        <Form.InputGroup>
            <div className="flex items-center">
                <Form.Select
                    key={`selectFeatureAnnouncementFeatureFlag${featureAnnouncement?.feature_flag}`}
                    data-testid="selectFeatureAnnouncementFeatureFlag"
                    onChange={({ target: { value } }) => {
                        onUpdate('feature_flag', value)
                    }}
                    value={featureAnnouncement?.feature_flag}
                >
                    {featureFlags.map(({ key, name }: { key: string, name: string }) => (
                        <option key={key} value={key}>{name} | {key}</option>
                    ))}
                </Form.Select>
            </div>
        </Form.InputGroup>
        <Form.Label>Title</Form.Label>
        <Form.InputGroup>
            <div className="flex items-center">
                <Form.Input
                    type="text"
                    data-testid="inputFeatureAnnouncementTitle"
                    placeholder="Map layer align tool"
                    value={featureAnnouncement?.title}
                    onChange={({ target: { value } }) =>
                        onUpdate('title', value)
                    }
                />
            </div>
        </Form.InputGroup>
        <Form.Label>Expires at</Form.Label>
        <Form.InputGroup>
            <DatePicker
                selected={featureAnnouncement?.expiration_date ? new Date(featureAnnouncement.expiration_date) : null}
                onChange={(date: any) => onUpdate('expiration_date', date)}
                dateFormat="MM/dd/yyyy"
                placeholderText='mm/dd/yyyy'
                wrapperClassName="w-full"
                className='w-full border-0'
                data-testid="inputFeatureAnnouncementExpirationDate"
            />
        </Form.InputGroup>
        <Form.Label>Subtitle</Form.Label>
        <Form.InputGroup>
            <div className="flex items-center">
                <Form.Input
                    type="text"
                    placeholder="The quickest way to align pdf map layers"
                    value={featureAnnouncement?.subtitle}
                    onChange={({ target: { value } }) =>
                        onUpdate('subtitle', value)
                    }
                    data-testid="inputFeatureAnnouncementSubtitle"
                />
            </div>
        </Form.InputGroup>
        <Form.Label>Loom Link</Form.Label>
        <Form.InputGroup>
            <div className="flex items-center">
                <Form.Input
                    type="text"
                    placeholder="https://someloomlink.com"
                    value={featureAnnouncement?.loom_link}
                    onChange={({ target: { value } }) =>
                        onUpdate('loom_link', value)
                    }
                    data-testid="inputFeatureAnnouncementLoomLink"
                />
            </div>
        </Form.InputGroup>
        <Form.Label>Faq Link</Form.Label>
        <Form.InputGroup>
            <div className="flex items-center">
                <Form.Input
                    type="text"
                    placeholder="https://faq.sitemarker.com"
                    value={featureAnnouncement?.faq_link}
                    onChange={({ target: { value } }) =>
                        onUpdate('faq_link', value)
                    }
                    data-testid="inputFeatureAnnouncementFaqLink"
                />
            </div>
        </Form.InputGroup>
        <Form.Label>Description</Form.Label>
        <Form.InputGroup>
            <Form.TextArea
                placeholder="Longer form description of the feature that will be included in bottom of announcement."
                value={featureAnnouncement?.description}
                rows={4}
                onChange={({ target: { value } }) =>
                    onUpdate('description', value)
                }
                data-testid="inputFeatureAnnouncementDescription"
            />
        </Form.InputGroup>
        <Form.Label>Beta</Form.Label>
        <Form.Group>
            <Form.Switch data-testid="switchFeatureAnnouncementBeta" checked={featureAnnouncement?.status === 'beta'} htmlFor='featureAnnouncementStatus' onClick={() => onUpdate('status', featureAnnouncement.status === 'beta' ? 'new' : 'beta')} />
            <Form.HelperText>Set beta to include beta badge in announcement.</Form.HelperText>
        </Form.Group>
        <Form.Label>Live</Form.Label>
        <Form.Group>
            <Form.Switch data-testid="switchFeatureAnnouncementLive" checked={!!featureAnnouncement?.active} htmlFor='featureAnnouncementActive' onClick={() => onUpdate('active', !featureAnnouncement.active)} />
            <Form.HelperText>Set live for end-users to experience announcement.</Form.HelperText>
        </Form.Group>
    </>;
}