import React, { useEffect, useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import useApi from '../../hooks/useApi';
import projectsApi from '../../api/projects';
import Loader from '../utility/Loader';
import useProject from '../../hooks/useProject';

export default function AddContactItem({ workspace_member, afterUpdate }) {
  const { project } = useProject();
  const [added, setAdded] = useState(
    project.primary_contact?.objectId === workspace_member.member?.objectId
  );
  const {
    data,
    loading,
    request: updateProject,
  } = useApi(projectsApi.updateProject, false);

  const createNewProjectMember = () => {
    updateProject(project.objectId, {
      primary_contact_id: workspace_member.member.id,
    });
  };

  const deleteProjectMember = () => {
    updateProject(project.objectId, { primary_contact_id: null });
  };

  useEffect(() => {
    if (!data) return;
    afterUpdate?.(data);
    setAdded(
      data.primary_contact?.objectId === workspace_member.member?.objectId
    );
  }, [data]);

  return (
    <div className="flex items-center justify-between p-3 border-b border-gray-200">
      <div className="flex items-center">
        <img
          className="rounded-full w-12 h-12 object-cover mr-4"
          src={
            workspace_member.member.profile_photos?.medium ||
            'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
          }
        />
        <div className="text-left">
        <p className="truncate">
            {workspace_member.member.name || workspace_member.member.email}
          </p>
          {workspace_member.invitationPending?.() && (
            <p className="italic text-xs text-tertiary text-xs truncate">
              Invitation pending
            </p>
          )}
          <p className="font-bold text-xs truncate">
            {workspace_member.member.title}
          </p>
        </div>
      </div>
      {added ? (
        <a
          onClick={deleteProjectMember}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-green-500 px-3 py-1 rounded-md cursor-pointer"
        >
          Added as contact{' '}
          {loading ? (
            <div className="ml-3">
              <Loader />
            </div>
          ) : (
            <BiCheck size={20} className="ml-2 text-white" />
          )}
        </a>
      ) : (
        <a
          onClick={createNewProjectMember}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-secondary px-3 py-1 rounded-md cursor-pointer"
        >
          Add as contact{' '}
          {loading && (
            <div className="ml-3">
              <Loader />
            </div>
          )}
        </a>
      )}
    </div>
  );
}
