import { useEffect, useState } from 'react';
import SearchForm from '../actions/SearchForm';
import { IoMdList } from 'react-icons/io';
import { FiGrid, FiX } from 'react-icons/fi';
import constructionDrawingsApi from '../../api/construction_drawings';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import AddConstructionDrawingModal from './AddConstructionDrawingModal';
import PhotosModal from '../utility/PhotosModal';
import BulkDeleteConstructionDrawingModal from './BulkDeleteConstructionDrawingModal';
import { useParams } from 'react-router-dom';
import EmptyState from '../utility/EmptyState';
import BulkDownloadConstructionDrawingModal from './BulkDownloadConstructionDrawingModal';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import ConstructionDrawing from './ConstructionDrawing';
import useSidebarCollapsed from '../../hooks/useSidebarCollapsed';

export default function ConstructionDrawings() {
  const { project_id } = useParams();
  const [gridView, setGridView] = useState(true);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    items: 20,
    q: '',
  });
  const { collapsed } = useSidebarCollapsed();
  const [constructionDrawings, setConstructionDrawings] = useState([]);
  const [deletedDrawings, setDeletedDrawings] = useState([]);
  const [selectedDrawings, setSelectedDrawings] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [shownAssetIndex, setShowAssetIndex] = useState(-1);
  const {
    data: { records, pagy },
    error,
    loading,
    request: getConstructionDrawings,
  } = useApi(constructionDrawingsApi.getConstructionDrawings, {
    records: [],
    pagy: {},
  });

  const loadMore = () => {
    setSearchParams((params) => ({ ...params, page: params.page + 1 }));
  };

  const hasNextPage = () => !!pagy.next;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(),
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  const loadConstructionDrawings = () => {
    getConstructionDrawings(project_id, searchParams);
  };

  const updateDeletedDrawings = (objectIds) => {
    setDeletedDrawings([...deletedDrawings, ...objectIds]);
  };

  const availableDrawings = () =>
    constructionDrawings.filter(
      ({ objectId }) => !deletedDrawings.includes(objectId)
    );

  const handleSelectAllChange = (evt) => {
    evt.target.checked
      ? setSelectedDrawings(
        constructionDrawings.map(
          (constructionDrawing) => constructionDrawing.objectId
        )
      )
      : setSelectedDrawings([]);
  };
  useEffect(() => {
    if (loading) return;
    loadConstructionDrawings();
  }, [searchParams]);

  useEffect(() => {
    if (!records.length) return;
    setConstructionDrawings((existingRecords) => [
      ...existingRecords,
      ...records.filter((r) => !existingRecords.includes(r)),
    ]);
  }, [records]);

  useEffect(() => {
    setAllSelected(
      selectedDrawings.length === constructionDrawings.length &&
      constructionDrawings.length > 0
    );
  }, [selectedDrawings, constructionDrawings]);

  const viewCdEmptyState = () =>
    !loading && searchParams.q === '' && pagy.count === 0;

  return (
    <div className="p-5 w-full max-w-9xl self-start relative h-full">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        {!loading && !!constructionDrawings.length ? (
          <div
            className="bg-white px-3 py-2 rounded-lg border border-gray-300 shadow-sm"
            onClick={handleSelectAllChange}
          >
            <input
              id="cd_select_all"
              checked={allSelected}
              className="bg-gray-100 form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-secondary checked:border-secondary focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain cursor-pointer"
              type="checkbox"
            />{' '}
            <label
              htmlFor="cd_select_all"
              className="mx-1 cursor-pointer font-medium"
            >
              Select All
            </label>
          </div>
        ) : (
          <div />
        )}
        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => setGridView(true)}
          >
            <FiGrid
              size={18}
              className={!gridView ? 'text-gray-600' : 'text-primary'}
              title="Show as Grid"
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-3"
            onClick={() => setGridView(false)}
          >
            <IoMdList
              size={20}
              className={gridView ? 'text-gray-600' : 'text-primary'}
              title="Show as List"
            />
          </a>
          {/* Search form */}
          <SearchForm
            placeholder="Search Drawings..."
            onSearched={(text) => {
              setConstructionDrawings([]);
              setSearchParams({ q: text, page: 1, items: 10 });
            }}
          />
          {/* Add member button */}
          {/* <button className="btn rounded text-white bg-secondary">
                <svg className="w-4 h-4 fill-current opacity-50 flex-shrink-0" viewBox="0 0 16 16">
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Add New</span>
            </button> */}
          <AddConstructionDrawingModal
            afterAdd={() => {
              setConstructionDrawings([]);
              setSearchParams({ q: '', page: 1, items: 10 });
            }}
          />
        </div>
      </div>
      <div className={gridView && 'grid md:grid-cols-3 lg:grid-cols-5 gap-5'}>
        {availableDrawings().map((constructionDrawing, index) => {
          let { objectId } = constructionDrawing;
          return (
            <ConstructionDrawing
              key={`construction_drawing_grid_view_${objectId}`}
              viewMode={gridView ? 'grid' : 'list'}
              constructionDrawing={constructionDrawing}
              onSelect={({ target: { checked } }) =>
                setSelectedDrawings(
                  checked
                    ? [...selectedDrawings, objectId]
                    : [...selectedDrawings.filter((id) => id !== objectId)]
                )
              }
              selected={selectedDrawings.includes(objectId)}
              afterDelete={() => updateDeletedDrawings([objectId])}
              onClick={() => setShowAssetIndex(index)}
            />
          );
        })}
      </div>
      {(loading || hasNextPage()) && (
        <div className="flex justify-center py-10" ref={sentryRef}>
          <Loader color="black" />
        </div>
      )}
      {!loading &&
        searchParams.q.length > 0 &&
        constructionDrawings.length === 0 && (
          <div className="flex justify-center">
            <p className="empty-data-txt">
              No drawings found matching '{searchParams.q}'
            </p>
          </div>
        )}
      {viewCdEmptyState() ? (
        <>
          <div className="flex justify-center">
            <EmptyState
              heading={'Upload New CD'}
              subHeading={
                'Start adding new Construction Drawings to your project.'
              }
              hasButton={false}
            />
          </div>
          <div className="flex justify-center">
            <AddConstructionDrawingModal
              afterAdd={() => {
                setConstructionDrawings([]);
                setSearchParams({ q: '', page: 1, items: 10 });
              }}
            />
          </div>
        </>
      ) : null}
      <PhotosModal
        isOpen={shownAssetIndex > -1}
        assets={availableDrawings().map(({ asset }) => asset)}
        isPDF={true}
        activeIndex={shownAssetIndex}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setShowAssetIndex(-1)}
        allowDelete={false}
      />
      {selectedDrawings.length > 0 && (
        <>
          <div className={`bg-tertiary h-14 transition-all duration-200 ${collapsed ? 'left-16' : 'left-56'} right-5 fixed bottom-5 rounded-lg shadow-2xl flex justify-between items-center pl-6 pr-3`}>
            <div className="text-sm font-bold text-white">
              {selectedDrawings.length} drawing
              {selectedDrawings.length > 1 ? 's' : ''} selected
            </div>
            <div className="text-base font-bold text-white flex">
              <BulkDownloadConstructionDrawingModal
                constructionDrawingIds={selectedDrawings}
                afterDownload={() => {
                  setSelectedDrawings([]);
                }}
                downloadableUrls={constructionDrawings
                  .filter(({ objectId }) => selectedDrawings.includes(objectId))
                  .map(({ name, files: { original } }) => {
                    return { url: original, filename: name };
                  })}
              />
              <BulkDeleteConstructionDrawingModal
                afterDelete={() => {
                  updateDeletedDrawings(selectedDrawings);
                  setSelectedDrawings([]);
                  setConstructionDrawings([]);
                  setSearchParams({ q: '', page: 1, items: 10 });
                }}
                constructionDrawingIds={selectedDrawings}
              />
              <div className="border-l border-gray-50 border-opacity-25 mx-3"></div>
              <button
                href="#"
                className="cursor-pointer hover:bg-gray-800 rounded-full p-3"
                onClick={() => setSelectedDrawings([])}
              >
                <FiX className="text-white" />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
