import React, { useEffect, useState } from 'react';
import Loader from '../utility/Loader';
import ErrorView from '../utility/ErrorView';
import ErrorViewModel from '../../models/ErrorViewModel';
import AddMembersModal from '../members/AddMembersModal';
import AddProjectContactForm from './AddProjectContactForm';
import Modal, { ModalFooter, ModalHeader } from '../utility/Modal';
import DelayedSearchInput from '../utility/DelayedSearchInput';
import useModal from '../../hooks/useModal';
import useProjectContacts from '../../hooks/useProjectContacts';
import useAddContact from '../../hooks/useAddContact';
import useRemoveContact from '../../hooks/useRemoveContact';
import useUpdateContact from '../../hooks/useUpdateContact';
import useProject from '../../hooks/useProject';
import { Project, ProjectContact } from '../../data/models';
import useDebounce from '../../hooks/useDebounce';

interface ProjectContactsModalProps {
  title?: string;
  button?: React.ReactNode;
  afterUpdate?: (project: Project) => void;
  primaryContact?: {
    name: string;
    phone?: string;
    profile_photos?: {
      medium?: string;
    };
  };
  setProjectAndHideModal: (project: Project) => void;
  handleContactChange: () => void;
}

const ProjectContactsModal = ({
  title = 'Manage Project Contacts',
  button,
  afterUpdate,
  primaryContact,
  setProjectAndHideModal,
  handleContactChange
}: ProjectContactsModalProps) => {
  const baseFormData = {
    id: 0,
    role: '',
    company: '',
    contact_name: '',
    address_line_1: '',
    address_line_2: '',
    phone: '',
    email: '',
    updated_at: '',
    created_at: ''
  };

  const [error, setError] = useState<ErrorViewModel | null | unknown>(null);
  const { project, setProject } = useProject();
  const { open: modalOpen, toggle: toggleModal } = useModal();
  const [contacts, setContacts] = useState<ProjectContact[]>([]);
  const [loadingContacts, setLoadingContacts] = useState<number[]>([]);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [formData, setFormData] = useState(baseFormData);
  const [editingContactId, setEditingContactId] = useState<number | null>(null);
  const [searchingContacts, setSearchingContacts] = useState(false);

  const { contacts: projectContacts, loading: contactsLoading, reloadContacts } = useProjectContacts(project?.objectId);
  const { addedContact, addLoading, creationError, addContact } = useAddContact();
  const { removedContact, removeLoading, removeError, removeContact } = useRemoveContact();
  const { updatedContact, updateContact } = useUpdateContact();

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 100);

  const mapContactToFormData = (contact: ProjectContact) => ({
    id: contact.id,
    role: contact.role || '',
    company: contact.company || '',
    contact_name: contact.contact_name || '',
    address_line_1: contact.address_line_1 || '',
    address_line_2: contact.address_line_2 || '',
    phone: contact.phone || '',
    email: contact.email || '',
    updated_at: contact.updated_at || '',
    created_at: contact.created_at || ''
  });

  useEffect(() => {
    if (addedContact) {
      setProject?.((prevProject: Project | null) => {
        if (!prevProject) return null;
        return {
          ...prevProject,
          project_contacts: [...(prevProject.project_contacts || []), addedContact],
        };
      });
      reloadContacts();
      handleContactChange();
      setShowAddContactModal(false);
    }
  }, [addedContact]);

  useEffect(() => {
    if (removedContact) {
      setProject?.((prevProject: Project | null) => {
        if (!prevProject) return null;
        return {
          ...prevProject,
          project_contacts: prevProject.project_contacts?.filter(contact => contact.id !== removedContact.id)
        };
      });
      reloadContacts();
      handleContactChange();
    }
  }, [removedContact]);

  useEffect(() => {
    if (updatedContact) {
      setProject?.((prevProject: Project | null) => {
        if (!prevProject) return null;
        const updatedContacts = prevProject.project_contacts?.map(contact =>
          contact.id === updatedContact.id ? updatedContact : contact
        );
        return {
          ...prevProject,
          project_contacts: updatedContacts,
        };
      });
      reloadContacts();
      handleContactChange();
      setShowAddContactModal(false);
    }
  }, [updatedContact]);

  useEffect(() => {
    if (!modalOpen) return;
    reloadContacts();
  }, [modalOpen]);

  useEffect(() => {
    setContacts(projectContacts);
  }, [projectContacts]);


  useEffect(() => {
    const filteredContacts = projectContacts.filter((contact: ProjectContact) =>
      contact.contact_name.toLowerCase().includes(debouncedSearchText.toLowerCase()) ||
      contact.email?.toLowerCase().includes(debouncedSearchText.toLowerCase()) ||
      (contact.address_line_1 && contact.address_line_1.toLowerCase().includes(debouncedSearchText.toLowerCase())) ||
      (contact.address_line_2 && contact.address_line_2.toLowerCase().includes(debouncedSearchText.toLowerCase()))
    );
    setContacts(filteredContacts);
    setSearchingContacts(false);
  }, [debouncedSearchText, projectContacts]);

  const handleSearch = (text: string): void => {
    setSearchingContacts(true);
    setSearchText(text);
  };

  const handleAddContact = () => {
    if (!formData.contact_name || formData.contact_name.trim().length === 0)
      return setError(new ErrorViewModel({ contact_name: 'Contact name is required' }));
    if (!formData.email || formData.email.trim().length === 0)
      return setError(
        new ErrorViewModel({ email: 'Email is required' })
      );
    if (!formData.phone || formData.phone.trim().length === 0)
      return setError(
        new ErrorViewModel({ phone: 'Phone is required' })
      );
    setError(null);
    if (editingContactId) {
      updateContact(project?.objectId, editingContactId, formData)
        ?.finally(() => setEditingContactId(null));
    } else {
      addContact(project?.objectId, formData);
    }
    resetFormData();
  };

  const handleRemoveContact = async (contactId: number) => {
    if (!project?.objectId) {
      setError("Project is not defined. Cannot remove contact.");
      return;
    }

    setLoadingContacts((prev) => [...prev, contactId]);

    try {
      await removeContact(project.objectId, contactId);
    } catch (error) {
      setError(`Failed to remove contact: ${error}"`);
    } finally {
      setLoadingContacts((prev) => prev.filter((id) => id !== contactId));
    }
  };

  const handleEditContact = (contact: ProjectContact) => {
    setFormData(mapContactToFormData(contact));
    setEditingContactId(contact.id);
    setShowAddContactModal(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMailtoClick = (email: string | undefined) => {
    if(email) window.location.href = `mailto:${email}`;
  };

  const resetFormData = () => {
    setFormData(baseFormData);
  };

  return (
    <>
      {button ? (
        React.cloneElement(button as React.ReactElement, { onClick: toggleModal })
      ) : (
        <button className="btn" onClick={toggleModal}>
          {title}
        </button>
      )}
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={title} onClose={toggleModal} data-testid="projectContactsModal"/>
        <div className="bg-white overflow-hidden">
          {error ? <ErrorView error={error} extraClass={'mb-4'} /> : null}
          <div className="p-3 px-5 border-b border-gray-200">
            <p className="font-bold mb-2">Main Contact:</p>
            {primaryContact ? (
              <>
                <div className="flex cursor-pointer group relative items-center">
                  <img
                    className="rounded-full border-2 border-white float-left mr-2 h-9 w-9 object-cover"
                    src={
                      primaryContact.profile_photos?.medium ||
                      'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                    }
                  />
                  <div className="leading-snug">
                    <p>{primaryContact.name}</p>
                    <p className="text-gray-500">
                      {primaryContact.phone || 'Phone N/A'}
                    </p>
                    <AddMembersModal
                      forContact={true}
                      title="Update Main Contact"
                      afterUpdate={setProjectAndHideModal}
                      button={
                        <button className="transition-opacity duration-300 focus:outline-none absolute top-0 right-0 group-hover:opacity-100 bg-white px-2 py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold cursor-pointer">
                          Edit
                        </button>
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <AddMembersModal
                forContact={true}
                title="Add Main Contact"
                afterUpdate={setProjectAndHideModal}
                button={
                  <button className="focus:outline-none bg-white px-5 py-2 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer">
                    + Add Main Contact
                  </button>
                }
              />
            )}
          </div>
          <div className="mt-3">
            <p className="px-5 pt-3 pb-3 font-bold">Additional Contacts</p>
            <div className="px-5 mb-3 relative">
              <DelayedSearchInput
                placeholder="Search contacts..."
                onSearched={handleSearch}
                text={searchText}
              />
              <Loader color="black" className={`absolute right-14 top-2 ${searchingContacts ? '' : 'hidden'}`}/>
            </div>
          </div>
          {contactsLoading && contacts.length === 0 ? (
            <div className="flex justify-center py-5">
              <Loader color="black" />
            </div>
          ) : (
            <>
              {contacts.length > 0 ? (
                <div className="max-h-72 overflow-y-scroll">
                  {contacts.map((contact) => (
                    <div
                      key={contact.id}
                      className="flex items-top justify-between p-3 px-5 pt-2 border-b border-gray-200"
                    >
                      <div>
                        {contact.contact_name && (
                          <p className="text-sm font-semibold">
                            {contact.contact_name}
                          </p>
                        )}
                        {contact.company && (
                          <p className="text-xs text-gray-500">
                            {contact.role && (<span className="text-gray-600 font-semibold mr-1">{contact.role} -</span>)}<span>{contact.company}</span>
                          </p>
                        )}
                        {contact.email && (
                          <p className="text-xs text-gray-500">
                            <button
                              onClick={() =>handleMailtoClick(contact.email)}
                              className="text-gray-500 hover:underline border-none bg-transparent cursor-pointer"
                              aria-label={`Send email to ${contact.email}`}
                            >
                              {contact.email}
                            </button>
                          </p>
                        )}
                        {contact.phone && (
                          <p className="text-xs text-gray-500">
                            <a href={`tel:${contact.phone}`} className="text-gray-500 hover:underline">
                              {contact.phone}
                            </a>
                          </p>
                        )}
                        {contact.address_line_1 && (
                          <p className="text-xs text-gray-500">
                            {contact.address_line_1}
                          </p>
                        )}
                        {contact.address_line_2 && (
                          <p className="text-xs text-gray-500">
                            {contact.address_line_2}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          className="focus:outline-none group-hover:opacity-100 bg-white px-2 py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold cursor-pointer"
                          onClick={() => handleEditContact(contact)}
                        >
                          Edit
                        </button>
                        <button
                          className="focus:outline-none group-hover:opacity-100 bg-white px-2 py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold cursor-pointer text-red-500 flex items-center justify-center text-center"
                          disabled={loadingContacts.includes(contact.id)}
                          onClick={() => handleRemoveContact(contact.id)}
                        >
                          {loadingContacts.includes(contact.id) ? (
                            <>
                              <span>Removing</span><Loader color="black" />
                            </>
                          ) : (
                            'Remove'
                          )}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <p className="px-5 pb-2 pt-4 text-gray">No additional contacts found.</p>
                  <p className="px-5 text-gray-500"><i>Note: Additional contacts will not be added as members to the project.</i></p>
                </>
              )}
              <button
                className="focus:outline-none bg-white px-5 py-2 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer my-3 ml-5"
                onClick={() => setShowAddContactModal(true)}
              >
                + Add Additional Contact
              </button>
            </>
          )}
        </div>
        <ModalFooter>
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
            data-testid="closeProjectContactsModal"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showAddContactModal} onClose={() => setShowAddContactModal(false)}>
        <ModalHeader title={editingContactId ? "Edit Contact" : "Add New Contact"} onClose={() => setShowAddContactModal(false)} />
        <div className="bg-white overflow-hidden">
          <AddProjectContactForm
            formData={formData}
            handleChange={handleChange}
            error={error}
          />
        </div>
        <div className="bg-gray-50 border-0 flex justify-end p-4">
          <button
            type="submit"
            onClick={handleAddContact}
            disabled={addLoading}
            className="modal-save-btn"
            data-testid="saveContactButton"
          >
            {addLoading ? <Loader color="black" /> : (editingContactId ? 'Update Contact' : 'Add Contact')}
          </button>
          <button
            className="modal-close-btn"
            type="button"
            onClick={() => setShowAddContactModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectContactsModal;
