import { useEffect } from 'react';
import fieldsApi from '../../../api/fields';
import useApi from '../../../hooks/useApi';
export default function useField(fieldId: number | null) {
    const {
        data: field,
        error,
        loading,
        request: getField,
    } = useApi(fieldsApi.getField, null, true);

    const {
        data: updatedField,
        error: updateError,
        loading: updating,
        request: updateField,
    } = useApi(fieldsApi.updateField, null, true);

    const _updateField = (data: any) => {
        if (!fieldId) return;
        updateField(fieldId, data);
    }

    const reloadField = () => {
        if (!fieldId) return;
        getField(fieldId);
    }

    useEffect(() => {
        if (!fieldId) return;
        getField(fieldId);
    }, [fieldId])

    return {
        field,
        error,
        loading,
        getField,
        reloadField,
        updatedField,
        updateError,
        updating,
        updateField: _updateField,
    }
}