import { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import Table from '../../utils/Table';
import featureAnnoucementsApi from '../../api/feature_announcements';
import useModal from '../../hooks/useModal';
import CreateFeatureAnnouncementModal from './CreateFeatureAnnouncementModal';
import moment from 'moment';
import Button from '../shared/Button';
import PreviewFeatureAnnouncementModal from './PreviewFeatureAnnouncementModal';
import EditFeatureAnnouncementModal from './EditFeatureAnnouncementModal';
import Badge, { Dot } from '../shared/Badge';

const AddAnnoucementButton = ({ onClick }: { onClick: any }) => <div
    className="btn rounded cursor-pointer text-white hover:text-gray-50 hover:opacity-90 bg-secondary"
    data-testid="actionCreateFeatureAnnouncement"
    onClick={onClick}
>
    <svg
        className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
        viewBox="0 0 16 16"
    >
        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
    </svg>
    <span className="hidden xs:block ml-2">Add Annoucement</span>
</div>

function AnnouncementStatus({ featureAnnouncement }: { featureAnnouncement: any }) {
    return (
        <div className="w-16">
            {featureAnnouncement.status === 'beta' && (
                <Badge label="Beta" animated style={'green'} margin={''} title="This feature is currently in Beta - don't expect perfection!" />
            )}
            {featureAnnouncement.status === 'new' && (
                <Badge label="New" animated style={'warning'} margin={''} title="This feature is brand new! Enjoy!" />
            )}
        </div>
    );
}

export default function FeatureAnnouncements() {
    const {
        data: { records: featureAnnouncements },
        loading,
        request: getFeatureAnnouncements,
    } = useApi(featureAnnoucementsApi.getFeatureAnnouncements, { records: [], pagy: {} });


    const { open, close, toggle } = useModal();
    const [previewFeatureAnnouncement, setPreviewFeatureAnnouncement] = useState(null);
    const [editFeatureAnnouncement, setEditFeatureAnnouncement] = useState(null);

    const toggleModal = () => {
        setEditFeatureAnnouncement(null);
        close();
    }

    useEffect(() => {
        getFeatureAnnouncements();
    }, [])

    return <>
        <PreviewFeatureAnnouncementModal
            featureAnnouncement={previewFeatureAnnouncement}
            open={previewFeatureAnnouncement !== null}
            onClose={() => setPreviewFeatureAnnouncement(null)}
        />
        <CreateFeatureAnnouncementModal
            open={open}
            onClose={toggleModal}
            afterCreate={getFeatureAnnouncements}
        />
        <EditFeatureAnnouncementModal
            featureAnnouncement={editFeatureAnnouncement}
            open={!!editFeatureAnnouncement}
            onClose={() => setEditFeatureAnnouncement(null)}
            afterSave={getFeatureAnnouncements}
        />
        <Table.Table>
            <Table.Header>
                <h2 className="font-semibold text-gray-800">Feature Announcements</h2>
                <AddAnnoucementButton onClick={toggle} />
            </Table.Header>
            <Table.Body>
                <Table.Thead>
                    <Table.Th>Feature</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th>Live</Table.Th>
                    <Table.Th>View</Table.Th>
                    <Table.Th>Actions</Table.Th>
                </Table.Thead>
                <Table.Tbody>
                    {!loading && !featureAnnouncements.length && <tr>
                        <Table.Td colSpan={3} className="text-center px-2 py-3 italic">No feature announcements created</Table.Td>
                    </tr>}
                    {featureAnnouncements.map((featureAnnouncement: any) => {
                        return <tr>
                            <Table.Td data-testid={`featureAnnouncementFeatureFlag${featureAnnouncement.feature_flag}`}>{featureAnnouncement.feature_flag}</Table.Td>
                            <Table.Td>
                                <AnnouncementStatus featureAnnouncement={featureAnnouncement} />
                            </Table.Td>
                            <Table.Td data-testid={`featureAnnouncementLive${featureAnnouncement.feature_flag}`}>
                                <div className="flex items-center mb-1">{featureAnnouncement.active ? <> <Dot style="green" animated={false} /><p className="ml-2">Live</p></> : <> <Dot style="info" animated={false} /><p className="ml-2">Draft</p></>}</div>
                                <p className="font-medium">
                                    Expires <span className="italic">{moment(featureAnnouncement.expiration_date).fromNow()}</span>
                                </p>
                            </Table.Td>
                            <Table.Td data-testid={`featureAnnouncementFields${featureAnnouncement.feature_flag}`}>
                                <p className="font-semibold">{featureAnnouncement.title}</p>
                                <p className="font-medium">{featureAnnouncement.subtitle}</p>
                                <div className="flex">
                                    <p><a href={featureAnnouncement.loom_link} className="text-blue-800 underline mr-1" target="_blank" rel="noreferrer">Loom</a></p> &middot;
                                    <p><a href={featureAnnouncement.faq_link} className="text-blue-800 underline mr-1 ml-1" target="_blank" rel="noreferrer">Faq</a></p> &middot;
                                    <p><a href="#" onClick={() => setPreviewFeatureAnnouncement(featureAnnouncement)} className="text-blue-800 underline ml-1">Preview</a></p>
                                </div>

                            </Table.Td>
                            <Table.Td>
                                <Button
                                    data-testid={`featureAnnouncementEditButton${featureAnnouncement.feature_flag}`}
                                    text="Edit"
                                    color='light'
                                    onClick={() => setEditFeatureAnnouncement(featureAnnouncement)}
                                />
                            </Table.Td>
                        </tr>
                    })}
                </Table.Tbody>
            </Table.Body>
        </Table.Table></>;
}