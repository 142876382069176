import { useState } from 'react';
import { Status } from '../../data/models';
import Modal, { ModalBody, ModalHeader } from '../utility/Modal';
import Form from '../../utils/Form';
import Card from '../../utils/Card';
import { H3 } from '../../utils/Typography';
import usePinType from './hooks/usePinType';
import Loader, { LoaderContainer } from '../utility/Loader';
import FieldsetsForm, { FieldDropdownOption } from '../fieldsets/FieldsetsForm';
export default function PinTypeEditModal({
    pinTypeId,
    onClose,
}: {
    pinTypeId: number | null;
    onClose: () => void;
}) {
    const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
    const { pinType, loading, loadPinType } = usePinType(pinTypeId, { load: !!pinTypeId });

    return <Modal isOpen={!!pinTypeId} onClose={onClose} side
        maxHeight="h-full"
        maxWidth="lg:w-[352px] xl:w-[460px] 2xl:w-[calc(460px+80px)]"
        dialogPadding="px-0"
        modalClass="rounded-0"
    >
        <ModalHeader sticky title="Edit Pin type" onClose={onClose} />
        <ModalBody position='relative'>
            {loading && !pinType && <LoaderContainer>
                <Loader />
            </LoaderContainer>}
            {pinType && <>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <H3>{pinType.name}</H3>
                </Form.Group>
                {!!pinType.statuses.length && (
                    <Form.Group overflow='visible'>
                        <Form.Label>Status</Form.Label>
                        <Form.Dropdown
                            fullWidth
                            onSelect={(selectedId) => setSelectedStatus(pinType.statuses.find(({ id }) => id === selectedId) || null)}
                            options={pinType.statuses}
                            selected={selectedStatus?.id || -1}
                            element={({ option, isSelected, onClick }) => {
                                return <FieldDropdownOption
                                    key={`status${option.id}`}
                                    option={option}
                                    isSelected={isSelected}
                                    onClick={onClick}
                                />
                            }}
                        />
                        <Form.HelperText>Not currently editable - demo purposes only.</Form.HelperText>
                    </Form.Group>
                )}
                <Card.Card>
                    <Card.Content>
                        <Card.Body>
                            <Card.Header><H3>Custom fields</H3></Card.Header>
                            <FieldsetsForm pinType={pinType} afterUpdate={loadPinType} />
                        </Card.Body>
                    </Card.Content>
                </Card.Card>
            </>}
        </ModalBody>
    </Modal >
}