import React from 'react';
import Page, { PageHeader, PageTitle } from '../partials/shared/Page';
import DashboardCard from '../partials/dashboard/DashboardCard';
export default function AdminDashboard() {
  return (
    <Page>
      <PageHeader>
        <PageTitle>Admin Dashboard</PageTitle>
      </PageHeader>
      <div className="grid grid-cols-12 gap-6">
        <DashboardCard title={'New Users'} stats={'New users'} />
        <DashboardCard title={'New Projects'} stats={'New projects'} />
        <DashboardCard title={'New Pins'} stats={'New pins'} />
        <DashboardCard title={'New Reports'} stats={'New reports'} />
      </div>
    </Page>
  );
}
