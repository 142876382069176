import * as React from 'react';
import { useState, useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import reportsApi from '../../../api/reports';

export default function useReportCreationServices() {
    const {
        data: defaultReport,
        loading: newing,
        request: getNewReport,
    } = useApi(reportsApi.newReport, null);

    const {
        data: createdReport,
        loading: saving,
        request: addReport,
    } = useApi(reportsApi.addReport, null);

    return {
        defaultReport,
        newing,
        getNewReport,
        createdReport,
        saving,
        addReport,
    }
}