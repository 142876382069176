import { useEffect } from 'react';
import assetsApi from '../../../api/assets';
import useApi from '../../../hooks/useApi';
import { Asset } from '../../../data/models';
export default function useAssets(assetableId: string | number | null, assetableType: string, {
    load = true,
    share_id,
    params = {
        items: 100
    },
}: {
    load?: boolean
    share_id?: string
    params?: any
} = {}): {
    assets: Asset[];
    pagy: any;
    error: any;
    loading: boolean;
    loadAssets: (assetableType: string, assetableId: string | number, params: any) => void;
} {
    const {
        data: { records: assets, pagy },
        error,
        loading,
        request: loadAssets,
    } = useApi(assetsApi.getAssets, { records: [], pagy: {} }, load);

    useEffect(() => {
        if (!load || !assetableId) return;
        loadAssets(assetableType, assetableId, { share_id, ...params });
    }, [assetableId, assetableType, JSON.stringify(params), load, share_id]);

    return {
        assets,
        pagy,
        error,
        loading,
        loadAssets,
    };
}