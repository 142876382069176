import { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import featureFlagsApi from '../../api/feature_flags';
import Table from '../../utils/Table';
import Button from '../shared/Button';
import EditFeatureFlagModal from './EditFeatureFlagModal';

const AddFeatureFlagButton = ({ onClick }: { onClick: any }) => <div
    className="btn rounded cursor-pointer text-white hover:text-gray-50 hover:opacity-90 bg-secondary"
    data-testid="actionCreateFeatureFlag"
    onClick={onClick}
>
    <svg
        className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
        viewBox="0 0 16 16"
    >
        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
    </svg>
    <span className="hidden xs:block ml-2">Add Feature Flag</span>
</div>

type FeatureFlag = {
    name?: string;
    key?: string;
    description?: string;
    percentage?: number;
    users?: string[];
}

export default function FeatureFlags() {
    const {
        data,
        request: getFeatureFlags,
    } = useApi(featureFlagsApi.getFeatureFlags, []);

    const [updatingFeatureFlag, setUpdatingFeatureFlag] = useState<FeatureFlag | null>(null);
    const newFlag = !updatingFeatureFlag?.key;

    useEffect(() => {
        getFeatureFlags();
    }, [])

    return <>
        <EditFeatureFlagModal newFlag={newFlag} featureFlag={updatingFeatureFlag} open={!!updatingFeatureFlag} onClose={() => setUpdatingFeatureFlag(null)} afterCreate={getFeatureFlags} />
        <Table.Table>
            <Table.Header>
                <h2 className="font-semibold text-gray-800">Feature Flags</h2>
                <AddFeatureFlagButton onClick={() => {
                    setUpdatingFeatureFlag({})
                }} />
            </Table.Header>
            <Table.Body>
                <Table.Thead>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Key</Table.Th>
                    <Table.Th>Description</Table.Th>
                    <Table.Th>Percentage</Table.Th>
                    <Table.Th>Users</Table.Th>
                    <Table.Th>Actions</Table.Th>
                </Table.Thead>
                <Table.Tbody>
                    {data?.map((flag: any) => {
                        return <tr>
                            <Table.Td>{flag.name}</Table.Td>
                            <Table.Td data-testid={`featureFlagKey${flag.key}`}>{flag.key}</Table.Td>
                            <Table.Td data-testid={`featureFlagDescription${flag.key}`} className="max-w-xs">{flag.data?.description || 'N/A'}</Table.Td>
                            <Table.Td data-testid={`featureFlagPercentage${flag.key}`}>{flag.percentage}</Table.Td>
                            <Table.Td>{flag.users.length}</Table.Td>
                            <Table.Td>
                                <Button
                                    text="Edit"
                                    data-testid={`featureFlagEditButton${flag.key}`}
                                    color='light'
                                    onClick={() => setUpdatingFeatureFlag(flag)}
                                />
                            </Table.Td>
                        </tr>
                    })}
                </Table.Tbody>
            </Table.Body>
        </Table.Table></>;
}