// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { memo, useEffect, useRef, useState } from 'react';
import { BsBoundingBoxCircles } from 'react-icons/bs';
import { FaExclamation, FaLocationArrow, FaCog } from 'react-icons/fa';
import { useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api';
import useApi from '../../hooks/useApi';
import projectsApi from '../../api/projects';
import Loader from '../utility/Loader.js';
import ErrorViewModel from '../../models/ErrorViewModel.js';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import ErrorView from '../utility/ErrorView.js';
import { environment } from '../../config/index.js';
import useUserLocation from '../../hooks/useUserLocation.js';
import Button from '../shared/Button';
import useFeatures from '../../hooks/useFeatures';
import Tags from '../shared/Tags';
import { ContractDetails } from './ContractDetails';
import { ModalFooter } from '../utility/Modal';
import Form from '../../utils/Form';
import Badge from '../shared/Badge';
import { buildPinSvg } from '../maps/mapbox/MapBoxMap';
import timings from '../../styles/timings';
import { mapboxStyleUrls } from '../../data/models';
import InfoBadge from '../maps/InfoBadge';

const mapLibraries = ['places', 'geometry'];

const MAP_END_EVENTS = [
  'dragend',
  'zoomend',
];

const CoordinateInput = ({ ...rest }) => <input
  type="text"
  {...rest}
  className="flex-1 pr-2 pl-4 bg-white placeholder-gray-500 text-sm w-full border-0 border-r border-gray-300 focus:outline-none focus:ring-0"
/>
const AddressSearchInput = ({ defaultValue }) => <input
  type="text"
  placeholder="Ex: 321 Academy St, Greenville, SC 29601"
  data-testid="input-project-location"
  defaultValue={defaultValue}
  autoFocus={true}
  style={{
    boxSizing: `border-box`,
    borderLeft: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    borderTop: `1px solid transparent`,
    borderBottom: `2px solid gray`,
    width: `100%`,
    height: `46px`,
    padding: `12px 12px 10px 12px`,
    borderRadius: ``,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'relative',
    left: '0px',
    marginLeft: '0px',
    '::placeholder': {
      color: 'rgb(226, 232, 240)',
    },
  }}
/>

const LocationHelperCard = () => <div className="px-3 pb-3 pt-3 flex flex-col justify-center border-l border-r">
  <p className="text-center text-sm font-semibold items-center mb-1">
    Position the project bounds in your view - <span className="italic">this will be the default map view for the project.</span>
  </p>
  <p className="hidden sm:flex mx-auto text-center text-sm font-medium items-center text-secondary">
    <Badge style="info" animated={false} label="Pro Tip" margin='mr-2' /> {' '} Use <span className="bg-gray-200 text-gray-900 px-2 border border-gray-700 rounded-md shadow-sm mx-1">shift</span> + Drag to select a bounding box {' '} <BsBoundingBoxCircles size={18} className="ml-2" />
  </p>
</div>

const ProjectDetailsCard = ({ project, onEditClick }) => <div className="bg-white p-3 rounded-md relative shadow mb-4">
  <div className="px-2 mb-2 pb-2 border-b flex justify-between items-center">
    <span className="font-bold">Project Info</span>{' '}
    <button
      className="transition-opacity duration-300 focus:outline-none bg-white px-2 text-xs py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer"
      onClick={onEditClick}
    >
      Edit
    </button>
  </div>
  <div className="px-2">
    <p className="text-lg font-light font-oswald uppercase">
      <span className="font-normal">{project.identifier}</span>{' '}
      {project.name}
    </p>
    {project.description && (
      <p className="truncate text-sm text-gray-700 mt-2">
        {project.description}
      </p>
    )}
  </div>
</div>

function CreateProject({
  project: passedProject = null,
  step: passedStep = 'name',
  afterSave = null,
}) {
  const {
    data: newProject,
    error: creationError,
    loading,
    request: saveProject,
  } = useApi(
    passedProject ? projectsApi.updateProject : projectsApi.addProject,
    null
  );

  const { workspace_id } = useParams();
  const {
    features: { rollout: { project_tags } = {} },
  } = useFeatures();
  const history = useHistory();
  const [error, setError] = useState();
  const [step, setStep] = useState(passedStep);
  const [project, setProject] = useState(passedProject || {});
  const [tags, setTags] = useState([]);
  const markerRef = useRef(null);
  const [autoComplete, setAutoComplete] = useState(null);
  const [location, setLocation] = useState(null);
  const [{ currentZoom, center }, setMapAttributes] = useState({ currentZoom: 18, center: null });
  const [useCoordinates, setUseCoordinates] = useState(false);
  const {
    error: userLocationError,
    getUserLocation,
    locating,
    location: userLocation,
  } = useUserLocation();
  const mapContainerRef = useRef();
  const mapboxMap = useRef();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const setStatesFromExistingMap = () => {
    setMapAttributes({
      center: {
        lat: passedProject.map.center.lat,
        lng: passedProject.map.center.lng,
      },
      currentZoom: passedProject.map.default_zoom || currentZoom
    });
    setLocation({
      ...passedProject.map.location,
      coordinate_attributes: {
        lat: passedProject.map.center.lat,
        lng: passedProject.map.center.lng,
      },
    });
  };

  const submitProject = () => {
    if (!location)
      return setError(
        new ErrorViewModel({ project_location: 'Project location is required' })
      );

    let bounds = {
      north: mapboxMap.current.getBounds()._ne.lat,
      south: mapboxMap.current.getBounds()._sw.lat,
      east: mapboxMap.current.getBounds()._ne.lng,
      west: mapboxMap.current.getBounds()._sw.lng,
    };

    project.map_attributes = {
      default_zoom: currentZoom,
      location_attributes: location,
      bounds_attributes: [
        {
          direction: 'ne',
          lat: bounds.north,
          lng: bounds.east,
        },
        {
          direction: 'sw',
          lat: bounds.south,
          lng: bounds.west,
        },
      ],
      center_attributes: {
        lat: center.lat,
        lng: center.lng,
      },
    };

    if (passedProject) {
      project.map_attributes.id = passedProject.map.id;
      project.map_attributes.location_attributes.id =
        passedProject.map.location.id;
      project.map_attributes.center_attributes.id = passedProject.map.center.id;

      let neBound = passedProject.map.bounds.find(
        ({ direction }) => direction === 'ne'
      );
      let swBound = passedProject.map.bounds.find(
        ({ direction }) => direction === 'sw'
      );

      if (neBound) {
        project.map_attributes.bounds_attributes[0].id = neBound.id;
      }
      if (swBound) {
        project.map_attributes.bounds_attributes[1].id = swBound.id;
      }
    }

    project.tag_list = tags.map(({ name }) => name).join(', ');

    if (passedProject) {
      saveProject(passedProject.objectId, project);
    } else {
      let params = { project };
      if (!project.workspace_id) params.workspaceId = workspace_id;
      saveProject(params);
    }
  };

  const setName = () => {
    if (!project.name || project.name.trim().length === 0)
      return setError(new ErrorViewModel({ project_name: 'is required' }));
    if (!project.identifier || project.identifier.trim().length === 0)
      return setError(
        new ErrorViewModel({ project_identifier: 'is required' })
      );
    setError(null);
    setStep('map');
  };

  const updateProject = (key, value) => {
    setProject({ ...project, [key]: value });
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: environment.googleMapsKey,
    libraries: mapLibraries,
  });

  const updateMapAttributes = (key, value) => {
    let mapAttributes = project.map_attributes || {};
    mapAttributes.center_attributes = mapAttributes.center_attributes || {};
    mapAttributes.center_attributes[key] = value;
    setProject((existing) => ({ ...existing, map_attributes: mapAttributes }));
  };

  const setProjectFromPlace = (place) => {
    if (!place.geometry)
      return setError(
        new ErrorViewModel({
          location: 'No location found, please select address from dropdown',
        })
      );

    let centerLat = place.geometry.location.lat();
    let centerLng = place.geometry.location.lng();

    let address = place.address_components
      ? {
        address: place.formatted_address,
        street_address: `${place.address_components.find(
          ({ types }) => types.indexOf('street_number') > -1
        )
          ? place.address_components.find(
            ({ types }) => types.indexOf('street_number') > -1
          ).long_name
          : ''
          } ${place.address_components.find(
            ({ types }) => types.indexOf('route') > -1
          )
            ? place.address_components.find(
              ({ types }) => types.indexOf('route') > -1
            ).long_name
            : ''
          }`,
        locality: `${place.address_components.find(
          ({ types }) => types.indexOf('locality') > -1
        )
          ? place.address_components.find(
            ({ types }) => types.indexOf('locality') > -1
          ).short_name
          : ''
          }`,
        region: `${place.address_components.find(
          ({ types }) => types.indexOf('administrative_area_level_1') > -1
        )
          ? place.address_components.find(
            ({ types }) =>
              types.indexOf('administrative_area_level_1') > -1
          ).short_name
          : ''
          }`,
        postal_code: `${place.address_components.find(
          ({ types }) => types.indexOf('postal_code') > -1
        )
          ? place.address_components.find(
            ({ types }) => types.indexOf('postal_code') > -1
          ).short_name
          : ''
          }`,
      }
      : {
        address: place.formatted_address,
        street_address: `${place.formatted_address.split(',')[0]}`,
        locality: `${place.formatted_address.split(',')[1]}`,
        region: `${place.formatted_address.split(',')[2]}`,
        postal_code: `${place.formatted_address.split(',')[3]}`,
      };

    setLocation({
      ...address,
      coordinate_attributes: {
        lat: centerLat,
        lng: centerLng,
      },
    });

    setMapAttributes((ma) => ({ ...ma, center: { lat: centerLat, lng: centerLng } }));

    mapboxMap.current.flyTo({
      center: [centerLng, centerLat],
      zoom: currentZoom,
      maxDuration: timings.normal,
    });

  };

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      let place = autoComplete.getPlace();
      setProjectFromPlace(place);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const geocodeCoordinates = ({ lat, lng }) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setProjectFromPlace(results[0]);
        } else {
          setError(
            new ErrorViewModel({
              location: 'No results found for that location',
            })
          );
        }
      } else {
        setError(
          new ErrorViewModel({ location: 'No results found for that location' })
        );
      }
    });
  };

  const onLatLngPaste = (e) => {
    let clipboardDataText = e.clipboardData.getData('Text');
    if (!clipboardDataText.includes(',')) return;
    let [lat, lng] = clipboardDataText.split(',');
    updateMapAttributes('lat', lat.trim());
    updateMapAttributes('lng', lng.trim());
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    setStep(passedStep);
  }, [passedStep]);

  useEffect(() => {
    if (!passedProject) return;
    setStatesFromExistingMap();
  }, [passedProject]);

  useEffect(() => {
    if (!newProject || !passedProject) return;
    afterSave?.(newProject);
  }, [newProject]);

  useEffect(() => {
    setError(creationError);
  }, [creationError]);

  useEffect(() => {
    if (!userLocation) return;
    const { lat, lng } = userLocation;
    updateMapAttributes('lat', lat);
    updateMapAttributes('lng', lng);
    geocodeCoordinates({ lat, lng });
  }, [userLocation]);

  useEffect(() => {
    if (!mapContainerRef.current || mapboxMap.current) return;

    mapboxMap.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapboxStyleUrls['satellite-streets'],
      zoom: currentZoom,
    });

  }, [mapboxMap.current, mapContainerRef.current]);

  useEffect(() => {
    if (!mapboxMap.current) return;
    mapboxMap.current.resize();
  }, [step, mapboxMap.current]);

  useEffect(() => {
    if (!mapboxMap.current) return;
    const idleListener = (e) => {
      const map = e.target;
      const mapZoom = map.getZoom();
      const mapCenter = map.getCenter();
      const mapZoomChange = mapZoom !== currentZoom;
      const mapCenterChange = mapCenter.lat !== center?.lat || mapCenter.lng !== center?.lng;
      if (mapZoomChange || mapCenterChange) setMapAttributes({ currentZoom: mapZoom, center: { lat: mapCenter.lat, lng: mapCenter.lng } });
    };

    MAP_END_EVENTS.forEach(event => mapboxMap.current.on(event, idleListener));

    return () => {
      MAP_END_EVENTS.forEach(event => mapboxMap.current.off(event, idleListener));
    }
  }, [mapboxMap.current])

  useEffect(() => {
    if (!center || !mapboxMap.current) return;

    const onMarkerDragEnd = (e) => {
      const { lat, lng } = e.target._lngLat;
      mapboxMap.current.flyTo({
        center: [lng, lat],
        zoom: currentZoom,
        maxDuration: timings.normal,
      });
      setMapAttributes((ma) => ({ ...ma, center: { lat, lng } }));
    }

    if (!markerRef.current) {
      markerRef.current = new mapboxgl.Marker({
        draggable: true,
        element: buildPinSvg({
          type: 'pin',
          innerHTML: '+',
          pinStyle: {
            color: 'orange',
            icon: 'pin',
            showCount: false,
          },
          scale: 1
        }),
        anchor: 'bottom',
      }).setLngLat([center.lng, center.lat]).addTo(mapboxMap.current);
    } else {
      markerRef.current.setLngLat([center.lng, center.lat]);
    }

    markerRef.current.on('dragend', onMarkerDragEnd)

    return () => markerRef.current?.off?.('dragend', onMarkerDragEnd);

  }, [JSON.stringify(center), currentZoom, mapboxMap.current]);

  if (newProject && !passedProject)
    return (
      <Redirect
        to={`/${workspace_id}/projects/${newProject.objectId}/details?pcd=true`}
      />
    );

  return (
    <div className="text-base relative min-h-full pb-10">
      <div className="hidden md:block px-4 sm:px-6 lg:px-8 py-4 bg-white mb-4 sm:mb-0">
        <h1 className="text-2xl md:text-3xl text-gray-800 uppercase font-light font-oswald subpixel-antialiased">
          Add New Project
        </h1>
      </div>
      <div className="pt-2">
        <div className="p-5">
          {error && <ErrorView error={error} extraClass={'mb-4'} />}
          {step === 'name' && (
            <>
              <Form.Label>Identifier</Form.Label>
              <Form.InputGroup>
                <Form.Input
                  type="text"
                  name="identifier"
                  data-testid="input-project-identifier"
                  placeholder="Ex: GR2753"
                  value={project.identifier}
                  onChange={({ target: { value } }) => updateProject('identifier', value)}
                />
              </Form.InputGroup>
              <Form.Label>Name</Form.Label>
              <Form.InputGroup>
                <Form.Input
                  type="text"
                  name="name"
                  data-testid="input-project-name"
                  placeholder="Ex: Judson Mills"
                  value={project.name}
                  onChange={({ target: { value } }) => updateProject('name', value)}
                />
              </Form.InputGroup>

              {project_tags && (
                <>
                  <Form.Label>Tags</Form.Label>
                  <Tags
                    tags={tags}
                    allowCreate={true}
                    onAdd={(newTag) =>
                      setTags((oldTags) => [...oldTags.filter(({ name }) => name !== newTag.name), newTag])
                    }
                    onRemove={(removedTag) =>
                      setTags((oldTags) => [
                        ...oldTags.filter(
                          ({ name }) => name !== removedTag.name
                        ),
                      ])
                    }
                    keyPrefix={'newProject'}
                    containerClassName="normal-case font-normal font-inter items-center flex mt-2 mb-5 flex-wrap"
                    tagClassName={'mr-1 mb-1'}
                    maxTags={0}
                    canAdd={true}
                  />
                </>
              )}
              <Form.Label>Description</Form.Label>
              <Form.InputGroup>
                <Form.TextArea
                  type="text"
                  name="description"
                  data-testid="input-project-description"
                  placeholder="Ex: Consists of 820 Acres 4 miles south west of main"
                  value={project.description}
                  onChange={({ target: { value } }) => updateProject('description', value)}
                  rows={5}
                />
              </Form.InputGroup>
              <div className="pb-2 mb-2">
                <button
                  onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                  className="flex items-center transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer px-5 py-2"
                >
                  <FaCog size="12" /><span className="ml-2">{showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'}</span>
                </button>
              </div>
              {showAdvancedSettings && (
                <Form.Group>
                  <ContractDetails
                    project={project}
                    setProject={setProject}
                    showProgressBar={false}
                    inputContainerClassNames={
                      {
                        startDate: 'flex-1',
                        endDate: 'flex-1 pl-4',
                        amount: 'flex-1 pl-4'
                      }
                    }
                    inputClassNames={
                      {
                        startDate: 'bg-gray-50 placeholder-gray-400 relative text-sm w-full outline-none focus:outline-none border-l-0 border-t-0 border-b border-gray-200',
                        endDate: 'bg-gray-50 placeholder-gray-400 relative text-sm w-full outline-none focus:outline-none border-l-0 border-t-0 border-b border-gray-200',
                        amount: 'px-2 bg-gray-50 placeholder-gray-400 relative text-sm w-full outline-none focus:outline-none border-l-0 border-t-0 border-b-0 border-r border-gray-200',
                        dollar: 'flex items-center text-gray-500 bg-gray-50 border-b border-gray-200'
                      }
                    }
                  />
                </Form.Group>
              )}
              <div className="flex justify-between">
                {passedProject && (
                  <button
                    className="modal-save-btn"
                    type="button"
                    data-testid="action-project-save-btn"
                    disabled={loading}
                    onClick={submitProject}
                  >
                    Save {loading && <Loader />}
                  </button>
                )}
              </div>
            </>
          )}
          <div className={step !== 'map' ? 'hidden' : ''}>
            <ProjectDetailsCard project={project} onEditClick={() => setStep('name')} />
            <div className="font-semibold sm:px-2 mb-2 flex justify-between sm:items-center flex-col sm:flex-row">
              <div className="mb-2 sm:mb-0">Location</div>
              <div>
                <Button
                  color={'light'}
                  size="sm"
                  text="Use current location"
                  spacing="mr-2"
                  onClick={getUserLocation}
                  saving={locating}
                  title={userLocationError || ''}
                  icon={
                    locating ? null : userLocationError ? (
                      <FaExclamation
                        className="ml-2 text-red-600"
                        size={12}
                      />
                    ) : (
                      <FaLocationArrow
                        className="ml-2 text-secondary opacity-80"
                        size={12}
                      />
                    )
                  }
                />
                <Button
                  color={'light'}
                  size="sm"
                  text={useCoordinates ? 'Use address' : 'Use coordinates'}
                  onClick={() => setUseCoordinates(!useCoordinates)}
                />
              </div>
            </div>
            <div className="mb-20">
              <div className="flex items-center">
                {useCoordinates && (
                  <div
                    className={`flex bg-white py-0.5 items-center justify-center w-full border overflow-hidden ${location ? 'rounded-t-md' : 'rounded-md'
                      }`}
                  >
                    <CoordinateInput
                      defaultValue={center ? center.lat : ''}
                      value={project.map_attributes?.center_attributes?.lat}
                      placeholder="Latitude"
                      onChange={({ target: { value } }) =>
                        updateMapAttributes('lat', value)
                      }
                      onPaste={onLatLngPaste}
                    />
                    <CoordinateInput
                      type="text"
                      defaultValue={center ? center.lng : ''}
                      value={project.map_attributes?.center_attributes?.lng}
                      placeholder="Longitude"
                      onChange={({ target: { value } }) =>
                        updateMapAttributes('lng', value)
                      }
                      onPaste={onLatLngPaste}
                    />
                    <Button
                      text='Set'
                      onClick={() =>
                        geocodeCoordinates({
                          lat: project?.map_attributes?.center_attributes
                            ?.lat,
                          lng: project?.map_attributes?.center_attributes
                            ?.lng,
                        })
                      }
                    />
                  </div>
                )}
              </div>
              {isLoaded && (
                <div
                  className={`bg-white rounded-b-md shadow border relative ${!location && !useCoordinates && 'h-12 overflow-hidden'
                    }`}
                >
                  {!useCoordinates && (
                    <StandaloneSearchBox
                      onLoad={setAutoComplete}
                      onPlaceChanged={onPlaceChanged}
                      onPlacesChanged={() => {
                        setProjectFromPlace(autoComplete.getPlaces()[0]);
                      }}
                    >
                      <AddressSearchInput defaultValue={location ? location.address : null} />
                    </StandaloneSearchBox>
                  )}

                  <div
                    ref={mapContainerRef}
                    className={`w-full h-full`}
                    style={{ height: 400 }}
                  >
                    <InfoBadge>
                      Drag and drop marker to set new center
                    </InfoBadge>
                  </div>



                  {location && <LocationHelperCard />}
                </div>
              )}
            </div>
          </div>
        </div>

        <ModalFooter justify={'justify-between'} fixed={true}>
          {step === 'map' ? (
            <button
              className={'modal-close-btn hidden md:block'}
              disabled={loading}
              type="button"
              onClick={() => setStep('name')}
              data-testid="action-project-toggle-name"
            >
              {'Edit Details'}
            </button>
          ) : (
            <button
              className={passedProject ? 'modal-close-btn' : 'modal-save-btn'}
              disabled={
                !passedProject &&
                (!project.name ||
                  project.name.trim().length == 0 ||
                  !project.identifier ||
                  project.identifier.trim().length === 0)
              }
              type="button"
              onClick={setName}
              data-testid="action-project-add-location"
            >
              {passedProject ? 'Edit Map' : 'Add Location'}
            </button>
          )}
          <div className="flex">
            {step === 'map' && (
              <button
                className="modal-save-btn md:mr-2"
                type="button"
                disabled={loading}
                onClick={submitProject}
              >
                {passedProject ? 'Save' : 'Create'} Project{' '}
                {loading && <Loader />}
              </button>
            )}
            <button
              className="modal-close-btn  hidden md:block"
              type="button"
              disabled={loading}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </div>
    </div>
  );
}

export default memo(CreateProject);
