import { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import entryGroupsApi from '../../api/entry_groups';
export default function useEntryGroup(entryGroupId: string | null, {
    load = true
}: {
    load?: boolean
} = {
    }) {
    const {
        data: entryGroup,
        error,
        loading,
        request: loadEntryGroup,
    } = useApi(entryGroupsApi.getEntryGroup, null, load);

    const {
        data: updatedEntryGroup,
        error: updateError,
        loading: updating,
        request: updateEntryGroup,
    } = useApi(entryGroupsApi.updateEntryGroup, null);

    const {
        data: resettedEntryGroup,
        error: resetError,
        loading: reseting,
        request: resetEntryGroup,
    } = useApi(entryGroupsApi.resetEntryGroup, null);

    const _loadEntryGroup = () => {
        if (entryGroupId) loadEntryGroup(entryGroupId);
    }

    const _updateEntryGroup = (entryGroup: any) => {
        if (entryGroupId) updateEntryGroup(entryGroupId, entryGroup);
    }

    const _resetEntryGroup = () => {
        if (entryGroupId) resetEntryGroup(entryGroupId);
    }

    useEffect(() => {
        if (!load || !entryGroupId) return;
        _loadEntryGroup();
    }, [load, entryGroupId]);

    return {
        latestEntryGroup: resettedEntryGroup || updatedEntryGroup || entryGroup,
        error,
        loading,
        loadEntryGroup: _loadEntryGroup,
        updatedEntryGroup,
        updateError,
        updating,
        updateEntryGroup: _updateEntryGroup,
        resettedEntryGroup,
        resetError,
        reseting,
        resetEntryGroup: _resetEntryGroup,
    }
}