import * as React from 'react';
import { FeatureFlag, Product, Workspace } from '../../data/models';
import Form from '../../utils/Form';
import useProducts from '../products/hooks/useProducts';
import capitalize from '../../utility/capitalize';
import PermissionsViewModel from '../../models/PermissionsViewModel';
import useFeatureFlags from '../feature_flags/hooks/useFeatureFlags';
import Loader from '../utility/Loader';
export default function WorkspaceForm({
    workspace,
    onUpdate,
    permissions
}: {
    workspace: Workspace | null
    onUpdate: (field: string, value: any) => void,
    permissions?: PermissionsViewModel
}) {

    const { featureFlags, loading } = useFeatureFlags(permissions?.can?.('admin'));

    if (!workspace) return null;

    const testIdPrefix = workspace?.id ? 'edit' : 'create';
    const testIdSuffix = workspace?.id ? workspace.name : '';

    return <><Form.Label>Name</Form.Label>
        <Form.InputGroup>
            <Form.Input
                type="text"
                placeholder="Enter Company Name"
                data-testId={`${testIdPrefix}WorkspaceName${testIdSuffix}`}
                value={workspace.name}
                onChange={({ target: { value } }) =>
                    onUpdate('name', value)
                }
            />
        </Form.InputGroup>
        {permissions?.can?.('admin') && (
            <>
                <Form.Label>Subscription</Form.Label>
                <SubscriptionSelect
                    onSelect={({ target: { value: product_id } }) => {
                        onUpdate('active_subscription_attributes', { id: workspace.active_subscription?.id, product_id, status: 'active' })
                    }}
                    data-testId={`${testIdPrefix}WorkspaceSubscription${testIdSuffix}`}
                    selected={workspace.active_subscription?.product?.id}
                />
                <Form.Label>Custom Domain</Form.Label>
                <Form.InputGroup>
                    <Form.Input
                        type="text"
                        placeholder="company.com"
                        data-testId={`${testIdPrefix}WorkspaceDomain${testIdSuffix}`}
                        value={workspace.custom_domain}
                        onChange={({ target: { value } }) =>
                            onUpdate('custom_domain', value)
                        }
                    />
                </Form.InputGroup>
                <Form.Group className='flex'>
                    <Form.Switch checked={!!workspace.allow_matching_custom_domain_signups}
                        data-testId={`${testIdPrefix}WorkspaceAllowCustomDomain${testIdSuffix}`}
                        htmlFor={'workspaceCustomDomain'}
                        onClick={() => {
                            onUpdate(
                                'allow_matching_custom_domain_signups',
                                !workspace.allow_matching_custom_domain_signups
                            )
                        }}
                    />
                    <Form.SwitchLabel>
                        Allow{' '}
                        <span className="font-semibold">
                            {workspace.custom_domain ? '@' + workspace.custom_domain : 'domain'}{' '}
                        </span>
                        users to join{' '}
                        <span className="font-semibold">
                            {workspace.name || 'workspace'}
                        </span>
                    </Form.SwitchLabel>
                </Form.Group>
                <Form.Group className='flex'>
                    <Form.Switch checked={!!workspace.conference}
                        data-testId={`${testIdPrefix}WorkspaceAllowCustomDomain${testIdSuffix}`}
                        htmlFor={'workspaceConference'}
                        onClick={() => {
                            onUpdate(
                                'conference',
                                !workspace.conference
                            )
                        }}
                    />
                    <Form.SwitchLabel>
                        Is this workspace for a conference?
                    </Form.SwitchLabel>
                </Form.Group>
                <Form.Group className="bg-white border-b border-gray-200 rounded-lg shadow-sm p-3">
                    <Form.Label>
                        Feature Flags{' '}
                        {loading && <Loader color="text-gray-800" />}
                    </Form.Label>
                    {featureFlags
                        .filter(({ percentage }) => percentage !== 100)
                        .map((featureFlag) => {
                            const flagOn = workspace.feature_flags?.includes?.(featureFlag.key);
                            return (
                                <FeatureFlagCheckbox
                                    featureFlag={featureFlag}
                                    checked={flagOn}
                                    onClick={() => {
                                        const workspaceFeatureFlags = workspace.feature_flags || [];
                                        if (flagOn) {
                                            const index = workspaceFeatureFlags.indexOf(featureFlag.key);
                                            workspaceFeatureFlags.splice(index, 1);
                                        } else {
                                            workspaceFeatureFlags.push(featureFlag.key);
                                        }
                                        onUpdate('feature_flags', workspaceFeatureFlags);
                                    }}
                                />
                            );
                        })}
                </Form.Group>
            </>)}
    </>;
}

const FeatureFlagCheckbox = ({ featureFlag, onClick, checked }: {
    featureFlag: FeatureFlag,
    onClick?: () => void,
    checked: boolean
}) => (<Form.Group mb={2} className='flex items-center h-12 border p-2 rounded-lg shadow-xs'>
    <Form.Switch checked={checked}
        htmlFor={`featureFlag${featureFlag.key}`}
        onClick={onClick}
    />
    <Form.SwitchLabel>
        <p className="text-gray-800 text-sm mb-0 font-medium">{featureFlag.name}</p>
        <p className="text-gray-600 italic text-sm">
            {featureFlag.data.description}{' '}
        </p>
    </Form.SwitchLabel>
</Form.Group>);

const SubscriptionSelect = ({ onSelect, selected, ...rest }: {
    onSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    selected?: number,
    [key: string]: any
}) => {
    const { products } = useProducts();
    return (
        <Form.SelectGroup>
            <Form.Select
                onChange={onSelect}
                {...rest}
            >
                <option value="">No Active Subscription</option>
                {products.map(({ id, name }: Product) =>
                    <option selected={selected === id} value={id}>{capitalize(name)}</option>
                )}
            </Form.Select>
        </Form.SelectGroup>
    );

}