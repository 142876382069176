import { memo, useEffect, useRef, useState } from 'react';
import useApi from '../../hooks/useApi';
import useProject from '../../hooks/useProject';
import rolesApi from '../../api/roles';
import usersApi from '../../api/users';
import workspacesApi from '../../api/workspaces';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import humanize from '../../utility/humanize';
import WorkspaceInput from '../workspaces/WorkspaceInput';
import invitationsApi from '../../api/invitations';
import useAuth from '../../auth/useAuth';
import { Redirect, useParams } from 'react-router-dom';
import formatPhoneNumber from '../../utility/formatPhoneNumber';
import { AddExtraProps } from '../../utility/addExtraProps';
import PermissionsInfoModal from './PermissionsInfoModal';
import ErrorView from '../utility/ErrorView';
import InfoModal from '../utility/InfoModal';

function AddMemberModal({
  workspace: passedWorkspace,
  workspaceMember: passedWorkspaceMember,
  user: passedUser = {},
  button,
  afterCreate,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const { user: currentUser, permissions } = useAuth();
  const {
    data: roles,
    error: rolesError,
    loading: rolesLoading,
    request: getRoles,
  } = useApi(rolesApi.getWorkspaceRoles, []);
  const {
    data: invitedUser,
    error,
    loading,
    request: inviteUser,
  } = useApi(invitationsApi.invite, null);
  const {
    data: updatedUser,
    error: updateError,
    loading: updating,
    request: updateExistingUser,
  } = useApi(usersApi.updateUser, null);
  const {
    data: loadedWorkspace,
    error: workspaceError,
    loading: loadingWorkspace,
    request: loadWorkspace,
  } = useApi(workspacesApi.getWorkspace, null);
  const [user, setUser] = useState({ ...passedUser, skip_invitation: false });
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const { workspace_id } = useParams();
  const { project } = useProject();
  const [chosenWorkspace, setChosenWorkspace] = useState(
    passedWorkspace || { objectId: workspace_id }
  );
  const [workspaceMember, setWorkspaceMember] = useState(
    passedWorkspaceMember
      ? passedWorkspaceMember
      : {
        creator_id: currentUser.id,
        workspace_id: passedWorkspace ? passedWorkspace.id : workspace_id,
      }
  );

  const isUpdatingUser = () => passedUser && passedUser.objectId;

  const updateUser = (key, value) => {
    if (key === 'phone') value = formatPhoneNumber(value);
    user[key] = value;
    setUser({ ...user });
  };

  const updateWorkspaceMember = (key, value) => {
    workspaceMember[key] = value;
    setWorkspaceMember({ ...workspaceMember });
  };

  const submitUser = () => {
    user.workspace_members_attributes = [workspaceMember];
    if (project)
      user.project_members_attributes = [
        { project_id: project.id, creator_id: currentUser.id },
      ];
    updateExistingUser(passedUser.objectId, user);
  };

  const submitInvitation = () => {
    user.workspace_members_attributes = [workspaceMember];
    if (project)
      user.project_members_attributes = [
        { project_id: project.id, creator_id: currentUser.id },
      ];
    inviteUser(user);
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const clearModal = () => {
    clearTimeout(savedTimer.current);
    setModalOpen(false);
    if (
      (!passedWorkspace ||
        passedWorkspace.objectId === chosenWorkspace.objectId) &&
      saved
    )
      afterCreate && afterCreate(invitedUser);
    savedTimer.current = setTimeout(() => setSaved(false), 500);
  };

  useEffect(() => {
    !passedWorkspace && loadWorkspace(workspace_id);
  }, [passedWorkspace]);

  useEffect(() => {
    modalOpen && getRoles(workspace_id);
  }, [modalOpen]);

  useEffect(() => {
    chosenWorkspace &&
      updateWorkspaceMember('workspace_id', chosenWorkspace.id);
  }, [chosenWorkspace]);

  useEffect(() => {
    passedWorkspace && setChosenWorkspace({ ...passedWorkspace });
  }, [passedWorkspace]);

  useEffect(() => {
    passedWorkspaceMember && setWorkspaceMember(passedWorkspaceMember);
  }, [passedWorkspaceMember]);

  useEffect(() => {
    loadedWorkspace && setChosenWorkspace({ ...loadedWorkspace });
  }, [loadedWorkspace]);

  useEffect(() => {
    (invitedUser || updatedUser) && setSaved(true);
  }, [invitedUser, updatedUser]);

  useEffect(() => {
    !modalOpen && savedTimer.current && clearModal();
  }, [modalOpen]);

  useEffect(() => {
    if (saved) {
      setUser({ skip_invitation: user.skip_invitation });
      savedTimer.current = setTimeout(clearModal, 20000);
    }
  }, [saved]);

  if (!chosenWorkspace) return <span />;

  if (workspace_id !== chosenWorkspace.objectId && invitedUser && !modalOpen)
    return <Redirect to={`/${chosenWorkspace.objectId}/team`} />;

  return (
    <>
      {/* Button */}
      {button ? (
        AddExtraProps(button, { onClick: toggleModal })
      ) : (
        <a className="cursor-pointer" onClick={() => setModalOpen(!modalOpen)}>
          <div className="btn rounded text-white bg-secondary hover:text-gray-50 hover:opacity-90">
            <svg
              className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add Member</span>
          </div>
        </a>
      )}
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={'Invite New Team Member'} onClose={toggleModal} />
        {saved ? (
          <ModalSuccessView
            text={
              <div className="flex flex-col justify-center items-center">
                {isUpdatingUser() && (
                  <p className="text-xl text-gray-500 font-base text-center px-4">
                    Updated member{' '}
                    <span className="font-semibold">{updatedUser.name}</span>
                  </p>
                )}
                {!isUpdatingUser() && (
                  <>
                    <p className="text-xl text-gray-900 font-normal text-center px-4 mb-2">
                      New member{' '}
                      <span className="font-semibold">
                        {invitedUser.name || invitedUser.email}
                      </span>{' '}
                      created.
                    </p>
                    <p className="text-sm text-blue-600 font-normal mx-5 text-center p-4 mb-2 bg-blue-50 rounded shadow">
                      Member added to{' '}
                      <span className="font-semibold">
                        {chosenWorkspace.name}
                      </span>{' '}
                      workspace
                      {project && (
                        <span>
                          {' '}
                          and{' '}
                          <span className="font-semibold">
                            {project.name}
                          </span>{' '}
                          project
                        </span>
                      )}
                      .
                    </p>
                    {!user.skip_invitation && (
                      <p className="text-sm mt-2 italic text-gray-600 font-normal text-center px-4">
                        We sent an invitation to{' '}
                        <span className="font-semibold">
                          {invitedUser.email}
                        </span>{' '}
                        to signup.
                      </p>
                    )}
                  </>
                )}
              </div>
            }
          />
        ) : (
          <div className="px-2 pt-2 text-left text-gray-900 text-base">
            {error && <ErrorView error={error} extraClass={'mb-4'} />}
            <div className="grid grid-cols-2 relative">
              <div
                className={`text-xs absolute bottom-0 right-5 ${!permissions?.can?.('admin') && 'hidden'
                  }`}
              >
                <span className="text-red-500">* Required</span>
              </div>
              <div>
                <div className="p-5">
                  <div
                    className={`font-semibold px-2 mb-2 ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                    data-testid="addMemberName"
                  >
                    Name
                  </div>
                  <div
                    className={`bg-white border-b border-gray-200 overflow-hidden mb-8 ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="Ex: Landon Messal"
                        value={user.name}
                        onChange={({ target: { value } }) =>
                          updateUser('name', value)
                        }
                        className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="font-semibold px-2 mb-2">
                    Email <span className="text-red-500">*</span>
                  </div>
                  <div className="bg-white border-b border-gray-200 overflow-hidden mb-8">
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="name@email.com"
                        value={user.email}
                        onChange={({ target: { value } }) =>
                          updateUser('email', value)
                        }
                        className="px-2 bg-gray-50  placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                      />
                    </div>
                  </div>
                  <div
                    className={`font-semibold px-2 mb-2 flex items-center ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    Password{' '}
                    <InfoModal
                      title={'Password Info'}
                      message={
                        <div className="text-center py-6 px-6">
                          <div className="font-medium text-xl">
                            Password is not required if an invitation is sent to
                            new user.
                          </div>
                          <div className="font-normal py-2 text-gray-600">
                            New user will be able to add password during
                            onboarding.
                          </div>
                        </div>
                      }
                    />
                  </div>
                  <div
                    className={`bg-white border-b border-gray-200 overflow-hidden mb-8 ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="********"
                        value={user.password}
                        onChange={({ target: { value } }) =>
                          updateUser('password', value)
                        }
                        className="px-2 bg-gray-50  placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                      />
                    </div>
                  </div>
                  <div
                    className={`font-semibold px-2 mb-2 ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    Phone
                  </div>
                  <div
                    className={`bg-white border-b border-gray-200 overflow-hidden ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="555-555-5555"
                        value={user.phone}
                        onChange={({ target: { value } }) =>
                          updateUser('phone', value)
                        }
                        className="px-2 bg-gray-50  placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-5">
                  <div
                    className={`font-semibold px-2 mb-2 ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    Title
                  </div>
                  <div
                    className={`bg-white border-b border-gray-200 overflow-hidden mb-8 ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    <div className="flex items-center">
                      <input
                        type="text"
                        placeholder="Ex: Civil PE"
                        value={user.title}
                        onChange={({ target: { value } }) =>
                          updateUser('title', value)
                        }
                        className="px-2 bg-gray-50  placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="font-semibold px-2 mb-2 hidden">
                    Organization
                  </div>
                  {/* <WorkspaceInput workspace={chosenWorkspace} onChosen={(w) => {
                            setChosenWorkspace({...w})
                        }}/> */}
                  <div className="font-semibold px-2 mb-2 flex items-center">
                    Permission Level{' '}
                    <span className="ml-2 text-red-500">*</span>{' '}
                    <PermissionsInfoModal />{' '}
                  </div>
                  <div className="flex flex-wrap text-sm justify-center">
                    {rolesLoading && (
                      <div className="py-2">
                        <Loader margin={''} color="text-gray-800" />
                      </div>
                    )}
                    {!rolesLoading &&
                      roles
                        .filter(({ name }) => name.indexOf('workspace') > -1)
                        .map(({ id, name }) => (
                          <a
                            key={`add_member_modal_role_${id}`}
                            className={`bg-white cursor-pointer mt-2 mr-2 px-2 py-1 border rounded-md hover:border-secondary hover:text-secondary hover:bg-blue-50 ${workspaceMember.role_id === id &&
                              'border-secondary text-secondary bg-blue-50'
                              }`}
                            onClick={({ target: { value } }) =>
                              updateWorkspaceMember('role_id', id)
                            }
                          >
                            {humanize(name.replace('workspace_', ''))}
                          </a>
                        ))}
                  </div>
                  <div
                    className={`bg-gray-50 pb-4 mt-3 rounded-lg shadow border ${!permissions?.can?.('admin') && 'hidden'
                      }`}
                  >
                    <p className="px-2 border-b pb-1 pt-1 mb-3 text-xs bg-white rounded-t-lg">
                      Account Controls
                    </p>
                    {!isUpdatingUser() && (
                      <div className={`flex flex-grow mb-3 justify-end`}>
                        <p className={`mr-2 text-gray-500 font-medium text-sm`}>
                          Send Invitation
                        </p>
                        <div
                          className={`form-switch focus-within:shadow-outline mr-3`}
                          onClick={() =>
                            updateUser('skip_invitation', !user.skip_invitation)
                          }
                        >
                          <input
                            type="checkbox"
                            id={`includeInvitation`}
                            className="sr-only"
                            checked={!user.skip_invitation}
                            onChange={() => { }}
                          />
                          <label
                            className="bg-gray-300"
                            htmlFor={`includeInvitation`}
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Send Invitation</span>
                          </label>
                        </div>
                      </div>
                    )}
                    {!isUpdatingUser() && (
                      <div
                        className={`flex flex-grow mb-3 justify-end ${!permissions?.can?.('admin') && 'hidden'
                          }`}
                      >
                        <p className={`mr-2 text-gray-500 font-medium text-sm`}>
                          Send Welcome Email
                        </p>
                        <div
                          className={`form-switch focus-within:shadow-outline mr-3`}
                          onClick={() =>
                            updateUser(
                              'welcome_email_sent',
                              !user.welcome_email_sent
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            id={`welcomeEmail`}
                            className="sr-only"
                            checked={!user.welcome_email_sent}
                            onChange={() => { }}
                          />
                          <label
                            className="bg-gray-300"
                            htmlFor={`welcomeEmail`}
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Send Welcome Email</span>
                          </label>
                        </div>
                      </div>
                    )}
                    <div
                      className={`flex flex-grow justify-end ${!permissions?.can?.('admin') && 'hidden'
                        }`}
                    >
                      <p className={`mr-2 text-gray-500 font-medium text-sm`}>
                        Auto Confirm Email
                      </p>
                      <div
                        className={`form-switch focus-within:shadow-outline mr-3`}
                        onClick={() =>
                          updateUser(
                            'confirmed_at',
                            user.confirmed_at ? null : new Date()
                          )
                        }
                      >
                        <input
                          type="checkbox"
                          id={`autoConfirm`}
                          className="sr-only"
                          checked={user.confirmed_at}
                          onChange={() => { }}
                        />
                        <label className="bg-gray-300" htmlFor={`autoConfirm`}>
                          <span
                            className="bg-white shadow-sm"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Auto Confirm Email</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                disabled={loading}
                onClick={isUpdatingUser() ? submitUser : submitInvitation}
              >
                {isUpdatingUser() ? 'Update' : 'Add New'} Member{' '}
                {(loading || updating) && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={loading}
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default memo(AddMemberModal);
