import React, { useEffect } from 'react';
import EntryTextInput from '../entries/EntryTextInput';
import EntryTextareaInput from '../entries/EntryTextareaInput';
import EntryDatetimeInput from '../entries/EntryDatetimeInput';
import EntryCheckboxes from '../entries/EntryCheckboxes';
import EntryDropdown from '../entries/EntryDropdown';

const entryDocumentComponents = {
  EntryDropdown,
  EntryTextInput,
  EntryTextareaInput,
  EntryDatetimeInput,
  EntryCheckboxes,
};

export default function EntryGroupDocument({
  entries,
  editing = true,
  onLoaded,
}) {
  useEffect(() => {
    onLoaded && onLoaded();
  }, []);

  return (
    <div className="border-b border-r grid grid-cols-4 text-xxs">
      {entries.map((entry, index) => {
        const EntryDocumentComponent =
          entryDocumentComponents[entry.component_name];
        return (
          <EntryDocumentComponent
            editing={editing}
            key={`entry_document_component_${entry.objectId}`}
            entry={entry}
            useBottomBorder={index !== entries.length - 1}
          />
        );
      })}
    </div>
  );
}
