import React, { memo, useEffect, useState } from 'react';

import SearchForm from '../partials/actions/SearchForm';
import useApi from '../hooks/useApi';
import projectsApi from '../api/projects';
import ProjectsCard from '../partials/project/ProjectsCard';
import Loader from '../partials/utility/Loader';
import ProjectsTableCell from '../partials/project/ProjectsTableCell';

import { IoMdList } from 'react-icons/io';
import { FiGrid, FiX } from 'react-icons/fi';
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import { BiUser } from 'react-icons/bi';
import colors from '../styles/colors';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';
import EmptyState from '../partials/utility/EmptyState';
import useWorkspaceContext from '../hooks/useWorkspaceContext';
import useAuth from '../auth/useAuth';
import useFeatures from '../hooks/useFeatures';
import TagsFilter from '../partials/shared/TagsFilter';

function Projects() {
  const { workspace_id } = useParams();
  const history = useHistory();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    items: 10,
    q: '',
    order: 'created_at desc',
  });
  const [projects, setProjects] = useState([]);
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadProjects,
  } = useApi(projectsApi.getWorkspaceProjects, { records: [], pagy: {} }, true);
  const [gridView, setGridView] = useState(true);
  const {
    workspace: { permissions = {} },
  } = useWorkspaceContext();
  const { user } = useAuth();
  const {
    features: { rollout: { project_tags } = {} },
  } = useFeatures();

  useEffect(() => {
    setProjects([]);
  }, [workspace_id]);

  useEffect(() => {
    loadProjects(workspace_id, searchParams);
  }, [workspace_id, searchParams]);

  useEffect(() => {
    if (!records.length) return;
    const existingProjectIds = projects.map(({ id }) => id);
    setProjects([
      ...projects,
      ...records.filter((p) => !existingProjectIds.includes(p.id)),
    ]);
  }, [records]);

  if (error && error.text() === "Couldn't find Workspace")
    return (
      <Redirect
        to={{
          pathname: `/${user.default_workspace.objectId}/projects`,
          state: { error: error.text() },
        }}
      />
    );

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl self-start" >
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className={`mb-4 sm:mb-0 ${window.isNativeApp && 'hidden'}`}>
          <h1 className="text-2xl md:text-3xl text-gray-800 font-poppins font-semibold uppercase">
            Projects
          </h1>
        </div>

        {/* Right: Actions */}
        <div
          className={`grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 items-center ${window.isNativeApp && 'hidden'
            }`}
        >
          <a
            className={`group relative flex items-center cursor-pointer hover:opacity-70 ${project_tags ? 'mr-1' : 'mr-3'
              } ${searchParams.with_membership ? 'bg-secondary' : 'bg-white'
              } rounded-full shadow-sm px-5 h-8`}
            title="Only show Projects I'm a member of"
            onClick={() => {
              setProjects([]);
              setSearchParams({
                ...searchParams,
                with_membership: searchParams.with_membership ? null : true,
              });
            }}
          >
            <BiUser
              size={18}
              color={
                searchParams.with_membership ? colors.white : colors.darkGray
              }
            />
            <button className="focus:outline-none">
              <FiX
                size={14}
                className={`${searchParams.with_membership
                  ? 'text-white'
                  : 'transform text-gray-400 rotate-45'
                  } opacity-0 group-hover:opacity-100 hover:opacity-60 cursor-pointer absolute top-2 right-2`}
              />
            </button>
          </a>
          {project_tags && (
            <TagsFilter
              taggableType="Project"
              onChange={(tags) => {
                setProjects([]);
                setSearchParams({
                  ...searchParams,
                  tags,
                });
              }}
            />
          )}
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => {
              setProjects([]);
              setSearchParams({ ...searchParams, order: 'updated_at desc' });
            }}
          >
            <FaRegCalendarAlt
              size={18}
              className={
                searchParams.order === 'updated_at desc'
                  ? 'text-primary'
                  : 'text-gray-600'
              }
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => {
              setProjects([]);
              setSearchParams({ ...searchParams, order: 'name asc' });
            }}
          >
            <FaSortAlphaDown
              size={18}
              className={
                searchParams.order === 'name asc'
                  ? 'text-primary'
                  : 'text-gray-600'
              }
            />
          </a>
          <a
            className="flex items-center cursor-pointer pr-3 border-r border-gray-300 border-r-2 mr-1"
            onClick={() => {
              setProjects([]);
              setSearchParams({ ...searchParams, order: 'name desc' });
            }}
          >
            <FaSortAlphaUp
              size={18}
              className={
                searchParams.order === 'name desc'
                  ? 'text-primary'
                  : 'text-gray-600'
              }
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => setGridView(true)}
          >
            <FiGrid
              size={18}
              className={gridView ? 'text-primary' : 'text-gray-600'}
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-3"
            onClick={() => setGridView(false)}
          >
            <IoMdList
              size={20}
              className={!gridView ? 'text-primary' : 'text-gray-600'}
            />
          </a>
          {/* Search form */}
          <SearchForm
            placeholder="Search by address or name..."
            onSearched={(text) => {
              setProjects([]);
              setSearchParams({ q: text, page: 1, items: 10 });
            }}
          />

          {permissions?.can?.('create_projects') && (
            <Link
              className="cursor-pointer"
              to={`/${workspace_id}/projects/new`}
            >
              <div
                className="btn rounded text-white hover:text-gray-50 hover:opacity-90 bg-secondary"
                data-testid="action-create-project"
              >
                <svg
                  className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Add Project</span>
              </div>
            </Link>
          )}
        </div>
      </div>
      {loading && (
        <div className="flex py-4 justify-center">
          <Loader color="black" />
        </div>
      )}
      {window.isNativeApp && permissions?.can?.('create_projects') && (
        <Link className="cursor-pointer" to={`/${workspace_id}/projects/new`}>
          <div
            className="btn rounded text-white hover:text-gray-50 hover:opacity-90 bg-secondary"
            data-testid="action-create-project"
          >
            <svg
              className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Add Project</span>
          </div>
        </Link>
      )}

      {!loading &&
        searchParams.q.trim().length > 0 &&
        projects.length === 0 && (
          <div className="flex justify-center">
            <p className="empty-data-txt">
              No projects found matching '{searchParams.q}'
            </p>
          </div>
        )}
      {!loading &&
        searchParams.q.trim().length === 0 &&
        projects.length === 0 && (
          <div className="flex justify-center">
            {permissions?.can?.('create_projects') ? (
              <EmptyState
                heading={"Let's get started"}
                subHeading={
                  'Create your first project, add some map layers and start dropping pins.'
                }
                buttonText={'Add Project'}
                buttonTestId={'action-create-project'}
                onClick={() => history.push(`/${workspace_id}/projects/new`)}
              />
            ) : (
              <EmptyState
                heading={
                  permissions?.can?.('read_all_projects')
                    ? 'No projects created yet'
                    : 'No projects to show'
                }
                subHeading={
                  permissions?.can?.('read_all_projects')
                    ? "You don't have permissions to create a project on this workspace."
                    : "You haven't been invited to any projects on this workspace."
                }
                hasButton={false}
              />
            )}
          </div>
        )}

      {/* View Types */}
      {gridView && (
        <div className="grid grid-cols-12 gap-6">
          {projects.map((project) => (
            <ProjectsCard
              key={`project_${project.objectId}`}
              project={project}
              workspaceId={workspace_id}
            />
          ))}
        </div>
      )}

      {!gridView && (
        <div>
          {projects.map((project) => (
            <ProjectsTableCell
              key={project.objectId}
              project={project}
              workspaceId={workspace_id}
            />
          ))}
        </div>
      )}

      {!loading &&
        pagy.count !== undefined &&
        pagy.count !== projects.length && (
          <div
            className="flex justify-center p-3 border-b border-gray-200"
            onClick={() =>
              setSearchParams({ ...searchParams, page: searchParams.page + 1 })
            }
          >
            <div className="text-black hover:opacity-80 text-sm font-semibold bg-white px-4 mt-1 py-2 shadow cursor-pointer">
              Load More
            </div>
          </div>
        )}
    </div>
  );
}

export default memo(Projects);
