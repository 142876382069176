import humanize from '../utility/humanize';

export default class ErrorViewModel {
  constructor(object) {
    Object.assign(this, object);
  }

  text() {
    let firstErrorKey = Object.keys(this)[0];
    return `${this[firstErrorKey]}`;
  }

  includes(key) {
    return this[key] || false;
  }
}
