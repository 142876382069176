import {
  useEffect,
  useRef,
  useState,
} from 'react';
import useApi from '../../hooks/useApi';
import EntryGroupDocument from '../entry_groups/EntryGroupDocument';
import EntryGroupCheckboxes from '../entry_groups/EntryGroupCheckboxes';
import EntryGroupViolations from '../entry_groups/EntryGroupViolations';
import EntryGroupDateActivity from '../entry_groups/EntryGroupDateActivity';
import EntryGroupPinSummary from '../entry_groups/EntryGroupPinSummary';
import EntryGroupMap from '../entry_groups/EntryGroupMap';
import EntryGroupPins from '../entry_groups/EntryGroupPins';
import EntryGroupNotes from '../entry_groups/EntryGroupNotes';
import EntryGroupSignatures from '../entry_groups/EntryGroupSignatures';
import Loader from '../utility/Loader';
import useReportContext from '../reports/useReportContext';
import ReportPinsSummary from '../reports/ReportPinsSummary';
import ReportTreePinsSummary from '../reports/ReportTreePinsSummary';
import ReportWeather from '../reports/ReportWeather';
import { useParams } from 'react-router-dom';
import entryGroupsApi from '../../api/entry_groups';
import { BiCheckCircle } from 'react-icons/bi';
import EntryGroupFreeText from '../entry_groups/EntryGroupFreeText';
import usePageEntries from './hooks/usePageEntries';


function ReportPageEntryGroup({
  report,
  lastGroup,
  reportPageEntryGroup: { objectId, split_entry_group, entry_group },
}) {
  const EntryComponent = ENTRY_GROUP_COMPONENTS[entry_group.component_name];
  const componentSelfContained = SELF_CONTAINED_COMPONENTS.includes(entry_group.component_name);
  const { share_id } = useParams();
  const { editing } = useReportContext();

  const {
    entries,
    loading,
    loadEntries
  } = usePageEntries(objectId, {
    share_id,
    load: !componentSelfContained
  });

  const showHeader = shouldShowHeader(loading, entry_group, entries);

  if (componentSelfContained) return <EntryComponent
    key={`entry_group_${objectId}`}
    report={report}
    editing={editing}
    pageEntryGroupObjectId={objectId}
    entryGroup={entry_group}
    lastGroup={lastGroup}
    splitEntryGroup={split_entry_group}
    reloadEntries={loadEntries}
    entries={entries}
    maxPhotos={showHeader ? 6 : 3}
  />

  return (
    <EntryGroupContainer lastGroup={lastGroup} entryGroup={entry_group}>
      {showHeader && (
        <EntryGroupHeader
          entryGroup={entry_group}
          editing={editing}
          splitEntryGroup={split_entry_group}
        />
      )}
      {loading ? (
        <div className="py-5 flex justify-center items-center">
          <Loader color={'text-gray-700'} margin="" />
        </div>
      ) : (
        <EntryComponent
          key={`entry_group_${objectId}`}
          report={report}
          editing={editing}
          entryGroup={entry_group}
          reloadEntries={loadEntries}
          entries={entries}
          maxPhotos={showHeader ? 6 : 3}
        />
      )}
    </EntryGroupContainer>
  );
}

const ENTRY_GROUP_COMPONENTS = {
  EntryGroupDocument,
  EntryGroupCheckboxes,
  EntryGroupViolations,
  EntryGroupDateActivity,
  EntryGroupPinSummary,
  EntryGroupFreeText,
  EntryGroupMap,
  EntryGroupPins,
  EntryGroupNotes,
  EntryGroupFreeText,
  EntryGroupSignatures,
  ReportPinsSummary,
  ReportTreePinsSummary,
  ReportWeather,
};

const HEADER_BORDER_COMPONENTS = [
  'EntryGroupDocument',
  'EntryGroupCheckboxes',
  'EntryGroupViolations',
  'EntryGroupDateActivity',
  'ReportWeather',
]

const GROUP_BORDER_ROUNDED_COMPONENTS = [
  'EntryGroupPinSummary',
  'EntryGroupPins',
  'EntryGroupPinSummary',
  'EntryGroupNotes',
  'EntryGroupFreeText',
  'EntryGroupMap',
  'ReportTreePinsSummary',
  'EntryGroupSignatures',
]

const SELF_CONTAINED_COMPONENTS = [
  'EntryGroupNotes'
]

const shouldShowHeader = (loading, entry_group, entries) => {
  if (
    loading ||
    (entry_group.component_name === 'EntryGroupPins' &&
      entries.length &&
      entries[0].description !== '1')
  )
    return false;
  return true;
};

function EntryGroupContainer({ children, lastGroup, entryGroup }) {
  return <div
    className={`${lastGroup ? '' : 'mb-4'} ${GROUP_BORDER_ROUNDED_COMPONENTS.includes(entryGroup.component_name)
      ? 'border rounded-b-md'
      : ''
      }`}
  >
    {children}
  </div>
}

function EntryGroupLoader() {
  return <div className="py-5 flex justify-center items-center">
    <Loader color={'text-gray-700'} margin="" />
  </div>
}

function EntryGroupHeader({ children, entryGroup, editing, splitEntryGroup }) {
  const { report_id } = useParams();
  const {
    data: updatedEntryGroup,
    loading: saving,
    request: saveEntryGroup,
  } = useApi(entryGroupsApi.updateReportEntryGroup, null);

  const submitEntryGroup = (name) =>
    saveEntryGroup(report_id, entryGroup.objectId, { name });

  const [saved, setSaved] = useState(false);
  const updateTimer = useRef(null);
  const savedTimer = useRef(null);

  const updateEntryGroup = ({ target: { value } }) => {
    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitEntryGroup(value);
    }, 1000);
  };

  useEffect(() => {
    if (!updatedEntryGroup) return;
    setSaved(true);
  }, [updatedEntryGroup]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <div className={`px-4 py-2 bg-gray-100 text-sm flex items-center justify-between relative ${HEADER_BORDER_COMPONENTS.includes(entryGroup.component_name) ? 'border-l border-r border-t' : ''}`}>
      {saving && (
        <div className="absolute top-0 right-2 h-full flex items-center">
          <Loader color="text-gray-700" />
        </div>
      )}
      {saved && (
        <div className="absolute top-0 right-2 h-full flex items-center">
          <BiCheckCircle
            size={16}
            className="bg-green-500 text-white rounded-full overflow-hidden"
          />
        </div>
      )}
      {editing ? (
        <textarea
          disabled={!editing}
          style={{ opacity: 1, WebkitTextFillColor: '#000000' }}
          cols="1"
          onChange={updateEntryGroup}
          className={`flex-1 bg-transparent text-sm font-semibold text-black border border-transparent h-6 px-0 py-0 rounded-md focus:outline-none ${editing && 'hover:bg-blue-50'
            } focus:border-gray-200 focus:ring-0 resize-none`}
        >
          {entryGroup.name}
        </textarea>
      ) : (
        <div className="font-semibold">
          {entryGroup.name}{' '}
          {splitEntryGroup &&
            'EntryGroupPins' !== entryGroup.component_name && (
              <span className="italic font-normal">(continued)</span>
            )}
        </div>
      )}
      {entryGroup.helper_text && (
        <div className="text-xxs italic ml-3"> {entryGroup.helper_text}</div>
      )}
      {children}
    </div>
  );
}

export {
  EntryGroupContainer,
  EntryGroupLoader,
  EntryGroupHeader,
  shouldShowHeader,
}


export default ReportPageEntryGroup;
