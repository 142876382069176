import { useState } from 'react';
import Form from '../../utils/Form';
import useEntry from './hooks/useEntry';
import EntryLabel from './EntryLabel';
import { Entry } from '../../data/models';
export default function EntryDropdown({
    entry,
    editing = true,
    useBottomBorder = true,
}: {
    useBottomBorder?: boolean,
    entry: Entry,
    editing?: boolean,
}) {

    const multiple = entry.value_type === 'array';
    const { saved, saving, saveEntry, savedEntry } = useEntry(entry.objectId, { load: false });
    const formattedOptions = entry.value_options?.map((option: any, index: number) => ({
        id: index,
        name: option.label,
        value: option.value
    }));
    const entryValue = savedEntry?.value?.split?.(',') || entry.value?.split?.(',');
    const selectedOptions = formattedOptions?.filter((option: any) => entryValue?.includes?.(option.value));
    const [valueOptions, setValueOptions] = useState<any[] | null>(!!selectedOptions.length ? selectedOptions : []);

    const onSelect = (id: number) => {
        const newValueOption = formattedOptions[id];
        let newValueOptions = [newValueOption];
        if (multiple) {
            if (!valueOptions?.find((option) => option.id === id)) {
                newValueOptions = [...valueOptions || [], newValueOption];
            } else {
                newValueOptions = valueOptions.filter((option) => option.id !== id);
            }
        }
        setValueOptions(newValueOptions)
        const value = newValueOptions.map((option) => option.value).join(',');
        saveEntry({ value });
    }

    const selected = () => {
        if (multiple) {
            return valueOptions?.map?.((option) => option.id as number) || []
        }

        return valueOptions?.[0]?.id ?? null;
    }

    return <>
        <EntryLabel entry={entry} useBottomBorder={useBottomBorder} saved={saved} saving={saving} />
        {editing ? <div className={`${entry.class_name} border-b flex items-center`} >
            <Form.Dropdown
                size="xs"
                multiple={multiple}
                testId={`entryDropdown_${entry.name}`}
                fullWidth
                fullHeight
                title={valueOptions?.map?.((option) => option.name).join?.(', ') || undefined}
                options={formattedOptions}
                selected={selected()}
                onSelect={onSelect}
            /> </div> :
            <input
                type={entry.value_type === 'number' ? 'number' : 'text'}
                data-testid={`entryTextInput_${entry.name
                    .replace(' ', '_')
                    .toLowerCase()}`}
                disabled
                className={`text-xxs leading-4 border-gray-200 p-2 border-t-0 border-r-0 border-l-0 ${entry.class_name
                    } focus:border-gray-200 focus:outline-none focus:ring-0`}
                defaultValue={valueOptions?.map?.((option) => option.name).join?.(', ')}
            />}
        {/* Form.Select single selection experience is probably ideal for mobile */}
        {/* <Form.Select size="xs" value={valueOption?.value} onChange={() => console.log("onChange")}>
                {options.map((option) => {
                    return <option key={option.id} value={option.value}>{option.name}</option>
                })}
            </Form.Select> */}
    </>
}