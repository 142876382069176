import { useState, useEffect } from 'react';
import {
  FiAlertTriangle,
  FiCheckCircle,
  FiDownload,
  FiFolder,
} from 'react-icons/fi';
import qs from 'querystring';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import foldersApi from '../../api/folders';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {
  conditionalRowStyles,
  getFormattedStringForTableIcon,
  styles,
  videoMimeTypes,
} from '../documents/DocumentTable';
import moment from 'moment-timezone';
import DocumentName from '../documents/DocumentName';
import Breadcrumb from '../documents/Breadcrumb';
import PhotosModal from '../utility/PhotosModal';
import useQuery from '../../hooks/useQuery';
import useFormattedFolderData from '../../hooks/useFormattedFolderData';
import DownloadFolderButton from '../documents/DownloadFolderButton';
import documentsApi from '../../api/documents';

const DownloadStateIcon = ({ downloading, downloaded, error }) => {
  if (error) return <FiAlertTriangle className="ml-2 text-red-500" />;
  if (downloading) return <Loader color="black" className="mb-0" size={4} />;
  if (downloaded) return <FiCheckCircle className="ml-2 text-green-500" />;
  return <FiDownload className="ml-2" />;
};

const InlineDownloadDocumentOrFolder = ({ row, share_id }) => {
  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async (e) => {
    e.preventDefault();
    setDownloading(true);

    try {
      const { data: blob } =
        row.type === 'folder'
          ? await foldersApi.downloadFolder(row.objectId, {
            share_id,
          })
          : await documentsApi.downloadDocument(row.objectId, {
            share_id,
          });

      // Create a blob URL for the fetched content
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = blobUrl;
      a.download = row.type === 'folder' ? `${row.name}.zip` : row.name;
      a.click();
      setDownloading(false);
      setDownloaded(true);
    } catch (error) {
      setDownloading(false);
      setError(error);
    }
    return false;
  };

  return (
    <p className="flex hover:text-secondary" onClick={handleDownload}>
      {downloaded ? 'Downloaded' : 'Download'}{' '}
      <DownloadStateIcon
        downloaded={downloaded}
        downloading={downloading}
        error={error}
      />
    </p>
  );
};
export default function SharedFolder() {
  const {
    data: folder,
    loading,
    error,
    request: getFolder,
  } = useApi(foldersApi.getFolder, null, true);

  const { share_id, shareable_id } = useParams();

  const [previewDocument, setPreviewDocument] = useState(null);
  const [topFolder, setTopFolder] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState(shareable_id);
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { data } = useFormattedFolderData(folder);

  useEffect(() => {
    getFolder(shareable_id, { share_id });
  }, [shareable_id]);

  useEffect(() => {
    if (!topFolder) return;
    getFolder(selectedFolderId, { share_id });
  }, [shareable_id, selectedFolderId, topFolder]);

  useEffect(() => {
    if (!query.get('folder_id')) return;
    setSelectedFolderId(query.get('folder_id'));
  }, [query]);

  useEffect(() => {
    if (!folder || topFolder) return;
    setTopFolder(folder);
  }, [folder]);

  const columns = [
    {
      name: 'Name',
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.name,
        },
      ],
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          className="flex items-center cursor-pointer select-none"
          title={row.name}
        >
          <DocumentName
            name={
              row.type.includes('archived')
                ? `${row.name} [Archived]`
                : row.name
            }
            type={getFormattedStringForTableIcon({ type: row.type })}
          />
        </div>
      ),
      selector: (row) => row.name,
      sortable: true,
      sortField: 'name',
    },
    {
      name: 'Last updated',
      selector: (row) => moment(row.updated_at).format('MMM DD, yyyy'),
      sortable: true,
    },
    {
      name: '',
      selector: (row) => row.id,
      cell: (row) => (
        <InlineDownloadDocumentOrFolder row={row} share_id={share_id} />
      ),
      width: '150px',
      sortable: false,
    },
  ];

  function route(newFolderId) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        folder_id: newFolderId,
      }),
    });
  }

  const handleDownload = async (e, row) => {
    e.preventDefault();
    const { data: blob } =
      row.type === 'folder'
        ? await foldersApi.downloadFolder(row.objectId, {
          share_id,
        })
        : await documentsApi.downloadDocument(row.objectId, {
          share_id,
        });

    // Create a blob URL for the fetched content
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = blobUrl;
    a.download = row.type === 'folder' ? `${row.name}.zip` : row.name;
    a.click();
    return false;
  };

  const handleDoubleClick = (row, evt) => {
    evt ?? evt.preventDefault();
    if (row.type === 'folder' || row.type === 'archived_folder') {
      route(row.objectId);
    } else {
      setPreviewDocument(row);
    }
  };

  if (loading)
    return (
      <div className="flex justify-center py-10">
        <Loader color="black" />
      </div>
    );

  if (error.text?.() === "Couldn't find Folder")
    return (
      <Redirect
        to={{
          pathname: `/`,
          state: { error: error.text() },
        }}
      />
    );

  return (
    <div className="flex overflow-auto flex-col h-full text-left p-10 relative">
      <div className="flex items-center justify-between">
        <FiFolder size={40} />
        <DownloadFolderButton folder={folder} share_id={share_id} />
      </div>
      <Breadcrumb
        ancestors={
          selectedFolderId !== shareable_id
            ? folder?.ancestors?.filter(
              ({ name }) =>
                !topFolder?.ancestors?.map(({ name }) => name).includes(name)
            )
            : []
        }
        current={folder?.name}
      />
      <DataTable
        className="!overflow-visible"
        columns={columns}
        data={data}
        defaultSortFieldId={1}
        keyField={'objectId'}
        styles={styles}
        conditionalRowStyles={conditionalRowStyles}
        onRowDoubleClicked={handleDoubleClick}
        pagination
        pointerOnHover
        highlightOnHover
      />
      <PhotosModal
        isOpen={!!previewDocument}
        assets={[previewDocument?.asset]}
        isPDF={previewDocument?.type === 'pdf'}
        isVideo={videoMimeTypes.includes(previewDocument?.type)}
        activeIndex={0}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setPreviewDocument(null)}
        defaultFullScreen={true}
        allowDelete={false}
      />
    </div>
  );
}
