import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import XLSX from 'xlsx';
import * as windows1252 from '../../vendor/windows-1252';
import pinsApi from '../../api/pins';
import { Conversion, ConversionParameter, ConversionUnit, Datum, Pin, Project } from '../../data/models';
import useApi from '../../hooks/useApi';
import useModal from '../../hooks/useModal';
import colors from '../../styles/colors';
import { downloadBlobAsFile } from '../../utility/downloadButtonUtils';
import { availableProjections } from '../../utility/pinConfig';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../utility/Modal';
// proj4 doesn't have type signatures
// @ts-ignore
import proj4 from 'proj4';

// This import path is janky-- I think we might need to adjust some options in
// the module resolution config for the package. But for now, this is fine.
// (Ideally this would read `import csvStringify from 'csv-stringify/sync'`)
// @ts-ignore
import { stringify as csvStringify } from 'csv-stringify/lib/sync';
import useFeature from '../feature_flags/hooks/useFeature';
import DatumSearch from '../datums/DatumSearch';
import useProjectDatums from '../datums/hooks/useProjectDatums';
import { SearchHeader, SearchLoader } from '../header/ProjectSearch';
import Form from '../../utils/Form';
import { Flex } from '../../utils/Layout';
import useDatums, { datumName } from '../datums/hooks/useDatums';
import useCachedDatums from '../datums/hooks/useCachedDatums';
import { SearchEmptyContainer } from '../utility/DelayedSearchInput';
import Button from '../shared/Button';
import DatumPreviewModal from '../datums/DatumPreviewModal';

// PNEZD pins are returned from the backend iff the `for_export` request param
// is true.
//
// PNEZD is a format that AutoCAD (and probably similar programs) can import. It
// stands for Point, Northing, Easting, Z (elevation), Description.
//
// I would like to add this to the data/models package once we've combined
// mobile-app and web-admin into a single repo.
type PinPNEZD = Pin & {
  // TODO: figure out what the concrete types of these fields are.
  point: any;
  elevation: any;
  description: unknown;
  field_summary: unknown;
  comments: unknown;
};

type PinPNEZDT = Pin & {
  // TODO: figure out what the concrete types of these fields are.
  point: any;
  elevation: any;
  description: unknown;
  field_summary: unknown;
  comments: unknown;
  tag_string: unknown;
};

export type PinExportedFields = {
  Point: any;
  Northing: number;
  Easting: number;
  Elevation: any;
  Description: string;
  'Field Summary': string;
  Comments: string;
};

function PinExportButtons({
  loadingPins,
  searchParams,
  project,
  containerClass = 'absolute m-4 rounded-md overflow-hidden right-5',
  buttonClass = 'text-sm cursor-pointer justify-between rounded-md bg-white hover:opacity-90 py-2 px-3 text-secondary font-semibold items-center flex',
  buttonText = 'Export Pins',
}: {
  loadingPins?: boolean;
  searchParams: any;
  project: Project | null;
  containerClass?: string;
  buttonClass?: string;
  buttonText?: string;
}) {
  const { report_id, workspace_id, project_id } = useParams<{ workspace_id: string, project_id: string, report_id: string }>();
  const { enabled = false } = useFeature('datum_search');

  const {
    data: { records: pinsRaw },
    loading,
    request: getPins,
  } = useApi(pinsApi.getPins, { records: [], pagy: {} }, true);

  const [datumSearchParams, setDatumSearchParams] = useState({
    q: '',
    page: 1,
    items: 10,
  });

  const { datums: cachedDatums, storeDatum } = useCachedDatums(`workspace-${workspace_id}-project-${project_id}`);
  const { datums: projectDatums } = useProjectDatums(project, {
    enabled
  });
  const { datums, loading: loadingDatums } = useDatums(datumSearchParams, {
    enabled
  })

  const pins = pinsRaw as PinPNEZDT[];
  const [chosenDatum, setChosenDatum] = useState<Datum | null>(null);
  const [previewedDatum, setPreviewedDatum] = useState<Datum | null>(null);
  const [applyConversion, setApplyConversion] = useState(true);
  const [chosenProjection, setChosenProjection] = useState(
    'South Carolina: NAD83 International Foot (EPSG:2273 | NAD83)'
  );
  const { open, toggle } = useModal();

  useEffect(() => {
    if (!report_id) return;
    getPins('report', report_id, {
      items: 10000,
      full: true,
      page: 1,
      for_export: true,
    });
  }, []);

  useEffect(() => {
    if (!project || !open || report_id) return;
    getPins('project', project.objectId, {
      ...searchParams,
      items: 10000,
      full: true,
      page: 1,
      for_export: true,
    });
  }, [searchParams, open]);

  useEffect(() => {
    if (!enabled || !cachedDatums.length) return;
    setChosenDatum(cachedDatums[0]);
  }, [enabled, cachedDatums])


  const filename = project
    ? `${project.identifier.replace(' ', '_')}_${project.name.replace(
      ' ',
      '_'
    )}_${moment().unix()}_pins`
    : // I guess if project is null we can fall back to just the current
    // timestamp, but I don't expect this to ever happen.
    `${moment().unix()}_pins`;

  const formattedPins = enabled ? formatPinsForExportFromDatum(pins, chosenDatum, applyConversion) : formatPinsForExport(pins, chosenProjection);
  const usingProjectDatums = !!datumSearchParams.q ? false : true;
  const activeDropdownOptions = !!datumSearchParams.q ? datums : projectDatums;

  const onSelect = (selectedId: number) => {
    const foundDatum = activeDropdownOptions.find(({ id }) => id === selectedId);
    if (foundDatum) storeDatum(foundDatum);
    setChosenDatum(foundDatum || null)
  }

  return (
    <>
      <div className={containerClass} onClick={toggle} data-testid="exportPinsButton">
        <div
          title={
            pins.length === 0
              ? 'No pins available to export'
              : 'Export pins in P, N, E, Z, D format'
          }
          className={`${loadingPins && 'opacity-50 cusor-not-allowed'
            } ${buttonClass}`}
        >
          <FaFileCsv size={16} color={colors.secondary} className="mr-2" />{' '}
          {buttonText}
        </div>
      </div>
      <Modal isOpen={open} onClose={toggle} modalClass="overflow-visible">
        <ModalHeader title={'Available Datums'} onClose={toggle} />
        <ModalBody>
          {!enabled && availableProjections.map(({ name }) => (
            <div
              className={`bg-white font-medium hover:opacity-80 cursor-pointer flex items-center justify-between p-3 border border-gray-100 ${name === chosenProjection
                ? 'border-blue-100 text-blue-900 bg-blue-50'
                : ''
                }`}
              onClick={() => setChosenProjection(name)}
            >
              {name}
            </div>
          ))}
          {enabled && <Form.Group overflow='visible'>
            <Form.Label justify='between'>
              Datums
              <Flex>
                <Form.SwitchLabel mr={2}>
                  Apply Conversion
                </Form.SwitchLabel>
                <Form.Switch
                  size='sm'
                  disabled={!chosenDatum}
                  data-testid="switchUseConversion"
                  checked={applyConversion}
                  htmlFor='switchUseConversion'
                  onClick={() => setApplyConversion(!applyConversion)}
                />
              </Flex>
            </Form.Label>
            <Form.Dropdown
              checkAlignment='right'
              fullWidth
              title={chosenDatum ? datumName(chosenDatum) : 'Select Datum'}
              options={activeDropdownOptions.map((datum) => ({ id: datum.id, name: datumName(datum) }))}
              onSelect={onSelect}
              selected={chosenDatum?.id || -1}
              element={(props) => <Form.DropdownOptionItem {...props}>
                {/* <Button text='Info' color='light' size='xs' className='ml-2' onClick={(e) => {
                  setPreviewedDatum(activeDropdownOptions.find(({ id }) => id === props.option.id) || null);
                  e.preventDefault();
                  e.stopPropagation();
                  return false;
                }} /> */}
              </Form.DropdownOptionItem>}
            >
              <div className="sticky top-0">
                <DatumSearch onSearch={(q: string) => setDatumSearchParams((sp) => ({ ...sp, q }))} />
                {usingProjectDatums && <SearchHeader>
                  Nearby Datums
                </SearchHeader>}
              </div>
              {loadingDatums && <SearchLoader />}
              {!loadingDatums && !usingProjectDatums && !datums.length && <SearchEmptyContainer>No datums found searching `{datumSearchParams.q}`</SearchEmptyContainer>}
            </Form.Dropdown>
          </Form.Group>
          }
          {enabled && <div className="hidden bg-white rounded-lg shadow">
            <SearchHeader>
              Nearby Datums
            </SearchHeader>
            <div className="max-h-52 overflow-y-scroll" >
              {projectDatums.map((datum) => (
                <div
                  className={`bg-white font-medium hover:opacity-80 cursor-pointer flex items-center justify-between p-3 border border-gray-100 ${datum.name === chosenDatum?.name
                    ? 'border-blue-100 text-blue-900 bg-blue-50'
                    : ''
                    }`}
                  onClick={() => setChosenDatum(datum)}
                >
                  {datum.name} ({datum.data.json.conversion?.name})
                </div>
              ))}
            </div>
          </div>}
          <div className="flex flex-row w-full justify-center items-center pt-4 px-10">
            {loadingPins || loading ? (
              <Loader color="black" />
            ) : (
              <div className={`${!enabled && chosenProjection || enabled && chosenDatum ? '' : 'opacity-60 pointer-events-none'} flex space-x-2`}>
                <DownloadAsXlsxButton
                  pins={formattedPins}
                  filename={filename}
                  afterDownload={toggle}
                />
                <CsvDownloadButton
                  pins={formattedPins}
                  filename={filename}
                  afterDownload={toggle}
                />
              </div>
            )}
          </div>
          <p className="text-grey-700 mt-5 py-2 text-center text-xs text-gray-900 px-2 mx-4 rounded-lg">
            Exporting too many pins?{' '}
            <span className="font-semibold">
              Make sure you've filtered only the pins you want to export.
            </span>
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="modal-close-btn" type="button" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal >
      <DatumPreviewModal datum={previewedDatum} onClose={() => setPreviewedDatum(null)} />
    </>
  );
}

const CsvDownloadButton = (props: {
  pins: PinExportedFields[];
  filename: string;
  afterDownload?: () => void;
}) => (
  <button
    className="w-1/2 h-full flex flex-row items-center border border-gray-200 rounded-md bg-tertiary-light hover:opacity-90 py-2 px-5 text-white font-semibold"
    type="button"
    data-testid="exportPinsAsCsvButton"
    onClick={() => {
      // It's not clear if the XLSX library Windows-1252 encoding in its CSV
      // routines. If it does, then we could theoretically replace this code
      // with a call to this library function:
      // XLSX.utils.sheet_to_csv(pinsToXlsxWorkbook(props.pins).Sheets.Pins);

      const blob = pinsToAutoCadCompatibleCsv(props.pins);
      downloadBlobAsFile(`${props.filename}.csv`, blob);

      props.afterDownload?.();
    }}
  >
    <FaFileCsv size={30} color={colors.white} className="mr-2" />
    <div className="flex-col">
      <p>Export for AutoCAD</p>
      <small>(Windows-1252 PNEZD CSV)</small>
    </div>
  </button>
);

const pinsToAutoCadCompatibleCsv = (pins: PinExportedFields[]): Blob => {
  // This is a Javascript UTF-16 string.
  const csvString: string = csvStringify(pins, {
    // AutoCAD cannot handle a header row.
    header: false,

    // We must disable this because otherwise negative lat/longs etc. will be
    // prefixed with a single-quote, which I suspect will cause AutoCAD to
    // fail to import.
    escape_formulas: false,
  });

  // We need to re-encode the string as a Windows-1252 "string" to provide the
  // format that AutoCAD expects.
  const windows1252EncodedUint16Array: Uint16Array = windows1252.encode(
    csvString,
    // Handle non-ASCII characters as gracefully as possible under the
    // circumstances.
    { mode: 'replacement' }
  );

  // Reduce the Uint16Array to a Uint8Array.
  // This is a bit janky but we've tested and confirmed that doing it this way
  // fixes the AutoCAD import.
  const windows1252EncodedUint8Array: Uint8Array = new Uint8Array(
    windows1252EncodedUint16Array.length
  );
  windows1252EncodedUint16Array.forEach((value, index) => {
    windows1252EncodedUint8Array[index] = value;
  });

  return new Blob([windows1252EncodedUint8Array], { type: 'text/csv' });
};

const DownloadAsXlsxButton = (props: {
  pins: PinExportedFields[];
  disabled?: boolean;
  filename: string;
  afterDownload?: () => void;
}) => (
  <button
    className="w-1/2 h-full flex flex-row items-center border border-gray-200 rounded-md bg-tertiary-light hover:opacity-90 py-2 px-5 text-white font-semibold"
    disabled={props.disabled}
    data-testid="exportPinsAsExcelButton"
    onClick={() => {
      const wb = pinsToXlsxWorkbook(props.pins);
      const blob = workbookToBlob(wb);
      downloadBlobAsFile(`${props.filename}.xlsx`, blob);
      props.afterDownload?.();
    }}
  >
    <FaFileExcel size={30} color={colors.white} className="mr-2" />
    Export as Excel Workbook
  </button>
);

const pinsToXlsxWorkbook = (pins: PinExportedFields[]): XLSX.WorkBook => {
  const ws = XLSX.utils.json_to_sheet(pins, {
    skipHeader: false,
    header: [
      'Point',
      'Northing',
      'Easting',
      'Elevation',
      'Description',
      'Field Summary',
      'Comments',
      'Tags',
    ],
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Pins');
  return wb;
};

const stringToArrayBuffer = (str: string): ArrayBuffer => {
  const buffer = new ArrayBuffer(str.length);
  const bufferView = new Uint8Array(buffer);
  for (let i = 0; i < str.length; i++) {
    bufferView[i] = str.charCodeAt(i);
  }
  return buffer;
};

const workbookToBlob = (wb: XLSX.WorkBook): Blob => {
  // This technically is a Javascript string, although it is (very likely) not
  // valid UTF-16 and should be treated as binary data.
  //
  // The XLSX library we're using is a bit unconvential in its encoding patterns
  // (see the write() function in xlsx.js source code).
  const workbookBinary: string = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'binary',
  });

  return new Blob([stringToArrayBuffer(workbookBinary)], {
    type: 'application/octet-stream',
  });
};

const formatPinsForExport = (
  pins: PinPNEZDT[],
  chosenProjection: string
): PinExportedFields[] => {
  const projectionConfig = availableProjections.find(
    ({ name }) => name === chosenProjection
  )?.proj4;

  if (!projectionConfig) {
    // This can only happen if there's a bug.
    // (Technically, this could also happen if the user edits the Javascript, or
    // if some bits in RAM flip due to cosmic rays, etc.)
    throw new Error(`Could not find projection config for ${chosenProjection}`);
  }

  return pins.map((pin: PinPNEZDT) => {
    let { point, elevation, description, field_summary, comments, tag_string } = pin;
    let [easting, northing] = proj4(projectionConfig, [
      pin.coordinate.lng,
      pin.coordinate.lat,
    ]);
    return {
      Point: point,
      Northing: northing,
      Easting: easting,
      Elevation: elevation,

      // NOTE: these used to be wrapped in double quotes. I'm not sure what
      // the purpose of that behavior was, but to make it easier for people
      // to manipulate these fields themselves later in Excel or wherever,
      // I'm just coercing these fields to strings.
      Description: `${description}`,
      'Field Summary': `${field_summary}`,
      Comments: `${comments}`,
      Tags: `${tag_string}`,
    };
  });
};

function convertToProj4(conversion: Conversion, command: string) {
  // Helper function to convert units if necessary
  function convertUnit(value: number, unit: ConversionUnit) {
    if (typeof unit === "string") return value;
    if (unit.type === "LinearUnit" && unit.conversion_factor) {
      return value * unit.conversion_factor;
    }
    return value;
  }

  // Extract parameters
  const params = conversion.parameters.reduce((acc: any, param: ConversionParameter) => {
    switch (param.name) {
      case "Latitude of false origin":
        acc.lat_0 = param.value;
        break;
      case "Longitude of false origin":
        acc.lon_0 = param.value;
        break;
      case "Latitude of 1st standard parallel":
        acc.lat_1 = param.value;
        break;
      case "Latitude of 2nd standard parallel":
        acc.lat_2 = param.value;
        break;
      case "Easting at false origin":
        acc.x_0 = convertUnit(param.value, param.unit);
        break;
      case "Northing at false origin":
        acc.y_0 = convertUnit(param.value, param.unit);
        break;
    }
    return acc;
  }, {});

  // Function to replace or add parameters in the existing Proj4 string
  function updateProj4String(proj4String: string, params: object) {
    const regexMap: { [key: string]: any } = {
      lat_0: /\+lat_0=[^ ]+/,
      lon_0: /\+lon_0=[^ ]+/,
      lat_1: /\+lat_1=[^ ]+/,
      lat_2: /\+lat_2=[^ ]+/,
      x_0: /\+x_0=[^ ]+/,
      y_0: /\+y_0=[^ ]+/
    };

    let updatedString = proj4String;

    for (const [key, value] of Object.entries(params)) {
      const regex = regexMap[key];
      if (regex.test(updatedString)) {
        updatedString = updatedString.replace(regex, `+${key}=${value}`);
      } else {
        updatedString += ` +${key}=${value}`;
      }
    }

    return updatedString;
  }

  // Update the existing Proj4 string with new parameters
  const updatedProj4String = updateProj4String(command, params);

  return updatedProj4String;
}

const formatPinsForExportFromDatum = (
  pins: PinPNEZD[],
  datum: Datum | null,
  applyConversion: boolean
): PinExportedFields[] => {
  if (!datum) return [];

  //We should handle this transformation on the backend
  //It seems client-side proj4 expects + in front of the command and each flag
  const finalProjection = datum.data.json.conversion && applyConversion ? convertToProj4(datum.data.json.conversion, datum.proj4) : `${datum.proj4}`;

  return pins.map((pin: PinPNEZD) => {
    let { point, elevation, description, field_summary, comments } = pin;
    let [easting, northing] = proj4(finalProjection, [
      pin.coordinate.lng,
      pin.coordinate.lat,
    ]);
    return {
      Point: point,
      Northing: northing,
      Easting: easting,
      Elevation: elevation,

      // NOTE: these used to be wrapped in double quotes. I'm not sure what
      // the purpose of that behavior was, but to make it easier for people
      // to manipulate these fields themselves later in Excel or wherever,
      // I'm just coercing these fields to strings.
      Description: `${description}`,
      'Field Summary': `${field_summary}`,
      Comments: `${comments}`,
    };
  });
};

export default PinExportButtons;
