import { memo, useEffect, useRef, useState } from 'react';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import { BiCheckCircle } from 'react-icons/bi';
import { FaMapMarker } from 'react-icons/fa';
import Loader from '../utility/Loader';
import { useParams } from 'react-router-dom';
import useFeatures from '../../hooks/useFeatures';
import useReportContext from '../reports/useReportContext';

function EntryPinSummary({ entry, editing, report }) {
  const {
    data: currentNote,
    loading,
    request: getNote,
  } = useApi(notesApi.getNote, null);
  const {
    data: savedNote,
    error,
    loading: saving,
    request: updatePinNote,
  } = useApi(notesApi.updateNote, null);
  const [note, setNote] = useState({});
  const [saved, setSaved] = useState(false);
  const { share_id } = useParams();
  const editableRef = useRef(null);
  const updateTimer = useRef(null);
  const savedTimer = useRef(null);

  const { features: { rollout: { report_entry_variable_height } } } = useFeatures();


  const { pinUpdated, setPinUpdated, deletedPins, softDeletedPins } =
    useReportContext();

  let { value, info } = entry;

  const isDeleted = () =>
    [...deletedPins, ...softDeletedPins].includes(info?.pin_objectId);

  const updateNote = ({ target: { innerText: text } }) => {
    setNote({ ...note, text });

    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitNote(text);
    }, 1000);
  };

  const submitNote = (text) =>
    updatePinNote(info.note_objectId, {
      ...note,
      text,
    });

  useEffect(() => {
    if (pinUpdated !== info?.pin_objectId || saved) return;
    getNote(note.objectId);
  }, [pinUpdated]);

  useEffect(() => {
    if (!info?.note_objectId) return;
    getNote(info.note_objectId, { share_id });
  }, [info?.note_objectId]);

  useEffect(() => {
    if (!currentNote) return;
    setNote({ ...currentNote });
  }, [currentNote]);

  useEffect(() => {
    if (!savedNote) return;
    setNote({ ...savedNote });
  }, [savedNote]);

  useEffect(() => {
    if (!savedNote) return;
    setSaved(true);
  }, [savedNote]);

  useEffect(() => {
    if (!saved) return;
    setPinUpdated(info?.pin_objectId);
    savedTimer.current = setTimeout(() => {
      setSaved(false);
      setPinUpdated(false);
    }, 2000);
  }, [saved]);

  useEffect(() => {
    if (editableRef.current && note?.text && !editableRef.current.innerText)
      editableRef.current.textContent = note?.text;
  }, [editableRef.current, note]);

  if (isDeleted()) return <span />;

  const entryClassName = report_entry_variable_height && entry?.styles?.variable_height ? 'flex flex-row items-center text-xs relative border border-gray-200 bg-gray-50 rounded-lg px-2 py-2 mb-3' : 'flex text-xs mb-2 relative items-center';
  const entryBorderStyle = report_entry_variable_height && entry?.styles?.variable_height ? 'bg-gray-150 border-gray-300' : 'bg-gray-100 border-gray-200';
  const entryLeading = report_entry_variable_height && entry?.styles?.variable_height ? 'leading-snug' : 'leading-tight';

  return value ? (
    <div className={entryClassName}>
      {(loading || saving) && (
        <div className="absolute top-0 right-2 h-full flex items-center">
          <Loader color="text-gray-700" />
        </div>
      )}
      {saved && (
        <div className="absolute top-0 right-2 h-full flex items-center">
          <BiCheckCircle
            size={16}
            className="bg-green-500 text-white rounded-full overflow-hidden"
          />
        </div>
      )}
      {![null, undefined].includes(info?.index) && (
        <div className="font-semibold text-center w-8 relative">
          <FaMapMarker
            style={{
              color:
                report.metadata?.map_state?.pin_style?.color === 'inherit'
                  ? info.color
                  : 'orange',
            }}
            fontSize={30}
          />
          <span className="text-white font-bold absolute top-0.5 left-0 text-center w-full">
            {info.index + 1}
          </span>
        </div>
      )}
      <div className={`font-semibold text-center border ${entryBorderStyle} w-14 mx-3 rounded-md`}>
        {value}
      </div>
      <p
        ref={editableRef}
        disabled={loading || saving || !editing}
        contentEditable={editing}
        onInput={updateNote}
        className={`flex whitespace-pre-line items-center align-middle ${entryLeading} p-0 w-full border-0 text-xs focus:border-gray-200 focus:outline-none focus:ring-0 ${editing && 'hover:bg-blue-50'
          }`}
      />
    </div>
  ) : (
    <p className="text-gray-700 text-xs">N/A</p>
  );
}

export default memo(EntryPinSummary);
