import React from 'react';
import { MB_CLASSES, MB_VALUE } from './Form';
export default function Tabs({ children, mb = 8 }: { children: React.ReactNode, mb?: MB_VALUE }) {
    return <div className={`relative ${MB_CLASSES[mb]}`}>
        <div className="absolute bottom-0 w-full h-px bg-gray-200" aria-hidden="true"></div>
        <ul className="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-scroll no-scrollbar">
            {children}
        </ul>
    </div>
}

const Tab = ({ active, children, onClick, testId }: {
    active?: boolean,
    children: React.ReactNode,
    onClick?: (e: React.MouseEvent) => void
    testId?: string
}) => <li className="mr-6 last:mr-0 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8" onClick={onClick} data-testid={testId}>
        <a className={`focus:ring-0 focus:outline-none block pb-2 ${active ? 'text-secondary border-b-2 border-secondary' : 'text-gray-500'} whitespace-nowrap`} href="#0">{children}</a>
    </li>

export {
    Tab
}