import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import pinsApi from '../../api/pins';
import useApi from '../../hooks/useApi';
import { uploadAttachment } from '../../hooks/useS3';
import Loader from '../utility/Loader';
import Modal from '../utility/Modal';
import ImageInput from './pin_form_fields/ImageInput';
import Textarea from '../shared/Textarea';
import Header from './modal/Header';
import Title from './modal/Title';
import Container from './modal/Container';
import Footer from './modal/Footer';
import Button from './modal/Button';
import usePinFields from './hooks/usePinFields';

export default function AddWorkLogModal({
    pin,
    onClose
}) {

    const [newPin, setNewPin] = useState({
        objectId: pin.objectId,
        notes_attributes: [{ text: '' }]
    });

    const [noteImages, setNoteImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [uploadingImages, setUploadingImages] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const { request: updatePin, data: updatedPin, loading: saving } = useApi(pinsApi.updatePin, null);

    const { fieldComponents, status, isPrivate } = usePinFields({ pin });

    const submitNewPin = async () => {
        try {
            //TODO: Migrate this in PinDropService or similar service
            setUploadingImages(true);
            if (status) {
                newPin.active_status = status;
                newPin.status = status?.value;
            }
            newPin.private = isPrivate;
            if (newPin?.notes_attributes?.length > 0) {
                newPin.notes_attributes[0].assets_attributes = [];
                for (let i = 0; i < noteImages.length; i++) {
                    const signature = await uploadAttachment(noteImages[i]);
                    newPin.notes_attributes[0].assets_attributes.push({
                        file: signature,
                        name: noteImages[i].name,
                    });
                }
            }
            updatePin(pin.objectId, { ...newPin });
        } finally {
            setUploadingImages(false);
        }
    };

    useEffect(() => {
        const checkFormComplete = () => {
            if (!!newPin?.notes_attributes?.[0]?.text || noteImages.length > 0 || status?.value !== pin.status || isPrivate !== pin.private) {
                setFormComplete(true);
            } else {
                setFormComplete(false);
            }
        }
        checkFormComplete();
    }, [status, isPrivate, newPin, noteImages]);


    useLayoutEffect(() => {
        setOpen(true);
    }, []);

    useEffect(() => {
        if (!updatedPin) return;
        onClose(updatedPin);
    }, [updatedPin])

    return (
        <Modal
            isOpen={open}
            maxWidth={'max-w-md'}
            maxHeight={'max-h-[80vh]'}
            modalScrolls={false}
            dialogScrolls={true}
            aligned={'items-center'}
            onClose={() => onClose(false)}
        >
            <Header title="Add Worklog" onClose={() => onClose(false)} />
            <Container>
                {fieldComponents}
                <Title>Notes</Title>
                <Textarea data-testid="worklogNoteInput" placeholder="Type a note here..." onChange={({ target: { value } }) => {
                    setNewPin({ ...newPin, notes_attributes: [{ text: value }] });
                }} />

                <div className="mb-8">
                    <Title> Photos </Title>
                    <div className="mb-4">
                        <ImageInput onImagesAdded={setNoteImages} />
                    </div>
                </div>
            </Container>
            <Footer>
                <Button
                    style="save"
                    type="button"
                    data-testid="saveWorklog"
                    disabled={!formComplete || saving || uploadingImages}
                    onClick={submitNewPin}
                >
                    Save Worklog
                    {(saving || uploadingImages) && <Loader />}
                </Button>
                <Button
                    style="close"
                    type="button"
                    data-testId="closeWorklog"
                    onClick={() => onClose(false)}
                    disabled={(saving || uploadingImages)}
                >
                    Cancel
                </Button>
            </Footer>
        </Modal>
    );
}
