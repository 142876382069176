import { memo, useEffect, useState } from 'react';
import entryGroupsApi from '../../api/entry_groups';
import reportsApi from '../../api/reports';
import MapBoxMap from '../maps/mapbox/MapBoxMap';
import {
  EntryMapEditState,
  buildMapLayerAttributes,
} from '../entries/EntryPin';
import MapContainer from '../maps/MapContainer';
import usePins from '../pins/usePins';
import useFeatures from '../../hooks/useFeatures';
import useReportContext from '../reports/useReportContext';

const mapContainerStyle = {
  width: '100%',
  height: '860px',
  position: 'relative',
};

function EntryGroupMap(props) {
  return (
    <MapContainer>
      <BaseEntryGroupMap {...props} />
    </MapContainer>
  );
}

function BaseEntryGroupMap({
  report: passedReport,
  entryGroup: passedEntryGroup,
}) {
  const { editing, deletedPins } = useReportContext();
  const { features: { rollout: { report_map_pin_filter = false } = {} } = {} } =
    useFeatures();
  const [report, setReport] = useState(passedReport);
  const [entryGroup, setEntryGroup] = useState(passedEntryGroup);
  const [mapLocked, setMapLocked] = useState(true);
  const [updatedBounds, setUpdatedBounds] = useState();
  const [updatedMapLayers, setUpdatedMapLayers] = useState();
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    pins,
    getPins,
    setLoadPins,
    setPinFilter,
    setSelectedPin,
    pinFilter,
  } = usePins();

  const initialPinFilter = entryGroup.extras?.pin_filter || report.pin_filter;
  const updatedFilter =
    JSON.stringify(pinFilter) !== JSON.stringify(initialPinFilter);

  const submitReportUpdates = async () => {
    setSaving(true);

    if (updatedBounds || updatedFilter) {
      let { data } = await entryGroupsApi.updateReportEntryGroup(
        passedReport.objectId,
        entryGroup.objectId,
        {
          extras: { bounds: updatedBounds, pin_filter: pinFilter },
        }
      );
      setTimeout(() => setEntryGroup({ ...data }), 1000);
    }

    if (updatedMapLayers) {
      let { data } = await reportsApi.updateReport(passedReport.objectId, {
        report_map_layers_attributes: updatedMapLayers,
      });
      setReport({ ...data });
    }

    setSaving(false);
    setSaved(true);
  };

  useEffect(() => {
    const availablePinFilter = entryGroup.extras?.pin_filter;
    if (availablePinFilter) setPinFilter(availablePinFilter);
    setTimeout(() => setLoadPins(true), 1);
  }, []);

  useState(() => {
    setReport({ ...passedReport });
  }, [passedReport]);

  useEffect(() => {
    if (!deletedPins.length) return;
    getPins();
  }, [deletedPins]);

  const reportOnMapLayers = (mapLayers) => {
    if (!editing) return;

    const mappedAttributes = buildMapLayerAttributes({
      associationId: 'report_map_layer_id',
      existingMapLayers: report.map_layers,
      chosenMapLayers: mapLayers,
    });

    setUpdatedMapLayers(mappedAttributes);
    setMapLocked(false);
  };

  useEffect(() => {
    if (!saved) return;

    const clearEditState = () => {
      setMapLocked(true);
      setUpdatedBounds();
      setUpdatedMapLayers();
      setSaved(false);
    };

    setTimeout(clearEditState, 1500);
  }, [saved]);

  const mapUpdated = updatedBounds || updatedMapLayers || updatedFilter;

  return (
    <div style={mapContainerStyle}>
      <MapBoxMap
        onBoundsUpdated={setUpdatedBounds}
        defaultBounds={
          entryGroup.extras && entryGroup.extras.bounds
            ? entryGroup.extras.bounds
            : undefined
        }
        onSelectPin={(objectId) => {
          setSelectedPin(pins.find((pin) => pin.objectId === objectId));
        }}
        onLayersChosen={reportOnMapLayers}
        defaultLayers={report.map_layers}
        defaultLocked
        locked={mapLocked}
        pins={pins}
        fitToPins
        project={report.project}
        bearing={report.metadata?.map_state?.bearing}
        pitch={report.metadata?.map_state?.pitch}
        pinStyle={
          report.metadata?.map_state?.pin_style || {
            icon: 'pin',
            color: 'orange',
            showCount: true,
          }
        }
        allowMapLayerSelection
        canAddLayers={false}
        canEditLayers={false}
        showNavigationControls={false}
        mapTypeToggle={false}
        testId={'entryGroupMap'}
      >
        {editing && (
          <EntryMapEditState
            allowFiltering={report_map_pin_filter}
            editing={!mapLocked}
            onToggle={() => {
              setUpdatedBounds();
              setUpdatedMapLayers();
              if (mapUpdated) getPins();
              setMapLocked(!mapLocked);
            }}
            onSave={submitReportUpdates}
            saving={saving}
            saved={saved}
            updated={mapUpdated}
          />
        )}
      </MapBoxMap>
    </div>
  );
}

export default memo(EntryGroupMap);
