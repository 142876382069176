import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import projectsApi from '../../api/projects';
const ProjectContext = React.createContext();
export default ProjectContext;
function ProjectContextProvider({ children }) {
  const {
    data,
    error,
    loading,
    request: getProject,
  } = useApi(projectsApi.getProject, null);
  const [project, setProject] = useState(data);

  const { project_id } = useParams();

  const loadProject = () => getProject(project_id);

  useEffect(() => {
    if (!project_id) return;
    loadProject();
  }, [project_id]);

  useEffect(() => {
    if (!data) return;
    setProject(data);
  }, [data]);

  const value = {
    project,
    setProject,
    loadProject,
    error,
    loading,
  };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

function ProjectContainer({ children, ...rest }) {
  return <ProjectContextProvider {...rest}>{children}</ProjectContextProvider>;
}

export { ProjectContext, ProjectContextProvider, ProjectContainer };
