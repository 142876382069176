import { useEffect } from 'react';
import Card from '../../utils/Card';
import { FaClipboard, FaMap } from 'react-icons/fa';
import { List } from '../../utils/Layout';
import { H3 } from '../../utils/Typography';
import Button from '../shared/Button';
import { ReportType } from '../../data/models';
import { FiArchive, FiMapPin } from 'react-icons/fi';
import { ConfirmationModal } from '../utility/Modal';
import useModal from '../../hooks/useModal';
import useReportType from './hooks/useReportType';
export default function ReportTypeCard({ reportType, afterArchived, onEdit }: {
    reportType: ReportType;
    onEdit?: (reportType: ReportType) => void;
    afterArchived?: (reportType: ReportType) => void;
}) {
    const { open, setOpen } = useModal();
    const {
        unarchiveReportType,
        archiving,
        archived,
        unarchived,
        archiveReportType,
    } = useReportType(reportType.id, { load: false });

    useEffect(() => {
        if (!archived && !unarchived) return;
        setOpen(false);
        afterArchived?.(reportType);
    }, [archived, unarchived]);

    return <>
        <Card.Card >
            <Card.Content>
                <Card.Body>
                    <Card.Header>
                        <H3 mr="mr-3">{reportType.name}</H3>
                    </Card.Header>
                    <List.List>
                        {reportType.has_map_layer && <List.Item>
                            <FaMap size={16} className="mr-2" /> <div>Includes map view</div>
                        </List.Item>}
                        {reportType.includes_pins && <List.Item>
                            <FiMapPin size={16} className="mr-2" /> <div>Includes pins</div>
                        </List.Item>}
                        {reportType.includes_site_notes && <List.Item>
                            <FaClipboard size={16} className="mr-2" /> <div>Includes site note</div>
                        </List.Item>}
                    </List.List>
                </Card.Body>
                <Card.Footer>
                    {afterArchived && !reportType.archived_at && <Button
                        text="Archive"
                        onClick={() => setOpen(true)}
                        color='light'
                        iconAligned='left'
                        icon={<FiArchive className='mr-1' />}
                        className="flex-1 mr-1"
                        data-testid={`archiveReportType${reportType.name}`}
                    />}
                    {reportType.archived_at && <Button
                        text="Activate"
                        onClick={unarchiveReportType}
                        color='tertiary'
                        iconAligned='left'
                        className="flex-1 mr-1"
                        data-testid={`activateReportType${reportType.name}`}
                    />}
                    {onEdit && <Button
                        text="Edit"
                        onClick={() => onEdit(reportType)}
                        color='secondary'
                        className="flex-1"
                        data-testid={`editReportType${reportType.name}`}
                    />}
                </Card.Footer>
            </Card.Content>
        </Card.Card>
        <ConfirmationModal
            buttonTestId={`buttonConfirmArchiveReportType${reportType.name}`}
            confirming={archiving}
            confirmed={archived}
            isOpen={open}
            onClose={() => setOpen(false)}
            title="Are you sure?"
            onConfirm={archiveReportType}
            body={
                <>
                    <p className="mb-1">This will archive the {reportType.name} and <span className="font-medium">remove it as an available type on all new reports.</span></p>
                    <p className="font-medium text-tertiary text-sm mb-1">Currently used on {reportType.reports_count} reports</p>
                    <p className="italic text-secondary text-xs">Reports usign this type will remain unaffected.</p>
                </>} />
    </>
}