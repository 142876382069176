import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Segment from 'react-segment-analytics';
import App from './App';
import { AuthContextProvider } from './auth/context';
import { environment } from './config';
import * as serviceWorker from './serviceWorker';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { FeatureFlagProvider } from './config/feature_flags';
import { version } from '../package.json';
import { SidebarContextProvider } from './hooks/useSidebarCollapsed';

let checkSearchParams = new URLSearchParams(window.location.search);

if (
  environment.apiEnvironment !== 'development' &&
  !checkSearchParams.get('become')
) {
  Sentry.init({
    release: `sitemarker-admin@${version}`,
    dsn: environment.sentryDSN,
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id, title: 'Uh oh, It looks like something went wrong...', labelSubmit: "Submit Report" });
      }
      return event;
    },
    environment: environment.apiEnvironment,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration(), Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      autoInject: false
    })],
  });
}

FullStory.init({
  orgId: 'o-1B2709-na1',
  devMode: !!(
    environment.apiEnvironment === 'development' ||
    checkSearchParams.get('become')
  ),
});

ReactDOM.render(
  <React.StrictMode>
    <SidebarContextProvider>
      <AuthContextProvider>
        <FeatureFlagProvider>
          <Router>
            <Segment writeKey={environment.segmentWriteKey}>
              <App />
            </Segment>
          </Router>
        </FeatureFlagProvider>
      </AuthContextProvider>
    </SidebarContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
