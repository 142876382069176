import { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import notesApi from '../../api/notes';
import Loader from '../utility/Loader';
import { useParams } from 'react-router-dom';
import EditableProjectSiteNote from '../project/EditableProjectSiteNote';
import useReportContext from '../reports/useReportContext';

function EntryNote({ noteId, onEditButtonClicked }: {
  noteId: string,
  onEditButtonClicked?: (note: any) => void
}) {
  const { data: note, error, request: getNote } = useApi(notesApi.getNote, null, true);
  const { share_id } = useParams<{ share_id: string }>();
  const { editing } = useReportContext();

  useEffect(() => {
    getNote(noteId, { share_id });
  }, []);

  if (error) return <p className="px-2 pb-2">N/A</p>

  return note ? (<div className="mb-2">
    <EditableProjectSiteNote
      editable={editing}
      condensed={true}
      onEditButtonClicked={onEditButtonClicked ? () => onEditButtonClicked?.(note) : undefined}
      dataTestId='inputReportProjectSiteNote'
      key={`project_site_note_${note.objectId}`}
      note={note}
    />
  </div>) : (
    <div className="flex justify-center py-2">
      <Loader color="black" />
    </div>
  );
}

export default EntryNote;
