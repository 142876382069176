import React, { memo, useEffect, useRef, useState } from 'react';
import {
  NavLink,
  useLocation,
  useHistory,
  matchPath,
  Redirect,
} from 'react-router-dom';
import colors from '../styles/colors';
import { RiBuilding2Fill, RiDashboard2Line, RiFlag2Fill, RiUser2Fill } from 'react-icons/ri';
import { GiStack } from 'react-icons/gi';
import { AiOutlineTeam } from 'react-icons/ai';
import logo from '../images/logos/logo-white-icon-orange.svg';
import icon from '../images/icons/icon-white.svg';
import useApi from '../hooks/useApi';
import workspacesApi from '../api/workspaces';
import apiAuth from '../api/auth';
import CreateWorkspaceMenu from './workspaces/CreateWorkspaceMenu';
import useQuery from '../hooks/useQuery';
import configureTimezone from '../utility/configureTimezone';
import AccessCodeModal from './access_codes/AccessCodeModal';
import { FaShareAlt } from 'react-icons/fa';
import useWorkspaceContext from '../hooks/useWorkspaceContext';
import { HiDocumentText } from 'react-icons/hi';
import Loader from './utility/Loader';
import { MdWorkspaces } from 'react-icons/md';
import useAuth from '../auth/useAuth';
import WorkspaceViewModel from '../models/WorkspaceViewModel';
import { version } from '../../package.json';
import getInitials from '../utility/getInitials';
import { GoSidebarCollapse } from 'react-icons/go';
import useSidebarCollapsed from '../hooks/useSidebarCollapsed';

const sidebarBg = 'bg-slate';
const workspaceBtnBg = 'bg-slate-light';
const tabActiveBg = 'bg-slate-light';
const knownNonRedirectPaths = ['dashboard', 'account', 'workspaces'];

const PressableVersionLabel = () => {
  const [tapCount, setTapCount] = useState(0);
  const tapTimeout = useRef();

  const handleClick = () => {
    setTapCount((prevCount) => prevCount + 1);
    clearTimeout(tapTimeout.current);
    tapTimeout.current = setTimeout(() => setTapCount(0), 200);
  };

  useEffect(() => {
    if (tapCount !== 5) return;
    throw 'Error: 5 taps detected.'
  }, [tapCount]);

  return <div className="text-xs text-white text-right cursor-pointer" title="Oh geez...please don't..." onClick={handleClick}>v{version}</div>
}

function SidebarButton({ active, collapsed, to, title, Icon }) {
  return <li
    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${active && tabActiveBg
      }`}
  >
    <NavLink
      exact
      to={to}
      className={`focus:outline-none block text-white hover:text-white transition duration-150 ${active && 'hover:text-gray-200'
        }`}
      title={title}
    >
      <div className="flex flex-grow items-center">
        <Icon size={20} color={colors.white} className={collapsed ? "" : "mr-2"} />
        <div className={`w-full ${collapsed ? 'hidden' : 'flex'} justify-between`}>
          <span className="text-sm font-medium">{title}</span>
        </div>
      </div>
    </NavLink>
  </li>;
}

const CollapseButton = ({ collapsed, onClick }) => <div onClick={onClick}
  data-testId="sidebarCollapseButton"
  className="cursor-pointer absolute w-full bottom-0 hidden lg:flex justify-between items-center text-white border-t border-gray-300 py-3 px-3">
  {!collapsed && <span className="mr-3 transition-all duration-300 opacity-100">Collapse</span>}
  {!collapsed ? <GoSidebarCollapse
    color='white'
    className={`transform rotate-180 cursor-pointer transition-all duration-300 hover:bg-tertiary bg-opacity-0 hover:bg-opacity-80 rounded-lg p-1`}
    size={35}
  /> :
    <GoSidebarCollapse
      color='white'
      className={`cursor-pointer transition-all duration-300 hover:bg-tertiary bg-opacity-0 hover:bg-opacity-80 rounded-lg p-1`}
      size={35}
    />}
</div>

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const timezone = configureTimezone();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;

  const query = useQuery();
  const page = pathname.split('/')[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const {
    user,
    update: updateUser,
    updating,
    permissions: userPermissions,
  } = useAuth();
  const {
    data: { records: workspaces, pagy },
    error: workspacesError,
    loading: workspacesLoading,
    request: getWorkspaces,
  } = useApi(workspacesApi.getWorkspaces, { records: [], pagy: {} });
  const {
    data: workspace,
    error,
    loading,
    request: getWorkspace,
  } = useApi(workspacesApi.getWorkspace, null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const { collapsed, setCollapsed } = useSidebarCollapsed();

  const [showShareModal, setShowShareModal] = useState(
    history.location.hash === '#share'
  );
  const { workspace: contextWorkspace, setWorkspace } = useWorkspaceContext();
  const { permissions } = contextWorkspace;

  const match = matchPath(location.pathname, {
    path: '/:workspace_id/:path',
    exact: false,
    strict: false,
  });

  //TODO - consider hash path for share modal open on first page load?
  const toggleShareModal = () => setShowShareModal(!showShareModal);

  useEffect(() => {
    if (!user) return;
    getWorkspaces();
  }, [user?.objectId, workspaceId]);

  useEffect(() => {
    if (!workspaceId || knownNonRedirectPaths.includes(workspaceId)) return;
    getWorkspace(workspaceId);
  }, [workspaceId]);

  useEffect(() => {
    if (
      !workspace?.objectId ||
      contextWorkspace?.objectId === workspace?.objectId
    )
      return;
    setWorkspace(new WorkspaceViewModel(workspace));
  }, [workspace?.objectId]);

  useEffect(() => {
    if (updating || !timezone || !user) return;
    if (timezone === user.timezone) return;
    updateUser({ timezone });
  }, [timezone, user?.objectId]);

  useEffect(() => {
    if (updating) return;
    if (!workspace?.objectId) return;
    if (!user) return;
    if (workspace.objectId === user.default_workspace?.objectId) return;
    updateUser({ default_workspace_objectId: workspace.objectId });
  }, [workspace?.objectId, user?.objectId]);

  useEffect(() => {
    const workspaceIdToUse =
      match?.params?.workspace_id || contextWorkspace?.objectId;
    if (!workspaceIdToUse) return;
    if (
      workspaceId === workspaceIdToUse ||
      knownNonRedirectPaths.includes(workspaceIdToUse)
    )
      return;
    setWorkspaceId(workspaceIdToUse);
  }, [match?.params?.workspace_id, contextWorkspace?.objectId]);

  useEffect(() => {
    if (!query.get('pcd')) return;
    getWorkspace(workspaceId);
  }, [query.get('pcd')]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  if (!user)
    return (
      <div className="flex py-5 justify-center">
        <Loader color="black" />
      </div>
    );

  if (error) {
    return (
      <Redirect
        to={{
          pathname: `/${user.default_workspace.objectId}/projects`,
          state: { error: error.text() },
        }}
      />
    );
  }

  return (
    <div className={`transition-all duration-200 group z-60 relative`}>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar ${collapsed ? 'w-16 px-2' : 'w-52 py-4 px-3'} flex-shrink-0 transition-transform duration-200 ease-in-out ${sidebarBg} ${sidebarOpen ? 'translate-x-0' : `${collapsed ? '-translate-x-16' : '-translate-x-52'}`
          }`}
      >
        {/* Sidebar header */}
        <div className={`flex justify-between pt-3 pb-10 ${collapsed ? '' : 'pr-3 sm:px-2'}`}>
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-white hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <div className={`${collapsed ? 'hidden' : 'block'} text-center relative`}>
            <NavLink exact to="/projects">
              <img src={logo} width={145} />
            </NavLink>
            <PressableVersionLabel />
          </div>
          {/* Icon */}
          <div className={`${collapsed ? 'flex' : 'hidden'} mb-8 pl-1.5 justify-center relative`}>
            <NavLink exact to="/projects">
              <img src={icon} width={35} className="mt-2" />
            </NavLink>
          </div>
        </div>

        {/* Links */}
        <div>
          {/* Projects */}
          {userPermissions?.can?.('admin') && (
            <ul className="mb-6" data-testid="adminSidebar">
              <SidebarButton collapsed={collapsed} active={pathname.startsWith('/admin_dashboard')} to={`/admin_dashboard`} title="Site Dashboard" Icon={RiDashboard2Line} />
              <SidebarButton collapsed={collapsed} active={pathname.startsWith('/features')} to={`/features`} title="Features" Icon={RiFlag2Fill} />
              <SidebarButton collapsed={collapsed} active={pathname.startsWith('/workspaces')} to={`/workspaces`} title="Workspaces" Icon={RiBuilding2Fill} />
              <SidebarButton collapsed={collapsed} active={pathname.startsWith('/users')} to={`/users`} title="Users" Icon={RiUser2Fill} />
            </ul>
          )}
          <div className="relative">
            <div
              className={`${collapsed ? ' justify-center items-center rounded-full' : 'flex justify-between rounded-sm'} ${workspaceBtnBg} py-2 px-3 shadow`}
            >
              <h3
                className={`${collapsed ? 'hidden' : 'block'} text-xs text-white text-center truncate`}
                data-testid="workspaceSidebarLabel"
              >
                {workspace ? workspace.name : <span />}
              </h3>
              <h3
                className={`${collapsed ? 'block' : 'hidden'} text-xs text-white text-center truncate`}
                data-testid="workspaceSidebarLabel"
              >
                {workspace ? getInitials(workspace.name) : <span />}
              </h3>
              {workspaces.length > 1 && (
                <span className={`${collapsed ? 'hidden' : 'inline'} text-sm`}>
                  {workspace && (
                    <CreateWorkspaceMenu
                      key={`create_workspace_menu_${user.objectId}`}
                      workspace={workspace}
                      includeCreate={false}
                      caretProps={{ className: 'text-white', size: 20 }}
                      openClasses={'transform rotate-180'}
                    />
                  )}
                </span>
              )}
            </div>
          </div>
          <ul className="mt-3">
            {/* Projects */}
            {user && (<SidebarButton collapsed={collapsed} active={pathname.endsWith('projects')} to={`/${workspaceId}/projects`} title="Projects" Icon={GiStack} />)}
            {user && permissions?.can?.('read_reports') && (<SidebarButton collapsed={collapsed} active={pathname.endsWith(`/${workspaceId}/reports`)} to={`/${workspaceId}/reports`} title="Reports" Icon={HiDocumentText} />)}
            {/* Team */}
            {user && (<SidebarButton collapsed={collapsed} active={pathname.endsWith(`/${workspaceId}/team`)} to={`/${workspaceId}/team`} title="Team" Icon={AiOutlineTeam} />)}
            {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.indexOf('settings') > -1 && pathname.indexOf('project') === -1 && tabActiveBg}`}>
              <NavLink exact to={`/${workspaceId}/settings`} className={`focus:outline-none block text-white hover:text-white transition duration-150 ${page === '' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow items-center">
                  <RiSettings5Line size={16} color={colors.white} className="mr-2" />
                  <span className="text-sm font-medium">Settings</span>
                </div>
              </NavLink>
            </li> */}
            {/* <li className={`hidden px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.indexOf('settings') > -1 && pathname.indexOf('project') === -1 && 'bg-sidebar-lighter'}`}>
              <button onClick={toggleShareModal} className={`focus:outline-none block text-white hover:text-white transition duration-150 ${page === '' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow items-center">
                  <FaShareAlt size={16} color={colors.white} className="mr-2" />
                  <span className="text-sm font-medium">Share</span>
                </div>
              </button>
            </li> */}
          </ul>
        </div>
      </div>

      <CollapseButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)} />
      {/* <AccessCodeModal
        button={<span />}
        modalOpen={showShareModal}
        onClose={toggleShareModal}
      /> */}
    </div>
  );
}

export default memo(Sidebar);
