import moment from 'moment-timezone';
import React from 'react';
import { FaFlag } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Button from '../shared/Button';
import useModal from '../../hooks/useModal';
import EditWorkspaceModal from './EditWorkspaceModal';
import { BiBuilding } from 'react-icons/bi';
import formattedDateString from '../../utility/formattedDateString';
import capitalize from '../../utility/capitalize';
const featureColors = [
  'text-primary mr-1',
  'text-secondary mr-1',
  'text-tertiary-500 mr-1',
  'text-red-500 mr-1',
];
function WorkspacesTableItem(props) {
  const { open, toggle } = useModal();
  return (
    <>
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px hidden">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={props.id}
                className="form-checkbox"
                type="checkbox"
                onChange={props.handleClick}
                checked={props.isChecked}
              />
            </label>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px hidden">
          <div className="flex justify-center items-center relative" title={props.activeSubscription?.product ? capitalize(props.activeSubscription.product.name) : 'No active subscription'}>
            <span className={`rounded-full w-4 h-4 ${props.activeSubscription ? 'bg-green-500' : 'bg-gray-300'
              }`} />
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${props.id}/team`}>
            <div className="flex items-center">
              <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                {props.image ? (
                  <img
                    className="rounded-full"
                    src={props.image}
                    width="40"
                    height="40"
                    alt={props.name}
                  />
                ) : (
                  <BiBuilding className="w-full h-full p-1 border border-gray rounded-full bg-gray-100" />
                )}
              </div>
              <div
                className="font-medium text-gray-800 w-60 overflow-x-hidden overflow-ellipsis"
                title={props.name}
              >
                {props.name}
              </div><div className="flex justify-center items-center relative" title={props.activeSubscription?.product ? capitalize(props.activeSubscription.product.name) : 'No active subscription'}>
                <span className={`rounded-full w-4 h-4 ${props.activeSubscription ? 'bg-green-500' : 'bg-gray-300'
                  }`} />
              </div>
            </div>
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{props.email}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">
            {formattedDateString(props.createdAt, 'LLL')}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{props.address}</div>
          <div className="text-left">{props.phone}</div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${props.id}/projects`}>
            <div className="text-center">{props.projectsCount}</div>
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${props.id}/team`}>
            <div className="text-left font-medium text-light-blue-500">
              {props.workspaceMembersCount}
            </div>
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${props.id}/projects`}>
            <div className="text-left font-medium text-green-500">
              {props.pinsCount}
            </div>
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${props.id}/reports`}>
            <div className="text-center">{props.reportsCount}</div>
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center flex" onClick={toggle}>
            {props.features.slice(0, 4).map((_, index) => (
              <FaFlag className={featureColors[index]} />
            ))}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          {/* Menu button */}
          <Link to={`/workspaces/${props.id}/edit`}>
            <Button text="Edit" data-testid={`actionEditWorkspace${props.workspace.name}`} color="light" onClick={() => { }} />
          </Link>
        </td>
      </tr>
      <EditWorkspaceModal
        open={open}
        workspace={props.workspace}
        onClose={toggle}
        afterUpdate={props.afterUpdate}
      />
    </>
  );
}

export default WorkspacesTableItem;
