import { useEffect, useRef, useState } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import reportsApi from '../../api/reports';
import useApi from '../../hooks/useApi';
import ctLogo from '../../images/logos/ct-logo.jpg';
import drgLogo from '../../images/logos/drg-logo.webp';
import logo from '../../images/logos/logo-black.svg';
import sbLogo from '../../images/logos/sb-logo.jpg';
import jwcLogo from '../../images/logos/jwc-logo.png';
import ppsLogo from '../../images/logos/pps-logo.jpg';
import choateLogo from '../../images/logos/choate-logo.png';
import seamonWhitesideLogo from '../../images/logos/sw-logo.png';
import Loader from '../utility/Loader';
import useReportContext from './useReportContext';

const ctConfig = {
  logo: ctLogo,
  className: 'h-7',
};

const sbConfig = {
  logo: sbLogo,
  className: 'h-12',
};

const jwcConfig = {
  logo: jwcLogo,
  className: 'h-12',
};

const choateConfig = {
  logo: choateLogo,
  className: 'h-10',
};

const drgConfig = {
  logo: drgLogo,
  className: 'h-12',
};

const ppsConfig = {
  logo: ppsLogo,
  className: 'h-12',
};

const seamonWhitesideConfig = {
  logo: seamonWhitesideLogo,
  className: 'h-4',
};

export const customLogoWorkspaceIds = Object.freeze({
  '85c73740ceaed11648b1': sbConfig,
  fd7738370d83ddd48c93: ctConfig,
  '9bef12382200c182f2d8': ctConfig,
  d91a2b86683cac79aeb9: ctConfig,
  e117b7b347d0442e64da: ctConfig,
  '944b5ed1569459687cbc': ctConfig,
  '2bf314e42d796f8ce2e5': ctConfig,
  f57e97a0be7e146dce52: ctConfig,
  '2f20b42b2897f8f19b05': ctConfig,
  '8751767599ccaa333b1a': ctConfig,
  e7bba84eed5f61c3c01f: ctConfig,
  a5b065f6771e13a4db71: ctConfig,
  '7244d3758e913f3bd6ce': ctConfig,
  a2eef1cca9b297205205: ctConfig,
  '4f1cf9559e457d244478': ctConfig,
  '79ae99772b4fa731836b': ctConfig,
  a9068254f34e1c9937e7: ctConfig,
  d70f7dc17a2f666389c9: ctConfig,
  '7524e07d7523bbbc4a47': ctConfig,
  '201f9bae479e21e64306': seamonWhitesideConfig,
  e4e5a65985dd73b62ce8: jwcConfig,
  '53bbe81f0eb6abfdaf00': choateConfig,
  ac78ede5c4a28a3c7337: drgConfig,
  'bb9cc895fa31c67f2f0e': ppsConfig,
});

export default function ReportHeader({ report: passedReport, printable = false }) {
  const {
    data: savedReport,
    loading: saving,
    request: saveReport,
  } = useApi(reportsApi.updateReport, null);
  const { workspace_id } = useParams();
  const { editing } = useReportContext();
  const [report, setReport] = useState(passedReport);
  const [saved, setSaved] = useState(false);
  const inputRef = useRef(null);
  const updateTimer = useRef(null);
  const savedTimer = useRef(null);

  const customWorkspaceConfig = customLogoWorkspaceIds[workspace_id];

  const updateReport = ({ target: { textContent: value } }) => {
    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitReport(value);
    }, 1000);
  };

  const submitReport = (name) => saveReport(report.objectId, { name });

  useEffect(() => {
    if (!savedReport) return;
    setReport({ ...savedReport });
  }, [savedReport]);

  useEffect(() => {
    if (!savedReport) return;
    setSaved(true);
  }, [savedReport]);

  useEffect(() => {
    if (!inputRef.current || inputRef.current.textContent) return;
    inputRef.current.textContent = report.name;
  }, [inputRef, report]);

  useEffect(() => {
    if (!saved) return;
    savedTimer.current = setTimeout(() => {
      setSaved(false);
    }, 2000);
  }, [saved]);

  return (
    <header className={`${printable ? 'text-xs' : 'text-sm top-0 sticky w-full z-30'} relative px-4 bg-white print:border-0 border-b border-gray-200`}>
      <div className={`${printable ? 'h-12' : 'h-16'} items-center flex justify-between`}>
        <img
          src={customWorkspaceConfig ? customWorkspaceConfig.logo : logo}
          className={
            customWorkspaceConfig ? customWorkspaceConfig.className : 'h-5'
          }
        />
        <div className="w-full relative text-right">
          {printable ? <p className="text-xs w-full text-right font-semibold text-black border border-transparent rounded-md focus:outline-none">
            {report.name}
          </p> : <p
            contentEditable={editing}
            ref={inputRef}
            onInput={updateReport}
            onBlur={updateReport}
            data-testId="inputReportName"
            className={`w-full text-right font-semibold text-black border border-transparent p-0 rounded-md focus:outline-none ${editing && 'hover:bg-blue-50'
              } focus:border-gray-200 focus:ring-0 resize-none`}
          />}
          <p className={`text-sm font-light text-gray-900 tracking-wider font-oswald`}>
            <span className="font-medium">{report.project.identifier}</span>{' '}
            {report.project.name}
          </p>
        </div>
      </div>

      {saving && (
        <div className="absolute top-2 right-2 flex items-center">
          <Loader color="text-gray-700" />
        </div>
      )}
      {saved && (
        <div className="absolute top-2 right-2 flex items-center">
          <BiCheckCircle
            size={16}
            className="bg-green-500 text-white rounded-full overflow-hidden"
          />
        </div>
      )}
    </header>
  );
}
