import { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import NotePhotos from '../notes/NotePhotos';
import formattedDateString from '../../utility/formattedDateString';
import useAuth from '../../auth/useAuth';
import EditableNote from '../notes/EditableNote';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import useDelayedSave from '../../hooks/useDelayedValue';
import useSaved from '../../hooks/useSaved';

export default function PinModalNote({ pin, note, onEditButtonClicked }) {
  const {
    notetaker: {
      id: noteTakerId,
      profile_photos: { medium },
      name,
    },
    notable_version,
    created_at,
    text: noteText,
    processed_photos,
  } = note;

  const { user } = useAuth();
  const [showPhotos, setShowPhotos] = useState(false);
  const [text, setText] = useState();

  const {
    data: savedNote,
    loading: saving,
    request: updateNote,
  } = useApi(notesApi.updateNote, null);

  const { value } = useDelayedSave(text);
  const { saved } = useSaved(savedNote?.updated_at);

  const onEdit = ({ target: { textContent: newText } }) => setText(newText)

  useEffect(() => {
    if (!value) return;
    updateNote(note.objectId, { text: value })
  }, [value])

  return (
    <div className="border-b border-gray-200 mb-4 pb-3">
      <div className="flex w-full relative">
        <img className="object-cover rounded-full h-16 w-16" src={medium} />
        <div className="pl-3 flex-grow text-sm">
          <p className="font-bold">{name}</p>
          {notable_version && (
            <p className="font-semibold text-gray-500 flex items-center mb-1.5">
              {notable_version.event === 'create' && (
                <>
                  <p>Created Pin</p>
                  {notable_version.changeset.status && (
                    <FaArrowRight size={16} className="text-black mx-2" />
                  )}
                </>
              )}
              {notable_version.changeset.status && (
                <p
                  className="capitalize"
                  style={{
                    color: pin.getStatusColorFromValue(
                      notable_version.changeset.status[1]
                    ),
                  }}
                >
                  Marked {notable_version.changeset.status?.[1]?.replace?.('_', ' ')}
                </p>
              )}
            </p>
          )}
          <EditableNote
            editable={user?.id === noteTakerId}
            onEditButtonClicked={onEditButtonClicked}
            onEdit={onEdit}
            saving={saving}
            saved={saved}
            text={noteText}
          />
          <div className="flex justify-between mt-3 px-3">
            <p className="text-gray-500 text-xs">
              {formattedDateString(created_at, 'LLL')}
            </p>
            {processed_photos.length > 0 && (
              <p
                className="text-black text-xs cursor-pointer hover:opacity-80"
                onClick={() => setShowPhotos(!showPhotos)}
              >
                {showPhotos ? '-' : '+'} {processed_photos.length} Photo
                {processed_photos.length > 1 ? 's' : ''}
              </p>
            )}
          </div>
        </div>
      </div>
      {showPhotos && <NotePhotos note={note} />}
    </div>
  );
}
