import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSegmentPage } from 'react-segment-analytics';
import { useSegmentTrack } from 'react-segment-analytics';
import * as FullStory from '@fullstory/browser';
import useAuth from '../auth/useAuth';
import * as Sentry from '@sentry/react';

const pageEvent = (path) => {
  if (path.endsWith('signin')) return 'Sign In Page View';
  if (path.endsWith('onboarding/sign_up'))
    return 'Onboarding: Sign Up Page View';
  if (path.endsWith('onboarding/confirm'))
    return 'Onboarding: Email Confirmation Page View';
  if (path.endsWith('onboarding/workspace'))
    return 'Onboarding: Workspace Creation Page View';
  if (path.endsWith('onboarding/share'))
    return 'Onboarding: Workspace Share Page View';
  if (path.endsWith('team')) return 'Workspace: Team Page View';
  if (path.endsWith('projects')) return 'Workspace: Projects Page View';
  if (path.endsWith('details')) return 'Project: Details Page View';
  if (path.endsWith('maps')) return 'Project: Map Page View';
  if (path.includes('map_layers') && path.endsWith('new'))
    return 'Project: New Map Layer Page View';
  if (path.endsWith('reports') && path.includes('projects'))
    return 'Project: Reports Page View';
  if (path.endsWith('reports')) return 'Workspace: Reports Page View';
  if (path.includes('reports') && path.includes('projects'))
    return 'Reports: Report Page View';
  if (path.endsWith('cds')) return 'Project: Construction Drawings Page View';
  if (path.endsWith('settings') && path.includes('projects'))
    return 'Project: Settings Page View';
  if (path.endsWith('settings')) return 'User: Settings Page View';
  if (path.endsWith('projects')) return 'Projects Page';
  return '';
};
export default function useAnalytics() {
  const { user } = useAuth();
  const history = useHistory();
  const page = useSegmentPage();
  const track = useSegmentTrack();

  useEffect(() => {
    if (!window.analytics) return;
    page();
    const pageEventName = pageEvent(history.location.pathname);
    pageEventName !== '' && track(pageEventName, { source: 'admin' });
  }, [page, history.location]);

  useEffect(() => {
    if (!user) return;

    let userInfo = {
      name: user.name,
      email: user.email,
      phone: user.phone,
      timezone: user.timezone,
    };
    Sentry.setUser({ id: user.objectId, fullName: user.name, email: user.email });
    window.analytics?.identify(user.objectId, userInfo);
    FullStory.identify(user.objectId, userInfo);
  }, [user?.updated_at]);

  return { track };
}
