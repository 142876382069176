import React, { useEffect, useState } from 'react';
import { Link, useLocation, Redirect, useParams } from 'react-router-dom';
import AuthImage from '../images/bgs/login-bg.png';
import logo from '../images/logos/logo-black.svg';
import useAuth from '../auth/useAuth';
import apiAuth from '../api/auth';
import Loader from '../partials/utility/Loader';
import ForgotPasswordModal from '../partials/user-actions/ForgotPasswordModal';
import { environment } from '../config';

export default function Signin() {
  const { state } = useLocation();
  const { user, logIn } = useAuth();
  const [loggingIn, setLoggingIn] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const keyHandler = (e) => {
      if (e.keyCode !== 13) return;
      submitLogin(e);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // TODO: this should likely be wrapped in useCallback()
  const submitLogin = async (e) => {
    e.preventDefault();

    const email = document.getElementById('email')?.value?.trim();
    const password = document.getElementById('password').value;

    const emailIsBlank = !email || email === '';
    const passwordIsBlank = !password || password === '';

    if (emailIsBlank || passwordIsBlank) {
      const newErrors = {};
      if (emailIsBlank) newErrors.email = 'Email cannot be blank';
      if (passwordIsBlank) newErrors.password = 'Password cannot be blank';
      return setErrors(newErrors);
    }

    setLoggingIn(true);
    const response = await apiAuth.login(email, password);
    setLoggingIn(false);

    if (!response.ok) {
      let error = response.data?.error;
      let newErrors = {};

      if (error === 'Please confirm your email to continue.') {
        return window.location.assign(
          `/onboarding/confirm?email=${email.trim()}`
        );
      }

      if (error && error.toLowerCase().indexOf('email') > -1) {
        newErrors.email = error;
      }
      if (error && error.toLowerCase().indexOf('password') > -1) {
        newErrors.password = error;
      }

      if (!newErrors.email && !newErrors.password) {
        // No errors have been prepared to show to the user even though
        // the login attempt has failed. This most likely means that the
        // connection to the server has failed. Fall back to a reasonable
        // default error message.
        newErrors.email = 'Unable to connect to server - please try again.';
      }

      return setErrors(newErrors);
    }

    await logIn(response);
  };

  if (user) {
    if (!user.default_workspace?.objectId)
      return <Redirect to={`/onboarding/workspace`} />;
    return (
      <Redirect
        to={state?.from || `/${user.default_workspace?.objectId}/projects`}
      />
    );
  }

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 left-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            alt="Authentication"
          />
        </div>

        <div className="md:w-1/2" />
        <div className="md:w-1/2">
          {state?.message && (
            <div className="bg-green-400 text-white text-center text-lg font-semibold p-3 absolute w-full sm:w-1/2 top-0 right-0">
              {state?.message}
            </div>
          )}
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full flex flex-col">
              <img src={logo} className="w-3/4 mb-12" />

              {/* Form */}
              {Object.keys(errors).length > 0 && (
                <div className="mb-4" data-testid="info-error">
                  <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                    <span className="text-sm">
                      {errors.email || errors.password}
                    </span>
                  </div>
                </div>
              )}
              <form data-testid="login-form">
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      data-testid="input-email"
                      onChange={(email) => {
                        setErrors({});
                      }}
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      data-testid="input-password"
                      type="password"
                      onChange={(password) => {
                        setErrors({});
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  {/* <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link>
                  </div> */}
                  <ForgotPasswordModal />
                  <button
                    className="btn rounded bg-tertiary text-white ml-3"
                    type="submit"
                    onClick={submitLogin}
                  >
                    Login {loggingIn && <Loader />}
                  </button>
                </div>
              </form>
              <div className="pt-5 mt-6 border-t border-gray-200">
                <div className="text-sm">
                  Don’t you have an account?{' '}
                  <Link
                    className="font-medium text-tertiary hover:opacity-80"
                    to="/onboarding/sign_up"
                  >
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <a
            href={environment.deepLink}
            className="fixed sm:hidden bottom-0 w-full h-20 bg-secondary text-white shadow-lg text-lg font-bold justify-center flex items-center text-center"
          >
            Open App
          </a>
        </div>
      </div>
    </main>
  );
}
