import { create } from 'apisauce';
//import cache from '../utility/cache';
import authStorage from '../auth/storage';
import dummyRequest from '../dummy/request';
import { dummy, environment } from '../config';

const apiClient = create({
  baseURL: environment.apiUrl,
});

const basicAuth = () => {
  const token = `${environment.user}:${environment.password}`;
  const hash = new Buffer.from(token).toString('base64');
  return `Basic ${hash}`;
};

if (dummy) {
  let dummyHandler = async (url, params, axiosConfig) =>
    dummyRequest(url, params, axiosConfig);

  apiClient.post = dummyHandler;
  apiClient.get = dummyHandler;
  apiClient.put = dummyHandler;
} else {
  apiClient.addAsyncRequestTransform(async (request) => {
    const isAppConfigRequest = ['/app_config'].includes(request.url);
    const authToken = await authStorage.getToken('authToken');
    if (!authToken && !isAppConfigRequest) return;

    if (!authToken && isAppConfigRequest) {
      request.headers.Authorization = basicAuth();
      return;
    }

    request.headers['Authorization'] = `Bearer ${authToken}`;
  });
}

export default apiClient;
