import React, { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import useApi from '../../hooks/useApi';
import notesApi from '../../api/notes';
import PinModalNote from './PinModalNote';
import { useParams } from 'react-router-dom';
import NoteEditModal from '../notes/NoteEditModal';
import { Note, Pin } from '../../data/models';

export default function PinModalNotes({ pin }: {
  pin: Pin;
}) {
  const {
    data: { records: notes },
    request: loadNotes,
  } = useApi(notesApi.getNotes, { records: [], pagy: {} }, true);
  const [showMore, setShowMore] = useState(false);
  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const { share_id } = useParams<{ share_id: string | undefined }>();

  const loadPinNotes = () => loadNotes('pin', pin.objectId, { share_id, items: 100 });

  useEffect(() => {
    loadPinNotes()
  }, []);


  return <>
    <div className="px-4 pt-4">
      <p className="font-normal mb-4 text-sm">Latest Activity</p>
      {notes && notes?.length > 0 ? (
        notes.slice(0, 2).map((note: Note) => <PinModalNote
          key={`pinNote_${note.objectId}_${note.updated_at}`}
          pin={pin}
          note={note}
          onEditButtonClicked={() => setEditingNote(note)}
        />)
      ) : (
        <div className="text-sm">No recent activity.</div>
      )}
      {showMore === true &&
        notes
          .slice(2, notes.length)
          .map((note: Note) => <PinModalNote
            key={`pinNote_${note.objectId}_${note.updated_at}`}
            pin={pin}
            note={note}
            onEditButtonClicked={() => setEditingNote(note)}
          />)}
      {notes && notes?.length > 2 && (
        <div className="flex justify-end">
          <p
            className="flex items-center text-black cursor-pointer hover:opacity-80 text-sm"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? (
              <>
                Show Less{' '}
                <FaChevronUp size={16} className="text-gray-500 ml-3" />
              </>
            ) : (
              <>
                See More{' '}
                <FaChevronDown size={16} className="text-gray-500 ml-3" />
              </>
            )}
          </p>
        </div>
      )}
    </div>
    <NoteEditModal
      noteId={editingNote?.objectId || null}
      onClose={() => setEditingNote(null)}
      afterEdit={loadPinNotes}
    />
  </>;
}
