import { useEffect, useRef, useState } from 'react';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import { BiCheckCircle } from 'react-icons/bi';
import Loader from '../utility/Loader';
import ProjectSiteNote from '../project/ProjectSiteNote';
import useReportContext from '../reports/useReportContext';
import { useParams } from 'react-router-dom';

function EntryPinNote({
  pin,
  note: passedNote,
  editing = false
}) {

  const {
    data: savedNote,
    error,
    loading: saving,
    request: updatePinNote,
  } = useApi(notesApi.updateNote, null);
  const {
    data: currentNote,
    error: noteError,
    loading,
    request: getNote,
  } = useApi(notesApi.getNote, null);
  const [note, setNote] = useState(passedNote);
  const [saved, setSaved] = useState(false);
  const { share_id } = useParams();
  const updateTimer = useRef(null);
  const savedTimer = useRef(null);

  const { pinUpdated, setPinUpdated } = useReportContext();

  const updateNote = ({ target: { textContent: text } }) => {
    if (text === note.text) return;

    setNote({ ...note, text });

    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitNote(text);
    }, 1000);
  };

  const submitNote = (text) =>
    updatePinNote(note.objectId, { ...note, text });

  useEffect(() => {
    if (pinUpdated !== pin.objectId) return;
    if (saved) return;
    getNote(note.objectId, { share_id });
  }, [pinUpdated]);

  useEffect(() => {
    if (!passedNote) return;
    setNote({ ...passedNote });
  }, [passedNote]);

  useEffect(() => {
    if (!currentNote) return;
    setNote({ ...currentNote });
  }, [currentNote]);

  useEffect(() => {
    if (!savedNote) return;
    setNote({ ...savedNote });
    setSaved(true);
  }, [savedNote]);

  useEffect(() => {
    if (!saved) return;
    setPinUpdated(pin.objectId);
    savedTimer.current = setTimeout(() => {
      setSaved(false);
      setPinUpdated(false);
    }, 2000);
  }, [saved]);

  return (
    <div className="flex flex-col relative">
      <p className="font-semibold text-sm mb-1">
        Notes:
        {saving && (
          <div className="absolute top-0 right-2 flex items-center">
            <Loader color="text-gray-700" />
          </div>
        )}
        {saved && (
          <div className="absolute top-0 right-2 flex items-center">
            <BiCheckCircle
              size={16}
              className="bg-green-500 text-white rounded-full overflow-hidden"
            />
          </div>
        )}
      </p>
      <ProjectSiteNote
        key={`project_site_note_${note.objectId}`}
        showProfilePhoto={false}
        showUserTitle={false}
        condensed={true}
        note={note}
        showEditButton={false}
        showPhotos={false}
        editable={editing}
        onEdit={updateNote}
      />
    </div>
  );
}

export default EntryPinNote;
