import PermissionsViewModel from './PermissionsViewModel';

// TODO: consolidate this class with the Project type definition in
// data/models.ts
export default class ProjectViewModel {
  constructor(object) {
    Object.assign(this, object);
    this.permissions = new PermissionsViewModel({
      permissions: object.permissions,
    });
  }
}
