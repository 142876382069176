import { forwardRef, useEffect, useState } from 'react';
import Modal, { ModalFooter, ModalHeader } from '../utility/Modal';
import PinFilterCard from '../pins/PinFilterCard';
import MapBoxMap from '../maps/mapbox/MapBoxMap';
import ReportTypeRadioButtons from '../report_types/ReportTypeRadioButtons';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
import { Redirect, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import useProject from '../../hooks/useProject';
import usePins from '../pins/usePins';
import PinModal from '../pins/PinModal';
import NewPinModal from '../pins/NewPinModal';
import Button from '../shared/Button';
import { FiCircle } from 'react-icons/fi';
import useFeatures from '../../hooks/useFeatures';
import useReportCreationServices from './hooks/useReportCreationServices';
import { ReportSiteNoteSelection } from './ReportSiteNoteSelection';
import PinCountBadge from '../maps/PinCountBadge';

export default function CreateReportModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    project_id: string;
  }>();

  const { project, loading: loadingProject } = useProject() as {
    project: any;
    loading: boolean;
  };

  const {
    defaultReport,
    newing,
    getNewReport,
    createdReport,
    saving,
    addReport,
  } = useReportCreationServices();

  const { loadingPins, pins, pinsPagy, pinFilter, setPinFilter, setLoadPins, excludedPins } =
    usePins();
  const { features: { rollout: { custom_map_state = false } = {} } = {} } =
    useFeatures();

  const [report, setReport] = useState<any>({});
  const [newReport, setNewReport] = useState<any>(null);
  const [reportType, setReportType] = useState<any>(null);
  const [inspectionDate, setInspectionDate] = useState(null);
  const [mapLayers, setMapLayers] = useState([]);
  const [nameSaved, setNameSaved] = useState(false);
  const [selectedPin, setSelectedPin] = useState<any>(null);
  const [pinDropModalShown, setPinDropModalShown] = useState(false);
  const [mapState, setMapState] = useState<any>({
    pin_style: custom_map_state
      ? {
        color: 'orange',
        icon: 'pin',
        showCount: true,
      }
      : {
        color: 'inherit',
        icon: 'inherit',
        showCount: true,
      },
  });

  const [saved, setSaved] = useState(false);

  const updateReport = (key: string, value: any, auto = false) => {
    if (key === 'name' && !nameSaved && !auto) setNameSaved(true);
    if (key === 'inspection_date') setInspectionDate(value);
    report[key] = value;
    setReport({ ...report });
  };

  const generateReport = () => {
    let report_map_layers_attributes = mapLayers.map(
      ({ id: map_layer_id, opacity }, index) => {
        return { map_layer_id, opacity, order: index };
      }
    );

    report.report_notes_attributes = report.report_notes;

    let reportFields = {
      ...report,
      metadata: custom_map_state ? { map_state: mapState } : {},
      report_type_id: reportType?.id,
      report_map_layers_attributes,

      // Incorporate the excludedPins filter from the PinContext to make sure
      // that we obey the user's requested pin exclusions.
      pin_filter: {
        ...pinFilter,
        where_not: {
          ...pinFilter.where_not,
          id: excludedPins ? excludedPins.map((p: any) => p.id) : [],
        },
      },
    };

    addReport(project?.objectId, reportFields);
  };

  useEffect(() => {
    if (!createdReport) return;
    setNewReport(createdReport);
  }, [createdReport]);

  useEffect(() => {
    if (!newReport) return;
    setSaved(true);
  }, [newReport]);

  useEffect(() => {
    if (!open || !reportType) return;
    getNewReport(project_id, {
      inspection_date: inspectionDate ? inspectionDate : null,
      report_type_id: reportType ? reportType.id : null,
    });
  }, [open, reportType, inspectionDate]);

  useEffect(() => {
    if (!defaultReport) return;

    setReport({
      ...defaultReport,
      name: nameSaved ? report.name : defaultReport.name,
    });

    let newPinFilter = {
      ...defaultReport.pin_filter,
      where: {
        ...defaultReport.pin_filter.where,
        notes: {
          notetaker_id:
            pinFilter.where?.notes?.notetaker_id?.length > 0
              ? pinFilter.where?.notes?.notetaker_id
              : defaultReport.pin_filter?.where?.notes?.notetaker_id,
        },
      },
    };

    if (newPinFilter.where?.start_date && newPinFilter.where?.end_date)
      newPinFilter.where.days = null;

    if (Object.keys(pinFilter).length === 0) setPinFilter(newPinFilter);
    else
      setPinFilter((existing: any) => ({
        ...existing,
        where: {
          ...existing.where,
          pin_type_id: defaultReport.pin_filter.where.pin_type_id,
        },
        or_where: {
          ...defaultReport.pin_filter.or_where,
        },
      }));

    setTimeout(() => setLoadPins(true), 1);

  }, [defaultReport]);

  const CustomInput = forwardRef(
    ({ value, onClick }: { value: any; onClick: () => void }, ref) => (
      <input
        type="text"
        placeholder={'N/A'}
        value={value}
        onClick={onClick}
        onChange={() => { }}
        // TODO: figure out why this ref type is mismatched
        // @ts-ignore
        ref={ref}
        className={`z-40 px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none focus:ring-0`}
      />
    )
  );

  let showMap = !reportType || reportType.has_map_layer === true;

  if (saved)
    return (
      <Redirect
        to={`/${workspace_id}/projects/${project_id}/reports/${newReport?.objectId}`}
      />
    );

  const pinsToDisplayOnModal = pins.filter(
    (pin: any) =>
      !excludedPins.some((excludedPin: any) => excludedPin.id === pin.id)
  );

  if (loadingProject) return <NewReportLoader />;

  return (
    <>
      <Modal
        isOpen={open}
        onClose={onClose}
        maxWidth={reportType ? 'max-w-screen' : 'max-w-2xl'}
        maxHeight={reportType && 'h-full'}
        dialogPadding={'px-0'}
      >
        <ModalHeader
          title={<NewReportTitle project={project} />}
          onClose={onClose}
          extraClass={reportType && 'fixed left-0 top-0 z-50 w-full bg-white shadow-md'}
        />
        {saved ? (
          <NewReportSaved />
        ) : (
          <NewReportBody condensed={!reportType}>
            <NewReportPane condensed={!reportType}>

              {reportType && (
                <FieldContainer>
                  <FieldLabel mb={0}>Report Name</FieldLabel>
                  <div className="bg-white border-b border-gray-200 overflow-hidden">
                    <div className="flex items-center">
                      <input
                        type="text"
                        data-testid="inputReportName"
                        value={report.name || ''}
                        onChange={({ target: { value } }) =>
                          updateReport('name', value)
                        }
                        className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                      />
                    </div>
                  </div>
                </FieldContainer>
              )}

              <div className="flex">
                <FieldContainer>
                  <FieldLabel mb={2}>
                    {reportType ? (
                      <>
                        Report Type{' '}
                        {newing && <Loader color={'text-gray-700'} />}
                      </>
                    ) : (
                      <>What type of Report do you want to create?</>
                    )}
                  </FieldLabel>
                  <ReportTypeRadioButtons
                    reportType={reportType}
                    onChosen={(report_type: any) => setReportType(report_type)}
                  />
                </FieldContainer>
                {reportType && (
                  <FieldContainer>
                    <FieldLabel>Date of Visit</FieldLabel>
                    <div className="bg-white border-b border-gray-200 overflow-hidden">
                      <div className="flex items-center">
                        <DatePicker
                          selected={
                            report.inspection_date
                              ? new Date(report.inspection_date)
                              : null
                          }
                          onChange={(date) =>
                            updateReport('inspection_date', date)
                          }
                          timeInputLabel="Time:"
                          wrapperClassName="w-full"
                          maxDate={new Date()}
                          dateFormat={'MM/dd/yyyy h:mm aa'}
                          showTimeInput
                          // TODO: provide the `value` and `onClick` parameters to CustomInput
                          // @ts-ignore
                          customInput={<CustomInput />}
                        />
                      </div>
                    </div>
                  </FieldContainer>
                )}
              </div>
              {reportType && (
                <NewReportMapContainer>
                  {open && (
                    <>
                      {/* TODO: either provide all parameters or make them optional */}
                      {/* @ts-ignore */}
                      <MapBoxMap
                        defaultLayers={mapLayers}
                        onLayersChosen={setMapLayers}
                        showNavigationControls={true}
                        fitToPins={true}
                        pins={pinsToDisplayOnModal}
                        project={project}
                        pinStyle={mapState.pin_style}
                        mapTypeToggle={false}
                        onSelectPin={(objectId: any) => {
                          setSelectedPin(
                            pins.find((pin: any) => pin.objectId === objectId)
                          );
                        }}
                        onBearingUpdated={(bearing: number) =>
                          setMapState((m: any) => ({ ...m, bearing }))
                        }
                        onPitchUpdated={(pitch: number) =>
                          setMapState((m: any) => ({ ...m, pitch }))
                        }
                        passedSelectedPin={selectedPin}
                        canEditLayers={false}
                        zoomOnSelect={false}
                      >
                        {custom_map_state && (
                          <NewReportMapTypeToggle mapState={mapState} setMapState={setMapState} />
                        )}
                        <PinCountBadge loading={loadingPins} count={pinsPagy.count} />
                      </MapBoxMap>
                      <PinModal
                        key={`pin_${selectedPin && selectedPin.objectId}_map`}
                        onClose={() => setSelectedPin(null)}
                        pinId={selectedPin && selectedPin.objectId}
                        // PinModal does not take an `open` parameter.
                        // @ts-ignore
                        open={selectedPin !== null}
                        actionable={true}
                        onOpenPinModal={() => setPinDropModalShown(true)}
                      />
                      {pinDropModalShown && selectedPin && (
                        // TODO: either provide all parameters or make them optional
                        // @ts-ignore
                        <NewPinModal
                          key={`pin_modal_${selectedPin?.objectId}`}
                          onClose={(_wasSubmitted: boolean) => {
                            setLoadPins(false);
                            setLoadPins(true);
                            setPinDropModalShown(false);
                            setSelectedPin(null);
                          }}
                          projectId={project_id}
                          existingPin={selectedPin}
                          zoom={18.5}
                        />
                      )}
                    </>
                  )}
                  {!showMap && (
                    <MapNotIncludedCallout reportType={reportType} />
                  )}
                </NewReportMapContainer>
              )}
            </NewReportPane>
            <NewReportPane>
              {open && reportType && (<>
                {reportType.includes_site_notes && <ReportSiteNoteSelection
                  reportNote={report.report_notes?.[0]}
                  onDismiss={() => updateReport('report_notes', [])}
                  onSelect={(newNote: any) => updateReport('report_notes', [newNote])}
                />}
                <PinFilterCard
                  reportType={reportType}
                  previousInspectionDate={
                    report.previous_report?.inspection_date
                  }
                />
              </>
              )}
            </NewReportPane>
          </NewReportBody>
        )}
        {reportType && <ModalFooter extraClass={"fixed bottom-0 left-0 w-full z-10"}>
          {!saved && (
            <button
              className="modal-save-btn"
              type="button"
              disabled={saving || !reportType}
              onClick={generateReport}
            >
              Review Report {saving && <Loader />}
            </button>
          )}
          <button
            className="modal-close-btn"
            disabled={saving}
            type="button"
            onClick={onClose}
          >
            Close
          </button>
        </ModalFooter>}
      </Modal>
    </>
  );
}

const NewReportLoader = () => <div className="flex py-4 justify-center">
  <Loader color="black" />
</div>

const NewReportTitle = ({ project = null }: { project: any }) => <>
  New Report <span className="mx-2 font-light">|</span>{' '}
  <span className="font-semibold font-oswald uppercase">
    {project?.identifier}
  </span>{' '}
  <span className="font-light font-oswald uppercase">
    {project?.name}
  </span>
</>

const NewReportSaved = () => <div className="px-2 pt-2">
  <div className="px-5 py-16 flex flex-col justify-center items-center">
    <BiCheckCircle
      size={100}
      className="bg-green-500 text-white rounded-full overflow-hidden mb-5"
    />
    <p className="text-xl text-gray-500 font-bold">
      Report successfully generated
    </p>
  </div>
</div>

const NewReportBody = ({ condensed, children }: { condensed: boolean, children: any }) => <div className={`px-5 ${condensed ? "py-5" : "pt-16 pb-20"} flex-col xs:flex-row flex flex-1 w-full h-full relative`}>
  {children}
</div>

const NewReportMapContainer = ({ children }: { children: any }) => <div
  className={`bg-gray-800 relative hidden xs:block font-semibold h-full overflow-hidden rounded-md border shadow-md bg-gray-50`}
>{children}</div>


const NewReportPane = ({ condensed, children }: { condensed?: boolean, children: any }) => <div className={`${!condensed && 'flex-1'} flex flex-col xs:p-3`}>{children}</div>
const MapNotIncludedCallout = ({ reportType }: { reportType: any }) => <div className="absolute inset-0 flex justify-center items-center">
  <p className="text-gray-50 text-md bg-gray-900 rounded-sm px-4 py-2 shadow-sm">
    Map <span className="italic">not included</span> on{' '}
    {reportType.name} Report
  </p>
</div>

const NewReportMapTypeToggle = ({ mapState, setMapState }: { mapState: any, setMapState: any }) => <div className="top-0 right-0 absolute z-10 m-4 rounded-md overflow-hidden print:hidden p-1 bg-white">
  <Button
    text="One Color"
    color={
      mapState.pin_style.color === 'orange'
        ? 'secondary'
        : 'light'
    }
    size="xs"
    rounded="ronded-l-md"
    onClick={() =>
      setMapState((ms: any) => ({
        ...ms,
        pin_style: {
          color: 'orange',
          icon: 'pin',
          showCount: true,
        },
      }))
    }
    icon={
      <FiCircle className="ml-2 text-white rounded-full bg-primary" />
    }
    savedText={undefined}
    saving={undefined}
    saved={undefined}
    tooltip={undefined}
  />
  <Button
    text="Inherit Color"
    color={
      mapState.pin_style.color !== 'orange'
        ? 'secondary'
        : 'light'
    }
    size="xs"
    rounded="ronded-r-md"
    onClick={() =>
      setMapState((ms: any) => ({
        ...ms,
        pin_style: {
          color: 'inherit',
          icon: 'pin',
          showCount: true,
        },
      }))
    }
    icon={
      <>
        <FiCircle className="-mr-1 ml-1 text-white rounded-full bg-primary" />
        <FiCircle className="-mr-1 text-white rounded-full bg-indigo-600" />
        <FiCircle className="-mr-1 text-white rounded-full bg-red-600" />
      </>
    }
    savedText={undefined}
    saving={undefined}
    saved={undefined}
    tooltip={undefined}
  />
</div>

const marginBottoms: { [key: number]: string } = {
  0: "mb-0",
  1: "mb-1",
  2: "mb-2",
};

const FieldLabel = ({ children, mb = 0 }: { children: any, mb?: number }) => <div className={`font-semibold ${marginBottoms[mb]} flex`}>{children}</div>
const FieldContainer = ({ children }: { children: any }) => <div className="mb-4 w-full" > {children}</div>