import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAuth from '../../auth/useAuth';
import ErrorViewModel from '../../models/ErrorViewModel';
import formatPhoneNumber from '../../utility/formatPhoneNumber';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import authApi from '../../api/auth';
export default function SignupForm({ code, onComplete }) {
  const { user, logIn } = useAuth();

  const [newUser, setNewUser] = useState({
    password: '',
    password_confirmation: '',
  });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const signUp = async (values) => {
    setError(false);

    setSaving(true);

    if (code) values.user_access_codes_attributes = [{ code }];

    let response = await authApi.signUp(values);

    setSaving(false);

    if (!response.ok)
      return setError(new ErrorViewModel(response.data.error || response.data));

    if (response.data['confirmed']) {
      await logIn(response);
      onComplete?.(response.data);
    } else {
      onComplete?.(values);
    }
  };

  const formik = useFormik({
    initialValues: newUser,
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      name: Yup.string().required('Required'),
      phone: Yup.string().required('Required'),
      password: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .max(128, 'Must be 128 characters or less')
        .required('Required'),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: signUp,
  });

  useEffect(() => {
    newUser &&
      Object.keys(formik.initialValues).length < 4 &&
      formik.setValues(newUser);
  }, [newUser]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {error && <ErrorView error={error} extraClass={'mb-4'} />}
      <div className="space-y-4 mb-8">
        {/* Name */}
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="name">
            Your Name{' '}
            <span className="text-red-500">
              * {formik.errors.name && formik.errors.name}
            </span>
          </label>
          <input
            id="name"
            name="name"
            data-testid="input-name"
            className={`form-input w-full ${
              formik.errors.name && 'border-red-500'
            }`}
            type="text"
            {...formik.getFieldProps('name')}
          />
        </div>
        {/* Emails */}
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="email">
            Email{' '}
            <span className="text-red-500">
              * {formik.errors.email && formik.errors.email}
            </span>
          </label>
          <input
            id="email"
            name="email"
            data-testid="input-email"
            className={`form-input w-full ${
              formik.errors.email && 'border-red-500'
            }`}
            type="email"
            {...formik.getFieldProps('email')}
          />
        </div>
        {/* Phone Number */}
        <div>
          <label className="block text-sm font-medium mb-1" htmlFor="phone">
            Phone Number{' '}
            <span className="text-red-500">
              * {formik.errors.phone && formik.errors.phone}
            </span>
          </label>
          <input
            id="phone"
            name="phone"
            data-testid="input-phone"
            className={`form-input w-full ${
              formik.errors.phone && 'border-red-500'
            }`}
            type="text"
            {...formik.getFieldProps('phone')}
            onChange={(e) => {
              e.target.value = formatPhoneNumber(e.target.value);
              formik.handleChange(e);
            }}
            value={formik.values.phone}
          />
        </div>
        {/* Passwords */}
        <div className="flex space-x-4">
          <div className="flex-1">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="password"
            >
              Password{' '}
              <span className="text-red-500">
                * {formik.errors.password && formik.errors.password}{' '}
                {formik.values.password &&
                  formik.values.password_confirmation &&
                  !formik.errors.password &&
                  !formik.errors.password_confirmation &&
                  formik.values.password !==
                    formik.values.password_confirmation &&
                  'Do not match'}
              </span>
            </label>
            <input
              id="password"
              data-testid="input-password"
              className={`form-input w-full ${
                formik.errors.password && 'border-red-500'
              }`}
              type="password"
              {...formik.getFieldProps('password')}
            />
          </div>
          <div className="flex-1">
            <label
              className="block text-sm font-medium mb-1"
              htmlFor="postal-code"
            >
              Confirm Password{' '}
              <span className="text-red-500">
                *{' '}
                {formik.errors.password_confirmation &&
                  formik.errors.password_confirmation}
              </span>
            </label>
            <input
              id="password_confirmation"
              data-testid="input-password-confirmation"
              className={`form-input w-full ${
                formik.errors.password_confirmation && 'border-red-500'
              }`}
              type="password"
              {...formik.getFieldProps('password_confirmation')}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="btn rounded bg-tertiary hover:opacity-80 text-white ml-auto"
          data-testid="action-submit-signup"
          type="submit"
        >
          Sign Up {saving ? <Loader /> : <>-&gt;</>}
        </button>
      </div>
    </form>
  );
}
