import React from 'react';
import DeleteProjectModal from './DeleteProjectModal';

export default function ProjectSettings() {
  return (
    <div className="p-5 w-full max-w-9xl self-start">
      <div className="bg-white border-b border-gray-200 p-5">
        <div className="font-semibold text-xl">Remove Project</div>
        <div className="pt-3 pb-5 text-sm">
          Delete this project permanently along with all its associated data.
          This action cannot be undone.
        </div>
        <DeleteProjectModal />
      </div>
    </div>
  );
}
