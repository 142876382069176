import client from './client';

const endpoint = '/fields';

const getField = () => client.get(`${endpoint}`);
const createFieldsetField = (fieldSetId, field) => client.post(`/fieldsets/${fieldSetId}${endpoint}`, { field });
const updateField = (id, field) => client.put(`${endpoint}/${id}`, { field });
const archiveField = (id) => client.put(`${endpoint}/${id}/archive`);
const unarchiveField = (id) => client.put(`${endpoint}/${id}/unarchive`);
const destroyField = (id) => client.delete(`${endpoint}/${id}`);

export default {
    getField,
    createFieldsetField,
    updateField,
    archiveField,
    unarchiveField,
    destroyField,
};
