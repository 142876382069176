import { memo } from 'react';
import placeholder from '../../images/placeholders/banner-white.png';
import PinViewModel from '../../models/PinViewModel';
import Loader from '../utility/Loader';
import PinsFilterDropdown from './PinsFilterDropdown';
import SearchForm from '../actions/SearchForm';
import PinCategory from './PinCategory';
import PinLastUpdatedAt from './PinLastUpdatedAt';

function PinsList({
  pins,
  loading,
  onSearch,
  onFilter,
  currentFilter = null,
  onSelect,
}) {
  const noPinsMessage = () => {
    if (currentFilter === null) return 'No pins have been added to project';
    return (
      <span>
        No pins found matching filters{' '}
        {currentFilter.days ? (
          <span>
            that have been updated in the{' '}
            <span className="italic font-medium">
              {' '}
              last {currentFilter.days} day(s)
            </span>
          </span>
        ) : (
          ''
        )}
      </span>
    );
  };
  return (
    <>
      <div className="grid grid-cols-5 gap-1 pb-3 px-5 bg-gray-50 shadow">
        <div className="col-span-4">
          <SearchForm
            placeholder={'Search Pins...'}
            className="bg-white w-full"
            onSearched={onSearch}
          />
        </div>
        <div className="col-span-1 rounded-sm border border-gray-200 flex justify-center items-center">
          <PinsFilterDropdown
            onFilter={onFilter}
            currentFilter={currentFilter}
          />
        </div>
      </div>
      <div className="text-black text-xs h-full px-5 py-2 overflow-y-auto">
        {loading && (
          <div className="flex py-4 justify-center">
            <Loader color="black" />
          </div>
        )}
        <div data-testid="pin_list">
          {pins.map((obj) => {
            let pin = new PinViewModel(obj);

            return (
              <a
                className="cursor-pointer"
                key={`pin_list_pin_${obj.objectId}`}
                onClick={() => onSelect(pin)}
              >
                <div
                  className="bg-white rounded-sm border border-gray-200 overflow-hidden mb-2"
                  data-testid={`pinListPin${obj.identifier}`}
                >
                  <div className="flex flex-col h-full">
                    <div className="grid grid-cols-4">
                      <div className="col-span-1 flex justify-center items-center">
                        <img
                          loading='lazy'
                          className={'bg-gray-100 object-cover w-full h-20'}
                          src={
                            pin.latestPhoto()
                              ? pin.latestPhoto().medium
                              : placeholder
                          }
                        />
                      </div>
                      <div className="col-span-3 p-2">
                        <div className="flex flex-row items-center">
                          <div className="font-bold mr-2">{pin.identifier}</div>
                          <PinLastUpdatedAt pin={pin} />
                        </div>
                        <div className="flex flex-row items-center flex-wrap mt-2">
                          <div className="bg-blue-100 rounded-sm text-xxs px-1 mr-2">
                            {pin.pin_type && pin.pin_type.name}
                          </div>
                          <div
                            className="bg-gray-200 text-white rounded-sm text-xxs px-1 mr-2 capitalize"
                            style={{
                              backgroundColor: pin && pin.getStatusColor(),
                            }}
                          >
                            {pin.getStatus()}
                          </div>
                          {pin
                            .categoryFields()
                            .map(({ field_option: { color, name } }, index) => {
                              return (
                                <PinCategory
                                  key={`report_pin_${pin.objectId}_category_${index}`}
                                  size="sm"
                                  name={name}
                                  color={color}
                                />
                              );
                            })}
                        </div>
                        {pin.latestNote() && (
                          <div className="text-xxs mt-2 py-0.5 px-1 bg-gray-100 rounded truncate">
                            {pin.latestNote()}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>
        {!loading && pins.length === 0 && (
          <div className="flex justify-center">
            <p className="py-3 font-semibold text-center">{noPinsMessage()}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default memo(PinsList);
