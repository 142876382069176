// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import {
  useLayoutEffect,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import { BsBoundingBoxCircles } from 'react-icons/bs';
import { FaCog } from 'react-icons/fa';
import useApi from '../../hooks/useApi';
import projectsApi from '../../api/projects';
import Loader from '../utility/Loader';
import ErrorViewModel from '../../models/ErrorViewModel';
import { Redirect, useParams } from 'react-router-dom';
import ErrorView from '../utility/ErrorView';
import { ContractDetails } from './ContractDetails';
import { mapboxStyleUrls, Project } from '../../data/models';

type MapBounds = {
  north: number;
  south: number;
  east: number;
  west: number;
};

export type Step = 'name' | 'map' | 'reporting' | 'advanced';

export function EditProjectModal({
  project: passedProject = null,
  step,
  setStep,
  closeWithoutSaving: onClose,
  afterSave,
  hasContractDetails,
}: {
  project: any;
  step: Step;
  setStep: (_: Step) => void;
  closeWithoutSaving?: () => void;
  afterSave: (newProject: any) => void;
  hasContractDetails: boolean;
}) {
  const {
    data: newProject,
    error: creationError,
    loading,
    request: saveProject,
  } = useApi(projectsApi.updateProject, null as any);

  const { workspace_id } = useParams() as any; // TODO: figure out why useParams is returning {}
  const [error, setError] = useState<ErrorViewModel | null | unknown>(null);
  const [project, setProject] = useState(passedProject || {});
  const [bounds, setBounds] = useState(null as MapBounds | null);

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(hasContractDetails || step == 'advanced');

  // TODO: center should probably be optional (i.e., nullable), but much of this
  // file assumes that center is a valid {lat;lng} struct
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const [location, setLocation] = useState(null as any);
  const [currentZoom, setCurrentZoom] = useState(18);
  const [useCoordinates, setUseCoordinates] = useState(false);
  const [open, setOpen] = useState(false);

  // use useLayoutEffect() to work around a bug where modals will not render if
  // isOpen is set to true on instantiation. It needs to start as false and be
  // updated later. When that bug is fixed then we can kill this variable and
  // layout effect.
  useLayoutEffect(() => {
    setOpen(true);
  }, []);

  // mapboxMap will be created on the mapboxContainerRef where possible
  const mapboxContainerRef = useRef(null);
  const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map | undefined>(
    undefined
  );

  const setStatesFromExistingMap = () => {
    passedProject.map.default_zoom &&
      setCurrentZoom(passedProject.map.default_zoom);
    let neBound = passedProject.map.bounds.find(
      ({ direction }: { direction: string }) => direction === 'ne'
    );
    let swBound = passedProject.map.bounds.find(
      ({ direction }: { direction: string }) => direction === 'sw'
    );
    setBounds({
      north: neBound.lat,
      south: swBound.lat,
      east: neBound.lng,
      west: swBound.lng,
    });
    setCenter({
      lat: passedProject.map.center.lat,
      lng: passedProject.map.center.lng,
    });
    setLocation({
      ...passedProject.map.location,
      coordinate_attributes: {
        lat: passedProject.map.center.lat,
        lng: passedProject.map.center.lng,
      },
    });
  };

  const submitProject = () => {
    if (!location)
      return setError(
        new ErrorViewModel({ project_location: 'Project location is required' })
      );
    if (!bounds)
      return setError(
        new ErrorViewModel({ map: 'Project bounds are required' })
      );

    project.map_attributes = {
      default_zoom: currentZoom,
      location_attributes: location,
      bounds_attributes: [
        {
          direction: 'ne',
          lat: bounds.north,
          lng: bounds.east,
        },
        {
          direction: 'sw',
          lat: bounds.south,
          lng: bounds.west,
        },
      ],
      center_attributes: {
        lat: center?.lat,
        lng: center?.lng,
      },
    };

    if (passedProject) {
      project.map_attributes.id = passedProject.map.id;
      project.map_attributes.location_attributes.id =
        passedProject.map.location.id;
      project.map_attributes.center_attributes.id = passedProject.map.center.id;

      let neBound = passedProject.map.bounds.find(
        ({ direction }: { direction: string }) => direction === 'ne'
      );
      let swBound = passedProject.map.bounds.find(
        ({ direction }: { direction: string }) => direction === 'sw'
      );

      if (neBound) {
        project.map_attributes.bounds_attributes[0].id = neBound.id;
      }
      if (swBound) {
        project.map_attributes.bounds_attributes[1].id = swBound.id;
      }
    }

    setProject(project);
    saveProject(passedProject.objectId, project);
  };

  const setName = useCallback(() => {
    if (!project.name || project.name.trim().length === 0)
      return setError(new ErrorViewModel({ project_name: 'is required' }));
    if (!project.identifier || project.identifier.trim().length === 0)
      return setError(
        new ErrorViewModel({ project_identifier: 'is required' })
      );
    setError(null);
    setStep('map');
  }, [project.name, project.identifier]);

  const updateProject = (key: string, value: unknown): void => {
    setProject({ ...project, [key]: value });
  };

  const updateMapAttributes = (key: string, value: unknown) => {
    let mapAttributes = project.map_attributes || {};
    mapAttributes.center_attributes = mapAttributes.center_attributes || {};
    mapAttributes.center_attributes[key] = value;
    setProject((existing: any) => ({
      ...existing,
      map_attributes: mapAttributes,
    }));
  };

  const setProjectFromPlace = (place: google.maps.places.PlaceResult) => {
    if (!place.geometry)
      return setError(
        new ErrorViewModel({
          location: 'No location found, please select address from dropdown',
        })
      );

    const centerLat = place.geometry.location?.lat();
    const centerLng = place.geometry.location?.lng();

    let address = place.address_components
      ? {
        address: place.formatted_address,
        street_address: `${place.address_components.find(
          ({ types }) => types.indexOf('street_number') > -1
        )?.long_name || ''
          } ${place.address_components.find(
            ({ types }) => types.indexOf('route') > -1
          )?.long_name || ''
          }`,
        locality: `${place.address_components.find(
          ({ types }) => types.indexOf('locality') > -1
        )?.short_name || ''
          }`,
        region: `${place.address_components.find(
          ({ types }) => types.indexOf('administrative_area_level_1') > -1
        )?.short_name || ''
          }`,
        postal_code: `${place.address_components.find(
          ({ types }) => types.indexOf('postal_code') > -1
        )?.short_name || ''
          }`,
      }
      : {
        address: place.formatted_address,
        street_address: `${place.formatted_address?.split(',')[0]}`,
        locality: `${place.formatted_address?.split(',')[1]}`,
        region: `${place.formatted_address?.split(',')[2]}`,
        postal_code: `${place.formatted_address?.split(',')[3]}`,
      };

    setLocation({
      ...address,
      coordinate_attributes: {
        lat: centerLat,
        lng: centerLng,
      },
    });

    if (centerLat && centerLng) {
      setCenter({ lat: centerLat, lng: centerLng });
    }
  };

  const geocodeCoordinates = ({ lat, lng }: { lat: string; lng: string }) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results && results[0]) {
          setProjectFromPlace(results[0]);
        } else {
          setError(
            new ErrorViewModel({
              location: 'No results found for that location',
            })
          );
        }
      } else {
        setError(
          new ErrorViewModel({ location: 'No results found for that location' })
        );
      }
    });
  };

  const dragListener = useCallback(
    (_: unknown) => {
      if (!mapboxMap) return;
      setBounds({
        north: mapboxMap.getBounds()._ne.lat,
        south: mapboxMap.getBounds()._sw.lat,
        east: mapboxMap.getBounds()._ne.lng,
        west: mapboxMap.getBounds()._sw.lng,
      });

      setCurrentZoom(mapboxMap.getZoom());
      setCenter(mapboxMap.getCenter());
    },
    [mapboxMap]
  );

  useEffect(() => {
    // if the map is already initialized, skip out
    if (mapboxMap) return;

    // don't initialize if the mapContainerRef is not set
    if (!mapboxContainerRef.current) return;

    const newMapboxMap = new mapboxgl.Map({
      container: mapboxContainerRef.current,
      style: mapboxStyleUrls['satellite-streets'],
    });

    newMapboxMap.on('load', () => newMapboxMap.resize());
    setMapboxMap(newMapboxMap);
  }, [mapboxMap, mapboxContainerRef.current, step]);

  useEffect(() => {
    mapboxMap?.on('dragend', dragListener);
    return () => mapboxMap?.off('dragend', dragListener);
  }, [mapboxMap]);

  useEffect(() => {
    if (!center || !mapboxMap) return;

    mapboxMap.flyTo({
      center: [center.lng, center.lat],
      zoom: currentZoom,
      maxDuration: 1000,
    });

    if (!markerRef.current) {
      markerRef.current = new mapboxgl.Marker();
      markerRef.current
        .setLngLat([center.lng, center.lat])
        .addTo(mapboxMap.current);
    } else {
      markerRef.current.setLngLat([center.lng, center.lat]);
    }
  }, [center, markerRef.current, mapboxMap]);

  const containerStyle = { width: '100%', height: '30px' };

  const getTitleBasedOnStep = (step: string) => {
    switch (step) {
      case 'reporting':
        return 'Update Permits';
      case 'advanced':
        return 'Update Contract Details';
      default:
        return 'Update Project';
    }
  };

  useEffect(() => {
    passedProject && setStatesFromExistingMap();
  }, [passedProject]);

  useEffect(() => {
    if (newProject && passedProject) {
      onClose?.();
      afterSave?.(newProject);
    }
  }, [newProject]);

  useEffect(() => {
    setError(creationError);
  }, [creationError]);

  if (useCoordinates && !location) containerStyle.height = '0px';
  if (location) containerStyle.height = '400px';
  if (newProject && !passedProject)
    return (
      <Redirect
        to={
          // @ts-ignore
          `/${workspace_id}/projects/${newProject.objectId}/details?pcd=true`
        }
      />
    );

  return (
    <div className="text-base">
      <Modal
        isOpen={open}
        dialogScrolls={true}
        modalScrolls={false}
        maxHeight={'max-h-auto'}
        maxWidth={'max-w-3xl'}
        dialogClass={'py-10'}
        modalClass="rounded-lg overflow-hidden"
        aligned={'items-start'}
        onClose={onClose}
      >
        <ModalHeader
          data-testid="element-create-project-modal-header"
          title={getTitleBasedOnStep(step)}
          onClose={onClose}
        />
        <div className="px-2 pt-2">
          <div className="p-5">
            {error ? <ErrorView error={error} extraClass={'mb-4'} /> : null}
            {step === 'reporting' && (
              <>
                <div className="bg-white px-2 py-3 rounded-md shadow-sm border mb-8">
                  <div className="font-semibold px-2 mb-2">Reporting</div>
                  <div className="flex items-center mb-2">
                    <div className="flex-1 px-2 border-l-0 border-t-0 border-b border-r border-gray-200">
                      <p className="font-semibold px-2 mb-2">
                        NPDES Permit No.
                      </p>
                      <input
                        type="text"
                        placeholder="Ex: SCR10Z1FD"
                        value={project.npdes_permit_no || ''}
                        onChange={({ target: { value } }) =>
                          updateProject('npdes_permit_no', value)
                        }
                        className="placeholder-gray-400 focus:ring-0 bg-transparent text-sm w-full border-0 focus:outline-none"
                      />
                    </div>
                    <div className="flex-1 pl-4 border-gray-200 border-l-0 border-t-0 border-r-0 border-b">
                      <p className="font-semibold px-2 mb-2">
                        MS4 Operator
                      </p>
                      <input
                        type="text"
                        placeholder="Ex: Berkeley County, SC"
                        value={project.ms4_operator || ''}
                        onChange={({ target: { value } }) =>
                          updateProject('ms4_operator', value)
                        }
                        className="placeholder-gray-400 focus:ring-0 bg-transparent text-sm border-0 w-full focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="px-2">
                    <p className="font-semibold px-2 mb-2">
                      Inspection frequency
                    </p>
                    <div className="flex items-center pt-3 h-10">
                      <div
                        className={`rounded-full w-4 h-4 border mr-2 ${project.inspection_frequency &&
                          project.inspection_frequency === 'monthly'
                          ? 'bg-secondary'
                          : 'hover:bg-blue-100 bg-white'
                          }`}
                        onClick={() =>
                          updateProject('inspection_frequency', 'monthly')
                        }
                      />{' '}
                      <p className="mr-2 text-sm">Monthly</p>
                      <div
                        className={`rounded-full w-4 h-4 border mr-2 ${project.inspection_frequency &&
                          project.inspection_frequency === 'weekly'
                          ? 'bg-secondary'
                          : 'hover:bg-blue-100 bg-white'
                          }`}
                        onClick={() =>
                          updateProject('inspection_frequency', 'weekly')
                        }
                      />{' '}
                      <p className="mr-2 text-sm">Weekly</p>
                      <div
                        className={`rounded-full w-4 h-4 border mr-2 ${project.inspection_frequency &&
                          project.inspection_frequency === 'other'
                          ? 'bg-secondary'
                          : 'hover:bg-blue-100 bg-white'
                          }`}
                        onClick={() =>
                          updateProject('inspection_frequency', 'other')
                        }
                      />{' '}
                      <p className="mr-2 text-sm">Other</p>
                      {project.inspection_frequency &&
                        project.inspection_frequency === 'other' && (
                          <input
                            className="placeholder-gray-400 focus:ring-0 bg-transparent text-sm border-0 focus:outline-none ml-3 bg-gray-200 px-2 py-1 rounded-md shadow-sm"
                            autoFocus={
                              project.inspection_frequency &&
                              project.inspection_frequency === 'other' &&
                              !project.inspection_frequency_other
                            }
                            value={project.inspection_frequency_other || ''}
                            onChange={({ target: { value } }) =>
                              updateProject('inspection_frequency_other', value)
                            }
                            placeholder={'Add frequency'}
                          />
                        )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  {passedProject && (
                    <button
                      className="modal-save-btn"
                      type="button"
                      data-testid="action-project-save-btn"
                      disabled={loading}
                      onClick={submitProject}
                    >
                      Save {loading && <Loader />}
                    </button>
                  )}
                </div>
              </>
            )}
            {(step === 'name' || step === 'advanced') && (
              <>
                {step !== 'advanced' && (
                  <>
                    <div className="font-semibold px-2 mb-2">Identifier</div>
                    <div className="bg-white border-b border-gray-200 overflow-hidden mb-8">
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="identifier"
                          data-testid="input-project-identifier"
                          placeholder="Ex: GR2753"
                          value={project.identifier || ''}
                          onChange={({ target: { value } }) =>
                            updateProject('identifier', value)
                          }
                          className="px-2 bg-gray-50 placeholder-gray-400 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="font-semibold px-2 mb-2">Name</div>
                    <div className="bg-white border-b border-gray-200 overflow-hidden mb-8">
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="name"
                          data-testid="input-project-name"
                          placeholder="Ex: Judson Mills"
                          value={project.name || ''}
                          onChange={({ target: { value } }) =>
                            updateProject('name', value)
                          }
                          className="px-2 bg-gray-50 placeholder-gray-400 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                        />
                      </div>
                    </div>
                    <div className="font-semibold px-2 mb-2">Description</div>
                    <div className="bg-white border-b border-gray-200 overflow-hidden mb-4">
                      <div className="flex items-center">
                        <textarea
                          name="description"
                          data-testid="input-project-description"
                          placeholder="Ex: Consists of 820 Acres 4 miles south west of main"
                          value={project.description || ''}
                          onChange={({ target: { value } }) =>
                            updateProject('description', value)
                          }
                          className="px-2 bg-gray-50 placeholder-gray-400 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                          rows={5}
                        />
                      </div>
                    </div>
                    <div className="pb-2 mb-2">
                      <button
                        onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                        className="flex items-center transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer px-5 py-2"
                      >
                        <FaCog size="12"></FaCog><span className="ml-2">{showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'}</span>
                      </button>
                    </div>
                  </>
                )}
                {showAdvancedSettings && (
                  <ContractDetails
                    project={project}
                    setProject={setProject}
                    showProgressBar={true}
                  />
                )}
                <div className="flex justify-between">
                  {passedProject && (
                    <button
                      className="modal-save-btn"
                      type="button"
                      data-testid="action-project-save-btn"
                      disabled={loading}
                      onClick={submitProject}
                    >
                      Save {loading && <Loader />}
                    </button>
                  )}
                  {step !== 'advanced' && (
                    <button
                      className={
                        passedProject ? 'modal-close-btn' : 'modal-save-btn'
                      }
                      disabled={
                        !passedProject &&
                        (!project.name ||
                          project.name.trim().length == 0 ||
                          !project.identifier ||
                          project.identifier.trim().length === 0)
                      }
                      type="button"
                      onClick={setName}
                      data-testid="action-project-add-location"
                    >
                      {passedProject ? 'Edit Map' : 'Add Location'}
                    </button>
                  )}
                </div>
              </>
            )}
            {step === 'map' && (
              <>
                <div className="bg-white p-3 rounded-md relative shadow mb-4">
                  <div className="px-2 mb-2 pb-2 border-b flex justify-between items-center">
                    <span className="font-bold">Project Info</span>{' '}
                    <button
                      className="transition-opacity duration-300 focus:outline-none bg-white px-2 text-xs py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer"
                      onClick={() => setStep('name')}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="px-2">
                    <p className="text-lg font-light font-oswald uppercase">
                      <span className="font-normal">{project.identifier}</span>{' '}
                      {project.name}
                    </p>
                    {project.description && (
                      <p className="truncate text-sm text-gray-700 mt-2">
                        {project.description}
                      </p>
                    )}
                  </div>
                </div>
                <div className="font-semibold px-2 mb-2 flex justify-between items-center">
                  <span>Location</span>
                  <button
                    onClick={() => setUseCoordinates(!useCoordinates)}
                    className="focus:outline-none bg-white px-3 py-1 border-2 border-sm-lightblue shadow-sm text-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer"
                  >
                    {useCoordinates ? 'Use address' : 'Use coordinates'}
                  </button>
                </div>
                <div className="bg-white overflow-hidden mb-6">
                  <div className="flex items-center">
                    {useCoordinates && (
                      <div
                        className={`flex bg-white w-full px-3 py-2 border ${location ? 'rounded-t-md' : 'rounded-md'
                          }`}
                      >
                        <input
                          type="text"
                          defaultValue={center ? center.lat : ''}
                          value={project.map_attributes?.center_attributes?.lat}
                          placeholder="Latitude"
                          onChange={(e) =>
                            updateMapAttributes('lat', e.target.value)
                          }
                          onPaste={(e) => {
                            let clipboardDataText =
                              e.clipboardData.getData('Text');
                            if (!clipboardDataText.includes(',')) return;
                            let [lat, lng] = clipboardDataText.split(',');
                            updateMapAttributes('lat', lat.trim());
                            updateMapAttributes('lng', lng.trim());
                            e.preventDefault();
                            e.stopPropagation();
                            return false;
                          }}
                          className="flex-1 px-2 bg-white placeholder-gray-500 text-sm w-full border-0 border-r border-gray-300 focus:outline-none focus:ring-0 "
                        />
                        <input
                          type="text"
                          defaultValue={center ? center.lng : ''}
                          value={project.map_attributes?.center_attributes?.lng}
                          placeholder="Longitude"
                          onChange={(e) =>
                            updateMapAttributes('lng', e.target.value)
                          }
                          onPaste={(e) => {
                            let clipboardDataText =
                              e.clipboardData.getData('Text');
                            if (!clipboardDataText.includes(',')) return;
                            let [lat, lng] = clipboardDataText.split(',');
                            updateMapAttributes('lat', lat.trim());
                            updateMapAttributes('lng', lng.trim());
                          }}
                          className="flex-1 pr-2 pl-4 bg-white placeholder-gray-500 text-sm w-full border-0 focus:outline-none focus:ring-0"
                        />
                        <button
                          className="modal-save-btn mr-2"
                          type="button"
                          onClick={() =>
                            geocodeCoordinates({
                              lat: project?.map_attributes?.center_attributes
                                ?.lat,
                              lng: project?.map_attributes?.center_attributes
                                ?.lng,
                            })
                          }
                        >
                          Set
                        </button>
                      </div>
                    )}
                  </div>
                  {
                    <div
                      className={`bg-white rounded-b-md shadow border relative ${!location && !useCoordinates && 'h-12'
                        }`}
                    >
                      <div
                        ref={mapboxContainerRef}
                        style={{ width: '100%', height: 400 }}
                      />
                      {location && (
                        <div className="px-3 pb-3 pt-3 flex justify-between border-l border-r">
                          <p className="flex text-sm font-semibold items-center">
                            <BsBoundingBoxCircles size={18} className="mr-2" />{' '}
                            <>Position the designated site in your view</>
                          </p>
                        </div>
                      )}
                    </div>
                  }
                </div>
              </>
            )}
          </div>

          {step === 'map' && (
            <div className="modal-footer">
              <button
                className="modal-save-btn mr-2"
                type="button"
                disabled={loading}
                onClick={submitProject}
              >
                {passedProject ? 'Save' : 'Create'} Project{' '}
                {loading && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={loading}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
