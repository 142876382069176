import { memo, useEffect, useState } from 'react';
import { BiXCircle, BiCheckCircle } from 'react-icons/bi';
import useApi from '../../hooks/useApi';
import imagesApi from '../../api/images';
import Loader from '../utility/Loader';
import { uploadAttachment } from '../../hooks/useS3';
import { useParams } from 'react-router';
import FileIcon from '../shared/FileIcon';
import { FiMapPin } from 'react-icons/fi';
import Modal, { ModalHeader, ModalBody } from '../utility/Modal';
import useModal from '../../hooks/useModal';
import Title from '../pins/modal/Title';
import { MdImageSearch } from 'react-icons/md';
import {
  gpsValuesPresent,
  getGpsValues,
  getFileExif,
  isExifSupportedFile
} from '../../utility/exifHelpers';


const ExitLocationIconContainer = ({ children }) => <div data-testid="exifLocationContainer" className="absolute bottom-2 right-2">{children}</div>
const ExifMissingIcon = () => <ExitLocationIconContainer>
  <div className="bg-red-600 bg-opacity-60 rounded-full p-1 cursor-pointer flex items-center justify-center" title="Missing Exif GPS data on image">
    <FiMapPin data-testid="exifLocationIcon" size={14} className="text-gray-100" />
  </div>
</ExitLocationIconContainer>;

const ExifMapPinIcon = ({ onClick, hasLocation }) => <div className="bg-gray-900 bg-opacity-60 rounded-full p-1 cursor-pointer flex items-center justify-between" onClick={onClick}>
  {hasLocation && <FiMapPin data-testid="exifLocationIcon" size={14} className="text-gray-100" />}
  <MdImageSearch data-testId="exifDataIcon" size={16} className="text-gray-100" />
</div>
const ExifShowMoreButton = ({ children, onClick }) => <p className="text-center bg-gray-200 shadow-sm rounded-lg text-xs py-1 mt-5 font-medium cursor-pointer" onClick={onClick}>{children}</p>
const ExifMoreContainer = ({ children }) => <div className="mt-4 pt-4 border-t">{children}</div>
const ExifLocationIcon = ({ exifData }) => {

  const { open, toggle } = useModal();
  const [showMore, setShowMore] = useState(false);
  const gpsValues = getGpsValues(exifData);

  return <>
    <ExitLocationIconContainer>
      <ExifMapPinIcon onClick={toggle} hasLocation={!!gpsValues} />
    </ExitLocationIconContainer>
    <Modal isOpen={open} onClose={toggle} modalClass={'rounded-lg'} >
      <ModalHeader
        title={'Exif Data'}
        onClose={toggle}
        closeBtnTestId="button-close-add-photo-modal"
      />
      <ModalBody>
        <Title>Gps Data</Title>
        {Object.keys(gpsValues).map((key) => {
          return <p className='mb-1'><span className="font-semibold capitalize">{key}</span> : {gpsValues[key]}</p>
        })}
        {showMore && <>
          <ExifMoreContainer>
            <Title>Exif Data</Title>
            {Object.keys(exifData).map((key) => {
              if (!exifData[key]?.description) return null
              return <p className='mb-1'><span className="font-semibold capitalize">{key}</span> : {exifData[key].description}</p>
            })}
          </ExifMoreContainer>
        </>}
        <ExifShowMoreButton onClick={() => setShowMore(!showMore)}>Show {showMore ? 'less' : 'more'}...</ExifShowMoreButton>
      </ModalBody>
    </Modal>
  </>
}

const EmptyPhotoItem = ({ children }) => {
  return <div className="h-28 w-28 bg-blue-200 font-bold text-blue-900 border border-blue-600 rounded-md flex justify-center items-center">
    {children}
  </div>
}

const UploadCompleteIcon = () => <div className="absolute w-full flex h-full justify-center items-center">
  <BiCheckCircle
    size={40}
    className="bg-green-500 text-white rounded-full overflow-hidden"
  />
</div>

export {
  UploadCompleteIcon,
  EmptyPhotoItem
}

function AddPhotoItem({
  passback,
  hidden = false,
  file,
  index,
  locationRequired,
  description: passedDescription,
  onSelect,
  onRemove,
  onLocated,
  onSaved,
  startSaving,
}) {
  const {
    data: created_image,
    error,
    loading: saving,
    request: addImage,
  } = useApi(imagesApi.addImage, null);
  const [signing, setSigning] = useState(false);
  const [signedId, setSignedId] = useState(null);
  const [description, setDescription] = useState(null);
  const [isThumbnailError, setThumbnailError] = useState(false);
  const [exifData, setExifData] = useState(null);
  const [loadingExif, setLoadingExif] = useState(true);
  const { project_id } = useParams();

  const signImage = async () => {
    setSigning(true);
    let signed_id = await uploadAttachment(file);
    setSignedId(signed_id);
    setSigning(false);
  };

  const saveImage = () => {
    let imageToCreate = { description, asset_attributes: { file: signedId } };
    addImage(project_id, imageToCreate);
  };

  useEffect(() => {
    setDescription(passedDescription);
  }, [passedDescription]);

  //TODO - verify if this internal handling is still necessary and if so combine image signing and upload
  useEffect(() => {
    if (!startSaving || saving) return;
    signImage();
  }, [startSaving]);

  useEffect(() => {
    if (!signedId || passback) return;
    saveImage();
  }, [signedId]);

  useEffect(() => {
    if (!signedId || !passback) return;
    onSaved?.(signedId, file, index);
  }, [signedId]);

  useEffect(() => {
    if (!created_image) return;
    onSaved?.(signedId, file);
  }, [created_image]);

  useEffect(() => {
    if (!file || !isExifSupportedFile(file)) return;
    const updateExifData = async () => {
      const data = await getFileExif(file, { expanded: true });
      setExifData(data);
      setLoadingExif(false)
    }
    updateExifData();
  }, [file])

  useEffect(() => {
    if (!exifData) return;
    if (gpsValuesPresent(exifData)) {
      onLocated?.(index, getGpsValues(exifData));
    }
  }, [exifData])

  return (
    <div
      className={`disabled:opacity-50 ${!!onSelect ? 'cursor-zoom-in' : ''} group relative mr-2 mb-2 ${hidden ? 'hidden' : ''}`}
      data-testid="addPhotoItem"
      disabled={saving}
      onClick={onSelect}
    >
      {!(created_image || (signedId && passback)) && !saving && !signing && (
        <BiXCircle
          size={20}
          data-testid="addPhotoItemRemove"
          className="hover:opacity-80 cursor-pointer rounded-full overflow-hidden absolute -top-1 -right-1 bg-black text-white opacity-0 group-hover:opacity-100"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove?.(index)
            return false;
          }}
        />
      )}
      {(saving || signing) && (
        <div className="absolute w-full flex h-full justify-center items-center">
          <Loader margin={''} />
        </div>
      )}
      {(created_image || (signedId && passback)) && (
        <UploadCompleteIcon />
      )}
      {!isThumbnailError && (
        <img
          className={'h-28 w-28 object-cover border border-gray-200 rounded-md'}
          data-testid="addPhotoItemThumbnail"
          src={URL.createObjectURL(file)}
          onError={(e) => setThumbnailError(true)}
        />
      )}
      {exifData && gpsValuesPresent(exifData) && <ExifLocationIcon locationRequired={locationRequired} exifData={exifData} />}
      {locationRequired && !loadingExif && !gpsValuesPresent(exifData) && <ExifMissingIcon />}
      {isThumbnailError && (
        <div className="h-28 w-28 border border-secondary rounded-md flex flex-col justify-between items-center ">
          <div className={'h-20 flex items-center'}>
            <FileIcon file={file} size={42} className={`text-secondary`} />
          </div>
          <div className="bg-gray-100 border-t px-2 py-1 rounded-b-md w-full">
            <p className="text-xxs line-clamp-2 text-center text-gray-700 font-medium">
              {file.name}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(AddPhotoItem);
