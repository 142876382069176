import client from './client';
const endpoint = '/documents';

const getDocuments = (parentId = '', params = {}) =>
  client.get(`projects/${parentId}${endpoint}`, params);

function getDocument(projectId, folderId, documentId) {
  return client.get(
    `projects/${projectId}/folders/${folderId}/documents/${documentId}`
  );
}

function addDocument(projectId, folderId, document) {
  return client.post(
    `projects/${projectId}/folders/${folderId}/documents`,
    document
  );
}

const downloadDocument = (id, params = {}) =>
  client.get(`${endpoint}/${id}/download`, params, { responseType: 'blob' });

const updateDocument = (projectId, folderId, documentId, document) =>
  client.put(
    `projects/${projectId}/folders/${folderId}/documents/${documentId}`,
    document
  );
const deleteDocument = (projectId, folderId, documentId) =>
  client.delete(
    `projects/${projectId}/folders/${folderId}/documents/${documentId}`
  );
const archiveDocument = (projectId, folderId, documentId) =>
  client.put(
    `projects/${projectId}/folders/${folderId}/documents/${documentId}/archive`
  );
const unarchiveDocument = (projectId, folderId, documentId) =>
  client.put(
    `projects/${projectId}/folders/${folderId}/documents/${documentId}/unarchive`
  );

function getDocumentFile(parentId, folderId, documentId) {
  return client.get(
    `projects/${parentId}/folders/${folderId}/documents/${documentId}/download`,
    null,
    {
      responseType: 'blob',
    }
  );
}

export default {
  getDocuments,
  getDocument,
  addDocument,
  downloadDocument,
  updateDocument,
  deleteDocument,
  getDocumentFile,
  archiveDocument,
  unarchiveDocument,
};
