/**
 * NOTE: this file was copied and pasted from mobile-app on 2023-08-29
 */

// Some day in the future, we could make these entities use UUIDv4 IDs so that
// we can know the ID of an entity before persisting it.

export type FileVariant = {
  original: string;
  medium: string;
  medium_large: string;
  thumbnail: string;
  large: string;
  taken_at: string,
}

export type AssetFile = {
  id: number,
  asset_id: number,
  file: string,
  file_type: string,
  created_at: string,
  updated_at: string,
  taken_at: string,
  download: FileVariant,
  original: string;
  medium: string;
  medium_large: string;
  thumbnail: string;
  large: string;
}
export type Asset = {
  id: number,
  assetable: any,
  assetable_type: string,
  created_at: string,
  files: AssetFile;
  name: string;
  objectId: string;
  updated_at: string;
  save_started_at: string | null;
  taken_at: string | null;
  version: number | null;
}

export type ConversionUnit = {
  type: string,
  conversion_factor: number
} | string

export type ConversionParameter = {
  name: string,
  value: number,
  unit: ConversionUnit,
}

export type Conversion = {
  method: object,
  name: string,
  parameters: ConversionParameter[],
}

type DatumBoundingBox = {
  east_longitude: number,
  north_latitude: number,
  south_latitude: number,
  west_longitude: number,
}

export type Datum = {
  id: number,
  name: string,
  proj4: string,
  wkt: string,
  data: {
    json: {
      bbox: DatumBoundingBox,
      conversion?: Conversion,
    }
  },
}

export type ArchiveTabState = 'active' | 'archived';
export type SearchParams = {
  include_archived?: boolean;
  items?: number;
  page?: number;
  q?: string;
}
export type Location = {
  latitude: number;
  longitude: number;
};

export enum MapViewMode {
  Streets = 'streets',
  SatelliteStreets = 'satellite-streets',
  ThreeD = '3d',
  NAIP = 'naip',
}

export enum MapLayerAlignmentMode {
  Standard = 'standard',
  TwoPoint = 'two-point',
}

export const mapboxStyleUrls = {
  [MapViewMode.Streets]: 'mapbox://styles/mapbox/streets-v12',
  [MapViewMode.SatelliteStreets]:
    'mapbox://styles/mapbox/satellite-streets-v12',
  [MapViewMode.ThreeD]: 'mapbox://styles/mapbox/satellite-streets-v12',
  [MapViewMode.NAIP]: 'mapbox://styles/tez123zsm/clk6znmur02k501qvbyb11a6t',
};

export type FeatureFlag = {
  id: number;
  name: string;
  key: string;
  percentage: number;
  data: {
    description: string;
  };
}

export type Subscription = {
  id?: number;
  product?: Product;
  status?: string;
  product_id: number
}

export type Workspace = {
  id: number;
  objectId: string;
  conference: boolean;
  name: string;
  owner_id: number;
  created_at: string;
  updated_at: string;
  phone?: string;
  address?: string;
  color?: string;
  custom_domain?: string;
  active_subscription?: Subscription;
  allow_matching_custom_domain_signups?: boolean;
  settings: { reports: { use_logo: boolean } } & unknown;
  projects_count: number;
  pins_count: number;
  workspace_members_count: number;
  feature_flags: unknown[];
};

export type Product = {
  id: number;
  name: string;
}

export type Project = {
  id: number;
  objectId: string;
  identifier: string;
  name: string;
  updated_at: string;
  map: { location: { region: string; locality: unknown } };
  bounding_box: BoundingBox;
  contract_length: number;
  contract_amount: number;
  contract_start: unknown;
  contract_end: unknown;
  completion_percentage: number;
  project_contacts?: ProjectContact[];
};

export type BoundingBox = {
  east: number;
  west: number;
  north: number;
  south: number;
};

export type User = {
  id: number;
  objectId: string;
  email: string;
  confirmed: boolean;
  custom_domain: string;
  name: string;
  color: string;
  phone: string;
  qualifications: string;
  company: string;
  title: string;
  default_workspace: Workspace;
  current_workspace_objectId: string;
  walkthrough_seen: boolean;
  updated_at: unknown; // timestamp
  settings: unknown; // some object
  timezone: string;
};

export type WorkspaceMember = {
  accepted: boolean;
  id: number;
  objectId: string;
  workspace_id: number;
  created_at: string;
  updated_at: string;
  member: User;
  creator: User;
  role: Role;
  role_id: number;
};

export type Role = {
  id: number;
  name: string;
  description: string;
};

export type EntryGroup = {
  component_name: string;
  extras: null | object;
  helper_text: string | null;
  id: number;
  name: string;
  objectId: string;
}


export type EntryValueOption = {
  label?: string;
  value: string;
  id?: number;
}
export type EntryValueType = 'string' | 'array' | 'object' | 'number' | 'boolean';
export type EntryStyles = {
  height?: string;
  width?: string;
}

export type EntryInfo = {
  end_index?: number;
  start_index?: number;
  count?: number;
}

export type EntryComponentName = "EntryViolation" | "EntrySignature" | "EntryTextInput" | "EntryCheckboxes" | "EntryNote" | "EntryPin" | "EntryFreeText" | "EntryPinSummary" | "EntryDatetimeInput" | "EntryTextareaInput" | "EntryDropdown"
export type EntryNotesComponentName = 'EntryNote' | 'EntryNotePhotos'
export type Entry = {
  id: string;
  component_name: EntryNotesComponentName | EntryComponentName;
  class_name: string;
  description: string;
  objectId: string;
  name: string;
  value: string;
  value_type: EntryValueType;
  value_options: EntryValueOption[];
  styles?: EntryStyles;
  info?: EntryInfo;
  order: number;
}

export type Note = {
  // NOTE: probably there are some other fields in here
  id: number;
  objectId: string;
  notetaker_id: number;
  notable_type: string;
  notable_id: number;
  text: string | null;
  created_at: string;
  updated_at: string;
  notable_version_id: number;
  save_started_at: unknown;
  notetaker?: {
    name: string;
    title: string;
    profile_photos: PhotoSizes;
  };
  processed_photos?: PhotoSizes[];

  // This is probably not the complete definition of this field
  assets_attributes: { file?: string, to_upload?: { uri: string } }[];

  isOffline: boolean;
  jobStatus: string;
};

export type ReportType = {
  archived_at: string | null;
  created_at: string;
  id: number;
  name: string;
  objectId: string;
  pin_filter: any;
  styles: any;
  includes_pins: boolean;
  includes_site_notes: boolean;
  has_map_layer: boolean;
  updated_at: string;
  reports_count: number;
  duplicated_from_id: number | null;
}

export type Report = {
  id: number;
  objectId: string;
  project_id: number;
  creator_id: number;
  assigned_to_id: unknown | null;
  name: string;
  created_at: string; // ex: "2023-06-07 16:24:40"
  updated_at: string; // ex: "2023-06-07 16:24:40"
  pin_filter?: {
    // This structure is probably dynamic and probably supports a number of
    // other parameters. My understanding is that this gets passed to the Rails
    // API verbatim.
    where: {
      days?: number;
      status?: string[];
      pin_type_id?: number[];
      notes?: {
        notetaker_id?: unknown[];
        created_at?: string; // ex: "2023-05-26 00:00:00 UTC..2023-06-07 23:59:59 UTC"
      };
    };
  };
  report_type_id: number;
  inspection_date: string; // "2023-06-07 16:24:12"
  previous_report_id: unknown | null;
  published: unknown | null;
  page_break_indexes: unknown[];
  pdf_status: 'in_review' | unknown;

  // I'm unsure how much is supported by this `styles` parameter.
  styles: unknown & {
    body?: unknown & { height: number };
    header?: unknown & { height: number };
  };

  report_notes: any[];
  report_notes_attributes: unknown;

  published_at: unknown | null;
};

export type Pin = {
  id: number;
  objectId: string;
  map_id?: number;
  creator_id: number;
  created_at: string;
  updated_at: string;
  coordinate_id: number;
  pin_type_id: number;
  identifier: string;
  color: string; // e.g., "#F8A332"
  status: string;
  private: boolean;
  identifier_pin_type_count: number;
  workspace_id: number;
  project_id: number;
  status_snapshot: {
    id: number;
    name: string;
    color: string;
    final?: unknown;
    value: string;
    objectId: string;
    created_at: string;
    updated_at: string;
    description: string;
  };
  field_values?: PinFieldValue[];
  coordinate: {
    id: number;
    lat: number;
    lng: number;
    // NOTE: this is distinct from the Pin's objectId
    objectId: string;
  };
  latest_notes?: Note[];
  save_started_at?: unknown;
  tag_list?: unknown;
};

export type IconNames = "plus" | "circle" | "triangle";

export type Icon = {
  component_name: string;
  name: IconNames;
  id: number;
  objectId: string;
}

export type Status = {
  id: number;
  objectId: string;
  name: string;
  color: string;
  description: string;
  final: boolean | null;
  value: string;
}

export type FieldComponents = 'FieldNumeric' | 'FieldTags' | 'FieldBoxes' | 'FieldPicker' | 'FieldSwitch'

export type Field = {
  id: number;
  objectId: string;
  name: string;
  component_name: FieldComponents;
  required: boolean;
  identifies_pin: boolean;
  colors_pin: boolean;
  statuses_pin: boolean | null; // no-longer in-use
  has_description: boolean;
  allows_duplicates: boolean;
  prefixs_pin: boolean; // not a typo- the server-side data is named prefixs_pin
  filters_pin: boolean;
  auto_fills: boolean;
  field_options: FieldOption[];
  style?: { [styleName: string]: string };
  archived_at: string;
}

export type Fieldset = {
  id: number;
  objectId: string;
  name: string;
  allows_multiple: boolean;
  fields: Field[];
};

export type PinType = {
  id: number;
  objectId: string;
  name: string;
  pin_color: string;
  icon: Icon;
  note_photo_required_on_create: boolean;
  note_required_on_create: boolean;
  created_at: string;
  updated_at: string;
  statuses: Status[];
  fieldsets: Fieldset[];
  archived_at: string | null;
  pins_count: number;
};

// This type signature scraped from a sample pin that was in a comment
// in offlinePinSerializer.js on 2023-05-10
export type PinFieldValue = {
  field_id: number;
  field_option: {
    color: string;
    description: string;
    id: number;
    name: string;
    objectId: string;
    value: string;
    field: {
      allows_duplicates: boolean | null;
      colors_pin: boolean;
      component_name: string;
      field_options: FieldOption[];
      has_description: boolean;
      id: number;
      identifies_pin: boolean;
      name: string;
      objectId: string;
      prefixs_pin: boolean; // not a typo- the server-side data is named prefixs_pin
      required: boolean;
      statuses_pin: boolean;
      style?: { [styleName: string]: string };
      updateable: boolean;
    };
  };
  field_option_id: number;
  id: number;
  objectId: string;
  value: string;
  value_type: null | unknown;
};

export type FieldOption = {
  color: string | null;
  default: unknown;
  description: string;
  id: number;
  name: string;
  objectId: string;
  order: number;
  value: string | null;
  archived_at?: string | null;
  field_values_count?: number;
  updated_at?: string;
};

export type PhotoSizes = {
  // these are URIs
  large: string;
  medium: string;
  original: string;
  thumbnail: string;
};

export type MapLayer = {
  id: number;
  objectId: string;
  center_id: unknown | null;
  rotation: number; // usually 0.0
  creator_id: number;
  map_id: unknown | null;
  file_type: 'KML' | 'TIF' | 'PDF' | string;
  created_at: string;
  updated_at: string;
  name: string; // user specified name
  scale: {
    to: number;
    from: number;
    system: 'imperial' | unknown;
    to_unit: 'inch' | unknown;
    from_unit: 'feet' | unknown;
  };
  asset: {
    files: {
      // there are probably other fields in here
      original: string;
      large: string;
    };
  };
  project_id: number;
  archived_at: string | null;
  archive_number: null | unknown;
};

// These are the values that we set ad-hoc on the client side to keep track of
// the display of the map layers.
export type MapLayerClientSide = MapLayer &
  Partial<{
    opacity: number;
    completed_upload: boolean;
    latest_upload: { payload: { tileset: string } };
    bounding_box: BoundingBox;
  }>;

// This is returned as a 'pagy' field on requests that use the Pagy Gem.
// See https://ddnexus.github.io/pagy/
export type PagyMetadata = {
  count?: number | null;
  from?: number | null;
  items?: number | null;
  last?: number | null;
  next?: number | null;
  offset?: number | null;
  outset?: number | null;
  page?: number | null;
  pages?: number | null;
  prev?: number | null;
  to?: number | null;
  vars?: {
    anchor: string;
    count: number;
    cycle: boolean;
    i18n_key: string;
    items: number;
    link_extra: string;
    outset: number;
    page: string;
    page_param: string;
    params: unknown; // seems to usually be an empty object
    size: number[];
  };
};

export type ProjectContact = {
  id: number;
  role: string;
  company: string;
  contact_name: string;
  address_line_1?: string;
  address_line_2?: string;
  phone?: string;
  email?: string;
  created_at: string;
  updated_at: string;
};
