import { useState, useEffect, useMemo } from 'react';
import useFeatures from '../../../hooks/useFeatures';
import featureAnnouncementsApi from '../../../api/feature_announcements';
import PreviewFeatureAnnouncementModal from '../../feature_announcements/PreviewFeatureAnnouncementModal';
import userFeatureAnnoucementsApi from '../../../api/user_feature_announcements';
export default function useFeature(featureFlag: string, {
    includeFeatureAnnouncement = true
}: {
    includeFeatureAnnouncement?: boolean
} = {}) {

    const { features } = useFeatures();
    const [announcementShown, setAnnouncementShown] = useState<boolean>(false);
    const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);
    const [featureAnnouncement, setFeatureAnnouncement] = useState<any>(null);
    const featureAnnoucementModal = useMemo(() => {
        return <PreviewFeatureAnnouncementModal
            featureAnnouncement={featureAnnouncement}
            open={showAnnouncement}
            onClose={() => setShowAnnouncement(false)} />
    }, [featureAnnouncement, showAnnouncement]);

    const onShowAnnouncement = () => {
        if (announcementShown || !featureAnnouncement || featureAnnouncement?.user_experienced) return;
        setShowAnnouncement(true)
        setAnnouncementShown(true)
    }

    useEffect(() => {
        if (!includeFeatureAnnouncement || !featureFlag || !features.rollout || !features.rollout[featureFlag]) return;

        const loadFeatureAnnouncement = async () => {
            const response = await featureAnnouncementsApi.getFeatureAnnouncement(featureFlag)
            if (response.ok) {
                setFeatureAnnouncement(response.data);
            }
        }

        loadFeatureAnnouncement();

    }, [includeFeatureAnnouncement, featureFlag, features])

    useEffect(() => {
        if (!announcementShown) return;
        const createUserFeatureAnnouncement = async () => await userFeatureAnnoucementsApi.createUserFeatureAnnouncement(featureFlag);
        createUserFeatureAnnouncement();
    }, [announcementShown, featureFlag])

    return {
        enabled: features.rollout[featureFlag],
        announcementAvailable: !!featureAnnouncement,
        beta: featureAnnouncement?.status === 'beta',
        announcementModal: featureAnnoucementModal,
        showAnnouncement: onShowAnnouncement,
        forceShowAnnouncement: () => setShowAnnouncement(true),
    }
}
