import React, { useState, useEffect } from 'react';
import { BiClipboard } from 'react-icons/bi';
import { HiGlobe } from 'react-icons/hi';
import { useCopyToClipboard } from 'react-use';
import sharesApi from '../../api/shares';
import useApi from '../../hooks/useApi';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
export default function ShareOptions({ shareable, shareableType }) {
  const {
    data: { records: shares, pagy },
    error: loadError,
    loading,
    request: getShares,
  } = useApi(sharesApi.getShares, { records: [], pagy: {} }, true);
  const [error, setError] = useState();

  const [publicShare, setPublicShare] = useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();

  const createPublicShare = async () => {
    setPublicShare(true);
    let { data } = await sharesApi.addShare({
      is_public: true,
      shareable_type: shareableType,
      shareable_id: shareable.id,
      share_types: ['public_link'],
    });
    setError(data.errors?.length ? data.errors[0] : false);
    setPublicShare(!data.errors?.length ? data : false);
  };

  const archivePublicShare = async () => {
    let { objectId } = publicShare;
    setPublicShare(false);
    await sharesApi.archiveShare(objectId);
  };

  useEffect(() => {
    if (!shareable?.asset && !shareable?.objectId) return;
    getShares({
      where: {
        shareable_type: shareableType,
        shareable_id: shareable.id,
        is_public: true,
      },
    });
  }, [shareable?.asset?.objectId, shareable?.objectId]);

  useEffect(() => {
    if (loading) return;
    const publicShare = shares.find(({ is_public }) => is_public);
    setPublicShare(publicShare);
  }, [shares, loading]);

  if (loading)
    return (
      <div
        data-testid="loader-share-options"
        className="flex justify-center w-full px-5 py-1 pb-2"
      >
        <Loader color={'text-gray-800'} />
      </div>
    );

  return (
    <>
      {error && <ErrorView error={error} extraClass={'relative ml-0'} />}
      <div className="flex bg-white p-3 shadow mb-1 justify-between">
        <div className="flex items-center">
          <p
            className={`mr-2 ${
              publicShare ? 'text-tertiary' : 'text-gray-500'
            } font-medium text-sm flex items-center cursor-pointer`}
            title="Allows anyone with link to view/download the pdf."
          >
            <HiGlobe size={20} className="mr-1" /> Public Link
          </p>
        </div>
        <div className="flex items-center">
          {publicShare && (
            <button
              onClick={() => copyToClipboard(publicShare.link)}
              className="flex items-center text-xs rounded-md border border-gray-200 text-tertiary py-0.5 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none mr-2"
            >
              <BiClipboard className="mr-1" />{' '}
              {state.value ? 'Copied!' : 'Copy Link'}
            </button>
          )}
          <div
            className={`form-switch focus-within:shadow-outline mr-3`}
            role="switch"
            onClick={publicShare ? archivePublicShare : createPublicShare}
          >
            <input
              type="checkbox"
              id={`publicLink_${shareable.objectId}`}
              className="sr-only"
              checked={publicShare}
              onChange={() => {}}
            />
            <label
              className="bg-gray-300"
              htmlFor={`publicLink_${shareable.objectId}`}
            >
              <span className="bg-white shadow-sm" aria-hidden="true"></span>
              <span className="sr-only">Public Link</span>
            </label>
          </div>
        </div>
      </div>
    </>
  );
}
