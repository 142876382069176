import React from 'react';

function createLoaderElement({ testId = 'inlineLoader', size = 5, color = 'white', margin = 'ml-2', className = '' } = {}) {
  // Create the SVG element
  const div = document.createElement("div");

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("class", `animate-spin ${margin} h-${size} w-${size} text-${color} ${className}`);
  svg.setAttribute("data-testid", testId);
  svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
  svg.setAttribute("fill", "none");
  svg.setAttribute("viewBox", "0 0 24 24");

  // Create the circle element
  const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
  circle.setAttribute("class", "opacity-25");
  circle.setAttribute("cx", "12");
  circle.setAttribute("cy", "12");
  circle.setAttribute("r", "10");
  circle.setAttribute("stroke", "currentColor");
  circle.setAttribute("stroke-width", "4");

  // Create the path element
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("class", "opacity-75");
  path.setAttribute("fill", "currentColor");
  path.setAttribute("d", "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z");

  // Append the circle and path to the SVG element
  svg.appendChild(circle);
  svg.appendChild(path);
  div.appendChild(svg);

  // Return the SVG element
  return div;
}

export {
  createLoaderElement
}

export default function Loader({
  className = '',
  color = 'white',
  margin = 'ml-2',
  size = '5',
}) {
  return (
    <svg
      className={`animate-spin ${margin} h-${size} w-${size} text-${color} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}

const LoaderContainer = ({ children }) => {
  return (
    <div className="w-full flex justify-center h-20 items-center">
      {children}
    </div>
  );
}

export {
  LoaderContainer
}
