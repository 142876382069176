/*eslint-disable*/
import { useEffect, useState } from 'react';
import {
  useHistory,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import useAuth from './auth/useAuth';
import * as Sentry from "@sentry/react";

import './css/style.scss';
import './charts/ChartjsConfig';

import { focusHandling } from 'cruip-js-toolkit';

import Projects from './pages/Projects';
import TeamTabs from './pages/TeamTabs';
import Settings from './pages/Settings';
import Signin from './pages/Signin';
import Invitation from './pages/Invitation';
import ResetPassword from './pages/ResetPassword';
import Project from './pages/Project';
import authStorage from './auth/storage';
import usersApi from './api/users';
import Loader from './partials/utility/Loader';
import Share from './pages/Share';
import ReportDownload from './pages/ReportDownload';
import Sidebar from './partials/Sidebar';
import Header from './partials/Header';
import PrivateRoute from './utils/PrivateRoute';
import { ReportContextProvider } from './partials/reports/context';
import GenerateReport from './pages/GenerateReport';
import useQuery from './hooks/useQuery';
import ReportPage from './pages/ReportPage';
import Join from './pages/Join';
import ReportPageComplete from './pages/ReportPageComplete';
import configureTimezone from './utility/configureTimezone';
import ErrorView from './partials/utility/ErrorView';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReportContainer from './partials/reports/ReportContainer';
import 'react-datepicker/dist/react-datepicker.css';
import { ProjectContainer } from './partials/project/context';
import Onboarding from './pages/Onboarding';
import { WorkspaceContextProvider } from './partials/workspaces/context';
import Reports from './pages/Reports';
import useAnalytics from './hooks/useAnalytics';
import CreateProject from './partials/project/CreateProject';
import AdminDashboard from './pages/AdminDashboard';
import Workspaces from './pages/Workspaces';
import DownloadBar, {
  DownloadContext,
  DownloadContextProvider,
} from './partials/utility/DownloadBar';
import useListenForNewVersion from './hooks/useListenForNewVersion';
import Users from './pages/Users';
import ActivityView from './partials/utility/ActivityView';
import AccountOverdueModal from './partials/billing/AccountOverdueModal';
import ErrorBoundaryView from './utils/ErrorBoundaryView';
import Features from './pages/Features';
import WorkspaceEdit from './pages/WorkspaceEdit';

const noRenderSidebarRoutes = ['s', 'mobile', 'generate', 'onboarding', 'join'];

function App() {

  configureTimezone();
  useAnalytics();

  const { loading, user, setUser, logIn } = useAuth();
  const { state, pathname } = useLocation() as any;

  useListenForNewVersion(!!user);

  const history = useHistory();

  const query = useQuery();

  const found_page = pathname.split('/')[1];

  const [page, setPage] = useState(found_page);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const setToken = async (token: unknown, become = false) => {
    if (become) {
      let response = await usersApi.becomeUser(token);
      await logIn(response);
      query.delete('become');
    } else {
      await authStorage.storeToken('authToken', token);
      let foundUser = await authStorage.getUser();
      if (foundUser) setUser(foundUser);
    }

    query.delete('authToken');
    history.replace({
      search: query.toString(),
    });
  };

  useEffect(() => {
    setPage(found_page);
  }, [found_page]);

  useEffect(() => {
    query.get('authToken') &&
      setToken(query.get('authToken'), !!query.get('become'));
  }, [query.get('authToken')]);

  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      html.style.scrollBehavior = 'auto';
      window.scroll({ top: 0 });
      html.style.scrollBehavior = '';

      // The focusHandling function appears to only accept null as a parameter.
      // TODO: determine whether this call can be removed.
      focusHandling('outline' as unknown as null);
    }
  }, [location.pathname]);

  if (loading || query.get('authToken'))
    return (
      <div className="flex py-5 justify-center">
        <Loader color="black" />
      </div>
    );

  //TODO add workspace role context container
  //TODO add redirect for workspace not found
  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryView />}>
      <ToastContainer />
      <WorkspaceContextProvider>
        <AccountOverdueModal />
        <DownloadContextProvider>
          {query.get('activity_id') && (
            <ActivityView
              dismissable={true}
              activityId={query.get('activity_id')}
            />
          )}
          {state?.error && (
            <ErrorView
              dismissable={true}
              error={state?.error}
              className={
                'bg-red-500 text-white px-3 py-2 rounded-0 text-lg text-center font-bold top-0 absolute w-full z-60'
              }
              preface=<span />
            />
          )}
          {state?.success && (
            <ErrorView
              dismissable={true}
              error={state?.success}
              className={
                'bg-green-500 text-white px-3 py-2 rounded-0 text-lg text-center font-bold top-0 absolute w-full z-60'
              }
              preface=<span />
            />
          )}
          {state?.info && (
            <ErrorView
              dismissable={state?.dismissable}
              error={state?.info}
              className={
                'bg-blue-500 text-white px-3 py-2 rounded-0 text-lg text-center font-bold top-0 absolute w-full z-60'
              }
              preface=<span />
            />
          )}
          <Switch>
            <Route key={`sign_in`} exact path="/signin">
              <Signin />
            </Route>
            <Route key={`invitation`} exact path="/invitation">
              <Invitation />
            </Route>
            <Route key={`onboarding`} exact path="/onboarding/:step">
              <Onboarding />
            </Route>
            <Route key={`share`} exact path="/s/:share_id/:shareable_id">
              <Share />
            </Route>
            <Route key={`join`} exact path="/join/:access_code">
              <Join />
            </Route>
            <Route key={`reset-password`} exact path="/reset-password">
              <ResetPassword />
            </Route>
            <PrivateRoute
              key={`mobile_report`}
              exact
              path="/mobile/:workspace_id/projects/:project_id/reports/:report_id"
            >
              <ReportContainer mobile={true} />
            </PrivateRoute>
            <PrivateRoute
              key={`generate_report`}
              exact
              path="/generate/:workspace_id/projects/:project_id/reports/:report_id/report_pages/:report_page_id"
            >
              <ReportContextProvider>
                <ReportPage />
              </ReportContextProvider>
            </PrivateRoute>
            <PrivateRoute
              key={`generate_report_complete`}
              exact
              path="/generate/:workspace_id/projects/:project_id/reports/:report_id/report_pages/:report_page_id/complete"
            >
              <ReportPageComplete />
            </PrivateRoute>
            <PrivateRoute
              key={`report_download`}
              exact
              path="/report-download"
            >
              <ReportDownload />
            </PrivateRoute>
          </Switch>

          {noRenderSidebarRoutes.indexOf(page) === -1 && (
            <div
              key={`${page}_container_${user && user.objectId}`}
              className={`${!user && 'hidden'
                } flex h-screen overflow-hidden ${page.indexOf('projects') > -1 && 'max-h-screen'
                }`}
            >
              {user && (
                <Sidebar
                  key={`sidebar_user_${user && user.objectId}`}
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                />
              )}

              <div
                className={`${page.indexOf('projects') > -1 && 'w-full h-full'
                  } relative flex flex-col flex-1 ${pathname.indexOf('new_map_layer') === -1 &&
                  page.indexOf('maps') === -1 &&
                  'overflow-y-auto overflow-x-hidden'
                  }`}
              >
                {user && (
                  <Header
                    key={`header_user_${user && user.objectId}`}
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                )}
                <Switch>
                  <Route key={`sign_in_auth`} exact path="/signin">
                    <></>
                  </Route>
                  <Route
                    key={`onboarding_auth`}
                    exact
                    path="/onboarding/:step"
                  >
                    <></>
                  </Route>
                  <Route key={`invitation_auth`} exact path="/invitation">
                    <></>
                  </Route>
                  <Route
                    key={`reset_password_auth`}
                    exact
                    path="/reset-password"
                  >
                    <></>
                  </Route>
                  <Route key={`share_auth`} exact path="/s/:share_id">
                    <></>
                  </Route>
                  <Route key={`join_auth`} exact path="/join/:access_code">
                    <></>
                  </Route>
                  <PrivateRoute key={`workspaces_edit`} exact path="/workspaces/:workspaceId/edit">
                    <WorkspaceEdit />
                  </PrivateRoute>
                  <PrivateRoute key={`workspaces`} exact path="/workspaces">
                    <Workspaces />
                  </PrivateRoute>
                  <PrivateRoute key={`users`} exact path="/users">
                    <Users />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`admin_dashboard`}
                    exact
                    path="/admin_dashboard"
                  >
                    <AdminDashboard />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`features`}
                    exact
                    path="/features"
                  >
                    <Features />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`reports_auth`}
                    exact
                    path="/:workspace_id/reports"
                  >
                    <Reports />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`report_page_auth`}
                    exact
                    path="/:workspace_id/projects/:project_id/reports/:report_id/report_pages/:report_page_id"
                  >
                    <ReportContextProvider>
                      <ReportPage />
                    </ReportContextProvider>
                  </PrivateRoute>
                  <PrivateRoute
                    key={`generate_report_auth`}
                    exact
                    path="/:workspace_id/projects/:project_id/reports/:report_id/generate"
                  >
                    <ReportContextProvider>
                      <GenerateReport />
                    </ReportContextProvider>
                  </PrivateRoute>
                  <PrivateRoute
                    key={`report_auth`}
                    exact
                    path="/:workspace_id/projects/:project_id/reports/:report_id"
                  >
                    <ReportContainer />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`map_layer_auth`}
                    exact
                    path="/:workspace_id/projects/:project_id/:path/:map_layer_id"
                  >
                    <ProjectContainer>
                      <Project />
                    </ProjectContainer>
                  </PrivateRoute>
                  <PrivateRoute
                    key={`project_new_auth`}
                    exact
                    path="/:workspace_id/projects/new"
                  >
                    <CreateProject />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`project_auth`}
                    exact
                    path="/:workspace_id/projects/:project_id/:path"
                  >
                    <ProjectContainer>
                      <Project />
                    </ProjectContainer>
                  </PrivateRoute>
                  <PrivateRoute
                    key={`projects_auth`}
                    exact
                    path="/:workspace_id/projects"
                  >
                    <Projects />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`workspace_team_auth`}
                    exact
                    path="/:workspace_id/team"
                  >
                    <TeamTabs />
                  </PrivateRoute>
                  <PrivateRoute
                    key={`workspace_settings_auth`}
                    exact
                    path={['/account/settings', '/:workspace_id/settings']}
                  >
                    <Settings />
                  </PrivateRoute>
                  <Redirect
                    to={
                      user
                        ? `/${user.default_workspace?.objectId}/projects`
                        : 'signin'
                    }
                  />
                </Switch>
              </div>
            </div>
          )}
          <DownloadBar />
        </DownloadContextProvider>
      </WorkspaceContextProvider>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
/* eslint-enable */
