import moment from 'moment-timezone';
import qs from 'querystring';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import documentsApi from '../../api/documents';
import foldersApi from '../../api/folders';
import useApi from '../../hooks/useApi';
import useProject from '../../hooks/useProject';
import ErrorView from '../../partials/utility/ErrorView';
import EditMenu from '../EditMenu';
import ShareModal from '../shares/ShareModal';
import PhotosModal from '../utility/PhotosModal';
import AddFolderModal from './AddFolderModal';
import ArchiveModal from './ArchiveModal';
import Breadcrumb from './Breadcrumb';
import ConfirmDeleteDocumentModal from './ConfirmDeleteDocumentModal';
import DocumentName from './DocumentName';
import EditDocumentModal from './EditDocumentModal';
import MoveDocumentModal from './MoveDocumentModal';
import EmptyState from '../utility/EmptyState';
import AddDocumentVersionModal from './AddDocumentVersionModal';
import VersionsDocumentsModal from './VersionsDocumentsModal';
import useFormattedFolderData from '../../hooks/useFormattedFolderData';
import Button from '../shared/Button';
import DownloadFolderButton from './DownloadFolderButton';

export const styles = {
  rows: {
    style: {
      minHeight: '36px',
    },
  },
  headCells: {
    style: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
    },
  },
  cells: {
    style: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
    },
  },
};

export const conditionalRowStyles = [
  {
    when: (row) => row.type.includes('archived'),
    style: {
      color: '#575959',
    },
  },
];

export const getFormattedStringForTableIcon = ({ type }) => {
  if (type.includes('archived')) {
    return type.replace('archived_', '');
  }

  return type;
};

export const videoMimeTypes = ['mp4', 'avi', 'mov', 'qt', 'wmv', 'm3u8', 'flv'];

export default function DocumentTable({
  folderData,
  updateFolderId,
  projectObjectId,
  onUpdate,
}) {
  const history = useHistory();
  const location = useLocation();
  const { project } = useProject();
  const [mostRecentError, setMostRecentError] = useState(null);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [downloadedFileName, setDownloadedFileName] = useState(null);

  const {
    data: downloadData,
    loading: downloadLoading,
    error: downloadError,
    request: downloadRequest,
  } = useApi(documentsApi.getDocumentFile, null);

  // Archiving
  const {
    data: archivedFolderData,
    loading: archiveFolderLoading,
    error: archiveFolderError,
    request: archiveFolder,
  } = useApi(foldersApi.archiveFolder, null);
  const {
    data: unarchivedFolderData,
    loading: unarchiveFolderLoading,
    error: unarchiveFolderError,
    request: unarchiveFolder,
  } = useApi(foldersApi.unarchiveFolder, null);
  const {
    data: archivedDocumentData,
    loading: archiveDocumentLoading,
    error: archiveDocumentError,
    request: archiveDocument,
  } = useApi(documentsApi.archiveDocument, null);
  const {
    data: unarchivedDocumentData,
    loading: unarchiveDocumentLoading,
    error: unarchiveDocumentError,
    request: unarchiveDocument,
  } = useApi(documentsApi.unarchiveDocument, null);

  const { data } = useFormattedFolderData(folderData);
  function route(newFolderId) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        folder_id: newFolderId,
      }),
    });
    updateFolderId(newFolderId);
  }

  useEffect(() => {
    if (!downloadData) return;
    const url = URL.createObjectURL(downloadData);
    const link = document.createElement('a');
    link.href = url;
    link.download = downloadedFileName ?? '';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }, [downloadData]);

  useEffect(() => {
    if (
      !archivedFolderData &&
      !archivedDocumentData &&
      !unarchivedFolderData &&
      !unarchivedDocumentData
    )
      return;
    onUpdate();
  }, [
    archivedFolderData,
    archivedDocumentData,
    unarchivedFolderData,
    unarchivedDocumentData,
  ]);

  useEffect(() => {
    if (!archiveFolderError) return;
    // TODO: Generically add error message from api response.
    setMostRecentError('Error archiving folder, please try again.');
    clearMostRecentError();
  }, [archiveFolderError]);

  useEffect(() => {
    if (!unarchiveFolderError) return;
    // TODO: Generically add error message from api response.
    setMostRecentError('Error unarchiving folder, please try again.');
    clearMostRecentError();
  }, [unarchiveFolderError]);

  const clearMostRecentError = () => {
    setTimeout(() => {
      setMostRecentError(null);
    }, 3000);
  };

  const handleClick = (row, evt) => {
    evt ?? evt.preventDefault();
    if (row.type === 'folder' || row.type === 'archived_folder') {
      route(row.objectId);
    } else {
      setPreviewDocument(row);
    }
  };

  const handleDownload = async (e, documentObjectId, fileName) => {
    e.preventDefault();
    setDownloadedFileName(fileName);
    await downloadRequest(
      projectObjectId,
      folderData.objectId,
      documentObjectId
    );
  };

  const archiveEntity = async ({ row }) => {
    if (row.type === 'folder') {
      await archiveFolder(projectObjectId, row.objectId);
    } else {
      await archiveDocument(projectObjectId, folderData.objectId, row.objectId);
    }
  };

  const unarchiveEntity = async ({ row, e }) => {
    e.preventDefault();
    if (row.type === 'archived_folder') {
      await unarchiveFolder(projectObjectId, row.objectId);
    } else {
      await unarchiveDocument(
        projectObjectId,
        folderData.objectId,
        row.objectId
      );
    }
  };

  const DocumentEditMenu = ({ row, project, folder }) => {
    const [versionModalOpen, setVersionModalOpen] = useState(false);
    const [versionsModalOpen, setVersionsModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    return (
      <>
        <div className="text-xs text-center p-2 h-11 group flex justify-between items-center">
          <div className="hidden h-6 w-10 text-xl flex rounded justify-center items-center hover:bg-gray-100">
            <FiMoreHorizontal />
          </div>
          <div className="relative">
            <EditMenu action="click" dataTestId={'documentsEditButton'}>
              {project.permissions.can('create_public_link') && (
                <a
                  className={`cursor-pointer`}
                  data-testid="shareReportButton"
                  onClick={() => setShareModalOpen(true)}
                >
                  <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                    Share
                  </div>
                </a>
              )}

              {row.type !== 'folder' && (
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm text-blue-900 hover:text-blue-800 flex py-1 px-3"
                    type="button"
                    onClick={() => setVersionModalOpen(true)}
                  >
                    Upload New Version
                  </a>
                </li>
              )}

              {row.type !== 'folder' && row.versions && (
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm flex py-1 px-3"
                    type="button"
                    onClick={() => setVersionsModalOpen(true)}
                  >
                    Version History
                  </a>
                </li>
              )}
              {project.permissions.can('view_documents') &&
                row.type !== 'folder' &&
                !row.type.includes('archived') && (
                  <li>
                    <a
                      className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      onClick={(e) => handleDownload(e, row.objectId, row.name)}
                    >
                      Download
                    </a>
                  </li>
                )}
              {project.permissions.can('view_documents') &&
                row.type === 'folder' &&
                !row.type.includes('archived') && (
                  <li>
                    <DownloadFolderButton
                      folder={row}
                      color={'link'}
                      className="my-0 bg-transparent shadow-none focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 hover:none"
                    />
                  </li>
                )}
              {project.permissions.can('view_documents') && (
                <li>
                  <a
                    className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                    rel="noreferrer"
                    onClick={(e) => handleClick(row, e)}
                  >
                    View
                  </a>
                </li>
              )}
              <li>
                {project.permissions.can('update_documents') &&
                  (row.type === 'folder' ? (
                    <AddFolderModal
                      folder={row}
                      onCreate={() => updateFolderId()}
                    />
                  ) : (
                    <EditDocumentModal
                      folderObjectId={folder.objectId}
                      document={row}
                      onUpdate={() => onUpdate()}
                    />
                  ))}
              </li>
              {project.permissions.can('view_documents') &&
                !row.type.includes('archived') && (
                  <li>
                    <MoveDocumentModal
                      row={row}
                      currentFolderId={folder.objectId}
                      onMove={onUpdate}
                    />
                  </li>
                )}
              {project.permissions.can('delete_documents') &&
                (!row.type.includes('archived') ? (
                  <li>
                    <ArchiveModal
                      onConfirmArchive={() => archiveEntity({ row })}
                      row={row}
                      errorMessage={
                        archiveFolderError
                          ? archiveFolderError
                          : archiveDocumentError
                      }
                    />
                  </li>
                ) : (
                  <li>
                    <a
                      className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      rel="noreferrer"
                      onClick={(e) => unarchiveEntity({ row, e })}
                    >
                      Unarchive
                    </a>
                  </li>
                ))}
              {project.permissions.can('delete_documents') && (
                <li>
                  <ConfirmDeleteDocumentModal
                    title={'Confirm Delete'}
                    rowData={row}
                    folderObjectId={folder.objectId}
                    projectObjectId={project.objectId}
                    onDelete={onUpdate}
                  />
                </li>
              )}
            </EditMenu>
          </div>
        </div>
        {row.type !== 'folder' && (
          <>
            <AddDocumentVersionModal
              open={versionModalOpen}
              document={row}
              currentFolder={folder}
              onClose={() => setVersionModalOpen(false)}
              onCreate={onUpdate}
            />
            {row.versions && (
              <VersionsDocumentsModal
                open={versionsModalOpen}
                document={row}
                onClose={() => setVersionsModalOpen(false)}
              />
            )}
          </>
        )}
        {project.permissions.can('create_public_link') && (
          <ShareModal
            open={shareModalOpen}
            hideButton
            onClose={() => setShareModalOpen(false)}
            data={row}
            shareType={row.type === 'folder' ? 'Folder' : 'Document'}
            folderId={row.objectId}
            shareableApi={
              row.type === 'folder'
                ? foldersApi.updateFolder
                : documentsApi.updateDocument
            }
          />
        )}
      </>
    );
  };

  const handleSort = (rows, selector, direction) => {
    const folders = rows.filter(
      (row) => row.type === 'folder' || row.type === 'archived_folder'
    );
    const documents = rows.filter(
      (row) => row.type !== 'folder' && row.type !== 'archived_folder'
    );

    // folders will always be sorted asc/desc before documents
    let data = folders.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);
      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
    return data.concat(
      documents.sort((rowA, rowB) => {
        const aField = selector(rowA);
        const bField = selector(rowB);
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      })
    );
  };

  const columns = [
    {
      name: 'Name',
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.name,
        },
      ],
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          className="flex items-center cursor-pointer select-none"
          title={row.name}
        >
          <DocumentName
            name={
              row.type.includes('archived')
                ? `${row.name} [Archived]`
                : row.name
            }
            type={getFormattedStringForTableIcon({ type: row.type })}
          />
        </div>
      ),
      selector: (row) => row.name,
      sortable: true,
      sortField: 'name',
    },
    {
      name: 'Creator',
      selector: (row) => row.creator.name,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.creator.name,
          style: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      ],
    },
    {
      name: 'Last updated',
      selector: (row) => moment(row.updated_at).format('MMM DD, yyyy'),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <DocumentEditMenu row={row} project={project} folder={folderData} />
      ),
      selector: (row) => row.id,
      sortable: false,
      width: '100px',
    },
  ];

  return (
    <>
      <br />
      <div className="flex items-center justify-between">
        <Breadcrumb
          ancestors={folderData?.ancestors}
          current={folderData?.name}
          updateFolderId={updateFolderId}
        />
        <div>
          {!!data.length && (
            <>
              <DownloadFolderButton folder={folderData} />
              {project.permissions.can('create_public_link') && (
                <ShareModal
                  data={folderData}
                  shareType={'Folder'}
                  folderId={folderData.objectId}
                  shareableApi={foldersApi.updateFolder}
                  button={<Button color="light" text="Share" />}
                />
              )}
            </>
          )}
        </div>
      </div>
      <DataTable
        className="!overflow-visible"
        customStyles={styles}
        noDataComponent={
          <div className="flex justify-center py-10">
            <EmptyState
              heading={'Upload New Document'}
              subHeading={'Start adding documents to this folder'}
              hasButton={false}
            />
          </div>
        }
        columns={columns}
        data={data}
        defaultSortFieldId={1}
        sortFunction={handleSort}
        keyField={'objectId'}
        onRowDoubleClicked={handleClick}
        conditionalRowStyles={conditionalRowStyles}
        pagination
        pointerOnHover
        highlightOnHover
      />

      <PhotosModal
        isOpen={!!previewDocument}
        assets={[previewDocument?.asset]}
        isPDF={previewDocument?.type === 'pdf'}
        isVideo={videoMimeTypes.includes(previewDocument?.type)}
        activeIndex={0}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setPreviewDocument(null)}
        defaultFullScreen={true}
        allowDelete={false}
      />

      {mostRecentError && (
        <ErrorView
          extraClass="mt-24"
          dismissable={true}
          error={mostRecentError}
        />
      )}
    </>
  );
}
