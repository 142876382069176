const Image = ({ src }: {
    src: string
}) => <img className="w-full" src={src} width="286" height="160" alt="Application 01" />

const Content = ({ children }: {
    children: React.ReactNode
}) => <div className="flex-grow flex flex-col p-5">
        {children}
    </div>

const Body = ({ children }: {
    children: React.ReactNode
}) => <div className="flex-grow">
        {children}
    </div>

const Header = ({ children }: {
    children: React.ReactNode
}) => <header className="mb-3 flex items-center">
        {children}
    </header>

const Footer = ({ children, spacing = "justify-start" }: {
    children: React.ReactNode,
    spacing?: string;
}) => <div className={`flex ${spacing}`}>
        {children}
    </div>

const Card = ({ children }: {
    children: React.ReactNode
}) => <div className="col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-sm border border-gray-200">
        <div className="flex flex-col h-full">
            {children}
        </div>
    </div>

export default {
    Card,
    Image,
    Content,
    Body,
    Header,
    Footer
}