const dummy = process.env.REACT_APP_REQUEST_MODE === 'dummy';
const apiEnvironment = process.env.REACT_APP_API_ENV || 'development';
const devApiPort = 5000;
const mapBoxPublicKey =
  process.env.REACT_APP_MAPBOX_PUBLIC_KEY ||
  'pk.eyJ1IjoidGV6MTIzenNtIiwiYSI6ImNsYTE0MnJwdDAwZDUzb21yM2MxNHp6b2UifQ.si3Si9oumYrS9vN06uTy2w';
const googleMapsKey = 'AIzaSyBT4oMVR2I9iKS9oh5ovhWCYAEoVE5hP94';
const openWeather = '9331a07d83c2d080db754ca08e220b2d';
const sentryDSN =
  'https://9a477af56e0c1c7ca42261c88f4f85bd@o4505426269831168.ingest.sentry.io/4506107083227136';
const forceEnvironment = '';

function getEnvironment() {
  if (apiEnvironment === 'production' || forceEnvironment === 'production') {
    return {
      name: 'production',
      deepLink: 'sitemarker://',
      apiHost: 'https://api.sitemarker.com',
      apiUrl: 'https://api.sitemarker.com/api',
      actionCableUrl: 'wss://api.sitemarker.com/cable',
      adminHost: 'https://app.sitemarker.com',
      user: 'admin@sitemarker.com',
      password:
        '535b7b83e4d7fa491883879063757029dd40fc54b19a1900c8776b55ba3bccf01b5d81f757259fd335d5eb78d69f9d1f7285e7ca111985038160c2c435a400f2',
      openWeather,
      segmentWriteKey: 'Jg4xfvuMtzD052NH2cNUD6P3eBxDtRBw',
      googleMapsKey,
      mapBoxPublicKey,
      apiEnvironment,
      sentryDSN,
    }; // prod env settings
  } else if (apiEnvironment === 'staging' || forceEnvironment === 'staging') {
    return {
      name: 'staging',
      deepLink: 'exp://exp.host/@community/with-webbrowser-redirect/--/',
      apiHost: 'https://api.staging.sitemarker.com',
      apiUrl: 'https://api.staging.sitemarker.com/api',
      actionCableUrl: 'wss://api.staging.sitemarker.com/cable',
      adminHost: 'https://app.staging.sitemarker.com',
      user: 'admin@sitemarker.com',
      password:
        '3b1a23bf0f7dfe728d52d6accd62b69c7f3ba8886935f7a070ebccfee39e689f1db107f51444e9b6a9dc223023657b403af7cb5e8c86cb237ad5956005e13170',
      openWeather,
      segmentWriteKey: 'qndh0HS4BrWt5Y7AvBYAv1qF1hUiR816',
      googleMapsKey,
      mapBoxPublicKey: 'pk.eyJ1IjoidGV6MTIzenNtIiwiYSI6ImNsdnk5N2xlcjA1bG8yamp6MWpuN29zdTMifQ.0MECU-QtOSLo_Mhe6Och0Q',
      apiEnvironment,
      sentryDSN,
    }; // stage env settings
  } else {
    return {
      name: 'development',
      deepLink: 'exp://127.0.0.1:19000/--/',
      apiHost: `http://${require('ip').address()}:${devApiPort}`,
      apiUrl: `http://${require('ip').address()}:${devApiPort}/api`,
      actionCableUrl: `ws://${require('ip').address()}:${devApiPort}/cable`,
      adminHost: `http://${require('ip').address()}:3000`,
      user: 'admin@sitemarker.com',
      password: 'supersecretpassword',
      openWeather,
      segmentWriteKey: '7x9x0QhctVbK5p9jhCOdtF05qPnaBnCH',
      googleMapsKey,
      mapBoxPublicKey: 'pk.eyJ1IjoidGV6MTIzenNtIiwiYSI6ImNsdnk5NXN1ZzJtbDAyaW82ZW0yYzNhY3kifQ.CBIF8ESHk0TBW8Ak9CqfTw',
      apiEnvironment,
      sentryDSN,
    }; // dev env settings
  }
}

const environment = getEnvironment();

export { forceEnvironment, environment, dummy };
