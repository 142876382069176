import React from 'react';
import { getTextColorFromBackground } from '../../styles/colors';

const sizes: { [key: string]: string } = {
  sm: 'px-1 text-xxs',
  md: 'py-1 px-2 text-sm',
};

export default function PinCategory({
  key,
  color,
  name,
  size = 'md',
}: {
  key: string;
  color: string;
  name: string;
  size?: string;
}) {
  return (
    <span
      key={key}
      className={`${sizes[size]} rounded-sm shadow text-white mr-2`}
      style={{
        backgroundColor: color,
        color: getTextColorFromBackground(color, 200),
      }}
    >
      {name}
    </span>
  );
}
