import { useEffect } from 'react';
import useApi from './useApi';
import projectContactsApi from '../api/project_contacts';
import { ProjectContact } from '../data/models';

const useProjectContacts = (projectId: string | undefined) : {
  contacts: ProjectContact[],
  loading: boolean,
  reloadContacts: () => void
 } => {
  const {
    data:{
      records: contacts,
      pagy,
    },
    loading,
    request:getProjectContacts
  } = useApi(projectContactsApi.getProjectContacts, { records: [], pagy: {} }, !!projectId);

  const reloadContacts = () => {
    if (!projectId) return;
    getProjectContacts(projectId);
  }

  useEffect(reloadContacts, [projectId]);

  return {
    contacts,
    loading,
    reloadContacts,
  };
};

export default useProjectContacts;
