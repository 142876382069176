import React, { useEffect, useState } from 'react';
import DocumentTable from '../documents/DocumentTable';
import foldersApi from '../../api/folders';
import useApi from '../../hooks/useApi';
import useProject from '../../hooks/useProject';
import useQuery from '../../hooks/useQuery';
import AddFolderModal from '../documents/AddFolderModal';
import AddDocumentModal from '../documents/AddDocumentModal';
import SearchForm from '../actions/SearchForm';
import { Redirect, useParams } from 'react-router-dom';
import Loader from '../utility/Loader';

export default function ProjectDocuments() {
  const { workspace_id, project_id } = useParams();
  const { project } = useProject();
  const {
    data: activeFolder,
    error,
    loading,
    request: requestFolder,
  } = useApi(foldersApi.getProjectFolder, null, true);
  const query = useQuery();
  const [selectedFolderQuery, setSelectedFolderQuery] = useState(null);
  const [showArchived, setShowArchived] = useState(true);
  const [filteredTableData, setFilteredTableData] = useState(null);
  const [documentsSearchTerm, setDocumentsSearchTerm] = useState(null);

  useEffect(() => {
    getDocumentsData(project.root_folder.objectId);
    setSelectedFolderQuery(project.root_folder.objectId);
  }, [project?.objectId]);

  useEffect(() => {
    const val = query.get('folder_id');
    if (selectedFolderQuery !== val && val) {
      setSelectedFolderQuery(val);
      getDocumentsData(val);
    }
  }, [query]);

  useEffect(() => {
    if (!activeFolder) return;
    setFilteredTableData(getFilteredData());
  }, [activeFolder, showArchived, documentsSearchTerm]);

  const getDocumentsData = async (folderId) => {
    if (project) {
      await requestFolder(project.objectId, folderId);
    }
  };

  const getFilteredData = () => {
    const results = showArchived
      ? { ...activeFolder }
      : { ...activeFolder, archived_documents: [], archived_folders: [] };

    if (documentsSearchTerm) {
      results.documents = filterByName(results.documents, documentsSearchTerm);
      results.folders = filterByName(results.folders, documentsSearchTerm);
      results.archived_documents = filterByName(
        results.archived_documents,
        documentsSearchTerm
      );
      results.archived_folders = filterByName(
        results.archived_folders,
        documentsSearchTerm
      );
    }

    return results;
  };

  const filterByName = (data, searchTerm) => {
    if (!data || !searchTerm) return data;
    return data.filter((x) =>
      x.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
  };

  if (error) {
    return (
      <Redirect
        to={{
          pathname: `/${workspace_id}/projects/${project_id}/documents`,
          state: { error: error.text?.() || 'Unexpected error has occured' },
        }}
      />
    );
  }

  if (loading)
    return (
      <div className="flex py-4 justify-center">
        <Loader color="black" />
      </div>
    );
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 max-w-9xl self-start w-full">
      <div className="flex justify-between">
        <div className="flex justify-center align-center">
          {!loading && project?.permissions?.can('create_documents') && (
            <AddFolderModal
              currentFolder={activeFolder}
              onCreate={async () => await getDocumentsData(selectedFolderQuery)}
            />
          )}
          {!loading && project?.permissions?.can('create_documents') && (
            <AddDocumentModal
              currentFolder={activeFolder}
              onCreate={async () => await getDocumentsData(selectedFolderQuery)}
            />
          )}
          {!loading && project?.permissions?.can('create_documents') && (
            <SearchForm
              placeholder="Search Documents..."
              containerClassName="relative"
              onSearched={(search) => setDocumentsSearchTerm(search)}
            />
          )}
        </div>
        {!loading && activeFolder && (
          <div
            className="flex items-center justify-end cursor-pointer"
            onClick={() => setShowArchived(!showArchived)}
          >
            <p className={`mr-2 text-gray-500 font-medium text-sm`}>
              Show Archived
            </p>
            <div className={`form-switch focus-within:shadow-outline mr-3`}>
              <input
                type="checkbox"
                id={'show_archived'}
                className="sr-only"
                checked={showArchived}
              />
              <label
                className="bg-gray-300 pointer-events-none"
                htmlFor={`show_archived`}
              >
                <span className="bg-white shadow-sm" aria-hidden="true"></span>
                <span className="sr-only">Show Archived</span>
              </label>
            </div>
          </div>
        )}
      </div>
      {!loading && !error && activeFolder && filteredTableData && (
        <DocumentTable
          projectObjectId={project.objectId}
          folderData={filteredTableData}
          updateFolderId={async (newId) =>
            await getDocumentsData(newId ?? selectedFolderQuery)
          }
          onUpdate={async (newId) =>
            await getDocumentsData(newId ?? selectedFolderQuery)
          }
        />
      )}
    </div>
  );
}
