import { useEffect, useState } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import DelayedSearchInput, { SearchContainer } from '../utility/DelayedSearchInput';
import useModal from '../../hooks/useModal';
import { useParams } from 'react-router-dom';
import ProjectSiteNote, { ReportNoteClient } from './ProjectSiteNote';

type SelectionMode = 'read' | 'selection';

function ProjectNotesModal({ btnDataTestId = 'projectNotesButton', buttonText = 'View All', mode = 'read', onSelect, includePhotos = true }: {
  btnDataTestId?: string,
  buttonText?: string,
  mode?: SelectionMode,
  includePhotos?: boolean,
  onSelect?: (n: ReportNoteClient) => void
}) {
  const { project_id } = useParams<{ project_id: string }>();
  const { open: modalOpen, toggle: toggleModal } = useModal();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    items: 10,
    q: '',
  });
  const [notes, setNotes] = useState<any>([]);
  const [showPhotos, setShowPhotos] = useState(false);
  const {
    data: { records, pagy },
    error,
    loading,
    request: getNotes,
  } = useApi(notesApi.getNotes, { records: [], pagy: {} }, true);

  const resetModal = () => {
    setNotes([]);
    setSearchParams({ page: 1, items: 10, q: '' });
  };

  const _onSelect = (newReportNote: ReportNoteClient) => {
    onSelect?.(newReportNote);
    toggleModal();
  }

  useEffect(() => {
    if (!modalOpen) return;
    getNotes('project', project_id, searchParams);
  }, [modalOpen, searchParams]);

  useEffect(() => {
    if (modalOpen) return;
    resetModal();
  }, [modalOpen]);

  useEffect(() => {
    if (!records.length) return;
    setNotes([...notes, ...records]);
  }, [records]);

  return (
    <>
      {/* Button */}
      <button className="card-header-btn" data-testid={btnDataTestId} type="button" onClick={toggleModal}>
        {buttonText}
      </button>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader
          title={loading ? '...' : 'Site Notes (' + pagy.count + ')'}
          onClose={toggleModal}
        />
        <SearchContainer>
          <DelayedSearchInput
            placeholder="Search Notes..."
            onSearched={(text: string) => {
              setNotes([]);
              setSearchParams({ q: text, page: 1, items: 10 });
            }}
          />
        </SearchContainer>
        <div className="max-h-72 space-y-2 overflow-y-scroll px-4 py-4" data-testid="projectSiteNotesBody">
          {(!loading || notes.length > 0) &&
            notes.map((note: any) => {
              return (
                <ProjectSiteNote
                  key={note.objectId}
                  note={note}
                  selectable={mode === 'selection'}
                  onSelect={onSelect && _onSelect}
                  onShowPhotosClicked={includePhotos ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowPhotos(!showPhotos)
                    return false;
                  } : undefined}
                  showPhotos={showPhotos}
                />
              );
            })}
          {!loading && searchParams.q === '' && pagy.count === 0 && (
            <div className="flex justify-center">
              <p className="empty-data-txt">
                No site notes have been recorded on project
              </p>
            </div>
          )}
          {!loading &&
            pagy.count !== undefined &&
            pagy.count !== notes.length && (
              <div className="flex justify-center p-3 border-b border-gray-200">
                <a
                  className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      page: searchParams.page + 1,
                    })
                  }
                >
                  Load More
                </a>
              </div>
            )}
          {loading && (
            <div className="flex justify-center py-10">
              <Loader color="black" />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ProjectNotesModal;
