import { memo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useApi from '../../hooks/useApi';
import useModal from '../../hooks/useModal';
import documentsApi from '../../api/documents';
import Loader from '../utility/Loader';
import Modal, { ModalHeader } from '../utility/Modal';
import AddPhotoItem from '../project/AddPhotoItem';
import { useParams } from 'react-router-dom';
import { FaFileUpload } from 'react-icons/fa';
import { AiOutlineFileAdd } from 'react-icons/ai';
function AddDocumentModal({ currentFolder, onCreate }) {
  const { open: modalOpen, toggle: toggleModal } = useModal();
  const { request: addDocument } = useApi(documentsApi.addDocument, null);

  const [files, setFiles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [signedFiles, setSignedFiles] = useState([]);
  const { project_id } = useParams();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const addSignedFile = (signature, file) => {
    setSignedFiles((oldSignedFiles) => [
      ...oldSignedFiles,
      { signature, file },
    ]);
  };

  useEffect(async () => {
    if (!signedFiles.length) return;
    if (signedFiles.length !== files.length) return;

    const addDocuments = async () => {
      for (const signedFile of signedFiles) {
        let newFileData = {
          name: signedFile.file.name,
          description: 'test',
          version: '1',
          assets_attributes: [
            {
              file: signedFile.signature,
            },
          ],
        };

        await addDocument(project_id, currentFolder.objectId, newFileData);
      }
    };

    await addDocuments();
    setSaving(false);

    await onCreate?.();
    toggleModal();
  }, [files, signedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFileAtIndex = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  return (
    <>
      <div
        className="btn rounded hover:text-gray-50 hover:opacity-90 text-white bg-secondary cursor-pointer mr-3"
        onClick={toggleModal}
      >
        <FaFileUpload size={24} /> <span className="ml-2">File upload</span>
      </div>
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader title={'Upload New File'} onClose={toggleModal} />

        <div
          className={`flex flex-wrap items-start ${!!files?.length ? 'py-4 px-5' : ''
            }`}
        >
          {files.map((file, i) => (
            <AddPhotoItem
              key={`project_${project_id}_temp_image_${file.path}`}
              file={file}
              startSaving={saving}
              index={i}
              onSaved={addSignedFile}
              passback={true}
              description={null}
              onRemove={removeFileAtIndex}
            />
          ))}
        </div>

        <div
          className="cursor-pointer outline-none ring-0 text-base w-full px-5 pb-4 pt-2"
          {...getRootProps()}
          data-testid="dropzone-photo-file"
        >
          <div
            disabled={saving !== false}
            className={`disabled:opacity-50 p-5 h-72 flex flex-col active:ring-0 items-center justify-center ${!isDragActive
              ? 'border-2 border-gray-300'
              : 'border-3 border-secondary bg-blue-100'
              } border-dashed hover:opacity-80 rounded-md text-center`}
          >
            <input {...getInputProps()} data-testid="input-photo-file" />
            <AiOutlineFileAdd
              size={60}
              className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
                }`}
            />
            <div
              className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
                }`}
            >
              Click to upload documents
              <br />
              or drag and drop
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="modal-save-btn"
            type="button"
            disabled={!files.length || saving !== false}
            onClick={() => setSaving(true)}
          >
            Save
            {!!saving && <Loader className="ml-3" />}
          </button>
          <button
            className="modal-close-btn"
            type="button"
            disabled={false}
            onClick={toggleModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default memo(AddDocumentModal);
